import {
  Badge,
  Box,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { RobotViewContext } from '../../context/RobotView';
import {
  connectHost,
  disconnectHost,
  onConnect,
  onMessage,
  topicPublish,
  topicSubscribeMultiple,
  topicUnsubscribeMultiple,
} from '../../mqtt/mqttFunctions';
import ActionWidget from '../../redux/containers/widgets/ActionWidget';
import AlertsWidget from '../../redux/containers/widgets/AlertsWidget.js';
import HardwareAlertsWidget from '../../redux/containers/widgets/HardwareAlertsWidget.js';
import HardwareComponentsWidget from '../../redux/containers/widgets/HardwareComponentsWidget.js';
import NavigationWidget from '../../redux/containers/widgets/NavigationWidget';
import RobotParametersWidget from '../../redux/containers/widgets/RobotParametersWidget';
import RobotStateHistoryWidget from '../../redux/containers/widgets/RobotStateHistoryWidget.js';
import ScheduleWidget from '../../redux/containers/widgets/ScheduleWidget';
import ServicingHistoryWidget from '../../redux/containers/widgets/ServicingHistoryWidget.js';
import TimelineWidget from '../../redux/containers/widgets/TimelineWidget.js';
import ConfirmDialog from '../robotView/ConfirmDialog';
import NewServicingDialog from '../robotView/NewServicingDialog';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';
import AislesProgressWidget from '../widgets/AislesProgressWidget';
import CallerWidget from '../widgets/CallerWidget.js';
import CamWidget from '../widgets/CamWidget';
import MapWidget from '../widgets/MapWidget';
import PipelineProgressWidget from '../widgets/PipelineProgressWidget';
import { SessionLogsWidget } from '../widgets/SessionLogsWidget';
import SessionPlanWidget from '../widgets/SessionPlanWidget';
import NewStateDialog from './NewStateDialog.js';

import { isEmptyOrUndefined } from '@zippeditoolsjs/blocks';

const { REACT_APP_MQTT_USER, REACT_APP_MQTT_PASSWORD } = process.env;

/**
 * get all possible topics for a robot
 * @param  	{string} 				store 				store code (e.g. J510)
 * @param  	{string}				robot 				robot number (e.g. 1)
 * @param 	{object}				args					optional arguments
 * @param 	{string}				args.topic_id	optional topic id to get a specific topic from all topics available in this view (e.g. nav_topic)
 * @param 	{boolean}				args.canParse	optional boolean to get all topics in a format that can be parsed by subscribeMultiple function from mqttFunctions.js
 * @return  {object}        			 				object with desired topics
 */
export function topics_handler(
  store,
  robot,
  args = {
    topic_id: null,
    canParse: false,
  }
) {
  const TOPICS_INFO = {
    nav_topic: { topic: `info/${store}/${robot}/nav_data`, qos: 0 },
    map_topic: {
      topic: `info/${store}/${robot}/map`,
      qos: 1,
      publishTopic: `info/${store}/${robot}/get_map`,
      publishQos: 1,
    },
    frontal_image: { topic: `info/${store}/${robot}/frontal`, qos: 0 },
    floor_image: { topic: `info/${store}/${robot}/floor`, qos: 0 },
    side_cameras: {
      topic: `info/${store}/${robot}/side_cameras`,
      qos: 0,
      publishTopic: `info/${store}/${robot}/get_side_cameras`,
      publishQos: 0,
    },
    security_cameras: {
      topic: `info/${store}/${robot}/security_cameras`,
      qos: 0,
      publishTopic: `info/${store}/${robot}/get_security_cameras`,
      publishQos: 0,
    },
    info_command: {
      topic: `info/${store}/${robot}/command_exec_status`,
      qos: 2,
      publishTopic: `info/${store}/${robot}/command`,
      publishQos: 2,
    },
    footage_topic: {
      topic: `info/${store}/${robot}/footage/response`,
      qos: 2,
      publishTopic: `info/${store}/${robot}/footage`,
      publishQos: 2,
    },
    autonomous_mapping: {
      topic: `${store}/${robot}/lines/response`,
      qos: 2,
      publishTopic: `${store}/${robot}/lines/request`,
      publishQos: 2,
    },
    robot_status_topic: {
      topic: `info/${store}/${robot}/robot_status`,
      qos: 0,
    },
    refresh_robot_status_topic: {
      topic: `info/${store}/${robot}/refresh_robot_status`,
      qos: 0,
    },
    connection_status_topic: {
      topic: `info/${store}/${robot}/connection_status`,
      qos: 0,
    },
    thumb_map_topic: {
      topic: `info/${store}/${robot}/thumb_map`,
      qos: 0,
    },
    monitoring_logs: {
      topic: `info/${store}/${robot}/monitoring_logs`,
      qos: 0,
      publishTopic: `info/${store}/${robot}/get_monitoring_logs`,
      publishQos: 1,
    },
  };

  if (args.canParse) {
    return Object.values(TOPICS_INFO).reduce((beforeValue, currentValue) => {
      return {
        ...beforeValue,
        [currentValue.topic]: { qos: currentValue.qos },
      };
    }, {});
  } else {
    return TOPICS_INFO;
  }
}

export default function RobotView(props) {
  const mqttOptions = {
    username: REACT_APP_MQTT_USER,
    password: REACT_APP_MQTT_PASSWORD,
  };
  let { store: storeRaw, robot: robotRaw } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = parseInt(searchParams.get('tab'), 10) || 0;

  const {
    robotActionsList,
    getRobotActions,
    topicMessageFeedback,
    postUserActions,
    getRobotsTaken,
    robotsTakenList,
    takeRobot,
    isLoadingTakeRobot,
    leaveRobot,
    isLoadingLeaveRobot,
    takeRobotResponse,
    leaveRobotResponse,
    getRobotBlocking,
    isLoadingRobotBlocking,
    postNewServicing,
    isLoadingNewServicing,
    newServicingResponse,
    getChangeMotives,
    changeMotivesList,
    isLoadingChangeMotives,
    robotComponentsList,
    getRobotComponents,
    setSnackAlert,
    productsList,
    isLoadingProductsList,
    getProductsByName,
    getBroker,
    brokerUrl,
    isLoadingBrokerUrl,
    getActiveAlerts,
    postNewMap,
    storeMapInfo,
    getStoreMap,
    storeInfo,
    isLoadingStoreInfo,
    getStore,
    userInfo,
    getUserInfo,
    detailedUserInfo,
    activeAlerts,
    getMapSignal,
    getMappingAlerts,
    mapSignal,
    isLoadingMapSignalInfo,
    featurePermission,
    checkFeaturePermission,
    getRobotState,
    robotStateInfo,
    getAvailableRobotStatus,
    availableStatus,
    updateRobotState,
    loadingNewRobotState,
    robotStateUpdateResponse,
    getRobotStateMotives,
    robotStateMotives,
    hardwareActiveAlerts,
    getActiveHardwareAlerts,
    postActionReason,
    getCallerHistory,
    postCallerAction,
    callerHistory,
    loadingCallerHistory,
    newCallerActionResponse,
    loadingNewCallerActionResponse,
    storeCountry,
    getCountryByStore,
    getCallMotives,
    callMotives,
    linkReceived,
    mappingAlerts,
    isMappingAlertsLoading,
  } = props;

  const { t } = useTranslation();
  const {
    setVirtualBorders,
    setIsLinesLoading,
    setOnDemandCamerasOptions,
    rawRobotStatus,
    setRawRobotStatus,
    isSubscribedStatus,
    setIsSubscribedStatus,
  } = useContext(RobotViewContext);

  const [store, setStore] = useState(storeRaw?.toUpperCase());
  const [robot, setRobot] = useState();
  const [topics, setTopics] = useState({});
  const [client, setClient] = useState(null);
  const [tabIndex, setTabIndex] = useState(initialTab);
  const [mqttMessage, setMqttMessage] = useState({});
  const [mqttNavMessage, setNavMqttMessage] = useState({});
  const [mqttNavInfo, setMqttNavInfo] = useState({});
  const [frontalImageMsg, setFrontalImageMsg] = useState({});
  const [frontalImage, setFrontalImage] = useState({});
  const [floorImageMsg, setFloorImageMsg] = useState({});
  const [floorImage, setFloorImage] = useState({});
  const [robotMainStatus, setRobotMainStatus] = useState(null);
  const [robotDetailStatus, setRobotDetailStatus] = useState(null);
  const [robotStatusIssuesCount, setRobotStatusIssuesCount] = useState(null);
  const [connectionStatusMsg, setConnectionStatusMsg] = useState({});
  const [thumbMap, setThumbMap] = useState(null);
  const [monitoringLogs, setMonitoringLogs] = useState(null);
  const [isSessionLogsLoading, setIsSessionLogsLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(1);
  const [open, setOpen] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [iHaveRobotTaken, setIHaveRobotTaken] = useState(false);
  const [controlledBy, setControlledBy] = useState(null);
  const [openKick, setOpenKick] = useState(false);
  const [canSendHeartbeat, setCanSendHeartbeat] = useState(false);
  const [isRobotTaken, setIsRobotTaken] = useState(false);
  const [mapHeight, setMapHeight] = useState('27rem');
  const [robotState, setRobotState] = useState(null);
  const [openState, setOpenState] = useState(false);
  const [storeLang, setStoreLang] = useState(null);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);
  const handleUnsubscribe = () => {
    const beforeTopicsParsed = Object.values(topics).reduce(
      (beforeValue, currentValue) => {
        return {
          ...beforeValue,
          [currentValue.topic]: { qos: currentValue.qos },
        };
      },
      {}
    );
    onConnect(client, () =>
      topicUnsubscribeMultiple(client, beforeTopicsParsed, () => { })
    );
    disconnectHost(client);
    setClient(null);
    setIsSubscribedStatus(false);
  };

  // Hooks
  useEffect(() => {
    getRobotStateMotives();
    getCallMotives();
    if (userInfo.email) getUserInfo(userInfo.email);
  }, []);

  useEffect(() => {
    getCallerHistory(store);
    getCountryByStore(store);
  }, [store]);

  useEffect(() => {
    if (storeInfo) {
      document.title = `${storeInfo.code}: ${storeInfo.name}`;
    }
    return () => {
      document.title = 'Overseer App';
    };
  }, [storeInfo]);

  useEffect(() => {
    if (storeRaw) {
      getStore(storeRaw);
      setStore(storeRaw.toUpperCase());
    }
    if (robotRaw !== undefined) {
      setRobot(robotRaw);
    }
  }, [storeRaw, robotRaw]);

  useEffect(() => {
    if (canSendHeartbeat) {
      setCanSendHeartbeat(false);
      publishHeartbeat();
    }
  }, [canSendHeartbeat]);

  useEffect(() => {
    if (brokerUrl && !isLoadingBrokerUrl) {
      if (!client) {
        const clientConnection = connectHost(brokerUrl, mqttOptions);
        setClient(clientConnection);
      } else if (!isSubscribedStatus) {
        const topicsParsed = topics_handler(store, robot, { canParse: true });
        onConnect(client, () =>
          topicSubscribeMultiple(client, topicsParsed, () =>
            setIsSubscribedStatus(true)
          )
        );
        client.on('error', (err) => {
          console.log('error', err);
          setIsSubscribedStatus(false);
        });
      }
    } else if (isLoadingBrokerUrl) {
      if (client) {
        handleUnsubscribe();
      } else {
        setIsSubscribedStatus(false);
      }
    }
  }, [brokerUrl, client, isLoadingBrokerUrl]);

  useEffect(() => {
    let newInterval, heartBeatInterval, robotCode;
    if (store) {
      if (robot !== undefined) {
        if (intervalId !== null) clearInterval(intervalId);
        newInterval = handleRobotTaken();
        heartBeatInterval = handleHeartBeat();
        robotCode = `${store}-${robot}`;
        getRobotComponents(robotCode);
        getStoreMap({ robot_code: robotCode });
        getRobotState(robotCode);
        getActiveHardwareAlerts(robotCode);
        postUserActions('enter-new-robot-page', robotCode);
      }
      getBroker(store);
    }
    return () => {
      clearInterval(newInterval);
      clearInterval(heartBeatInterval);
      clearInterval(intervalId);
    };
  }, [robot, store]);

  useEffect(() => {
    if (mqttNavMessage.msg) {
      setMqttNavInfo(JSON.parse(mqttNavMessage.msg));
    }
  }, [mqttNavMessage]);

  useEffect(() => {
    if (frontalImageMsg.msg) {
      setFrontalImage(JSON.parse(frontalImageMsg.msg));
    }
  }, [frontalImageMsg]);

  useEffect(() => {
    if (floorImageMsg.msg) {
      setFloorImage(JSON.parse(floorImageMsg.msg));
    }
  }, [floorImageMsg]);

  useEffect(() => {
    getRobotActions();
    getChangeMotives();
  }, [getRobotActions]);

  useEffect(() => {
    getRobotsTaken(`${store}-${robot}`);
  }, [isLoadingLeaveRobot]);

  useEffect(() => {
    if (newServicingResponse && newServicingResponse.status === 200) {
      handleCloseDialog();
      setResetForm(true);
    }
  }, [newServicingResponse]);

  useEffect(() => {
    if (robotStateUpdateResponse === 200) {
      setOpenState(false);
      setResetForm(true);
      getRobotState(`${store}-${robot}`);
    }
  }, [robotStateUpdateResponse]);

  useEffect(() => {
    if (isSubscribedStatus) {
      onMessage(client, msgHandler);
    }
  }, [isSubscribedStatus]);

  useEffect(() => {
    if (robotsTakenList && robotsTakenList[`${store}-${robot}`]) {
      if (robotsTakenList[`${store}-${robot}`].user === null) {
        setControlledBy(null);
        setIHaveRobotTaken(true);
      } else if (robotsTakenList[`${store}-${robot}`].user.username) {
        setControlledBy(robotsTakenList[`${store}-${robot}`].user.username);
        setIHaveRobotTaken(false);
      }
      setIsRobotTaken(true);
    } else {
      setControlledBy(null);
      setIHaveRobotTaken(false);
      setIsRobotTaken(false);
    }
  }, [robotsTakenList]);

  useEffect(() => {
    if (takeRobotResponse && takeRobotResponse.error === false) {
      postUserActions('take-robot', `${store}-${robot}`);
      setIHaveRobotTaken(true);
      getRobotsTaken(`${store}-${robot}`);
    }
  }, [takeRobotResponse]);

  useEffect(() => {
    if (leaveRobotResponse && leaveRobotResponse.error === false) {
      postUserActions('leave-robot', `${store}-${robot}`);
      setIHaveRobotTaken(false);
    }
  }, [leaveRobotResponse]);

  useEffect(() => {
    publishHeartbeat();
  }, [isRobotTaken]);

  useEffect(() => {
    if (robotStateInfo) {
      setRobotState(robotStateInfo.status_id);
    }
  }, [robotStateInfo]);

  useEffect(() => {
    if (storeCountry) {
      setStoreLang(storeCountry?.locale.split('_')[0]);
    }
  }, [storeCountry]);

  // Redirects from Dashboards
  useEffect(() => {
    if (!isEmptyOrUndefined(linkReceived, 'object')) {
      setTabIndex(linkReceived?.tabIndex)
    }
  }, [linkReceived])

  // Handlers
  const handleCommandsResponse = (msg) => {
    let data = JSON.parse(msg['msg']);
    if (data.command_exec_state) {
      topicMessageFeedback(data.message, data.command_exec_state);
      if (data.command_exec_state === 'success') {
        setMqttMessage(data);
      }
    }
  };

  const handleRobotTaken = () => {
    let interval = setInterval(
      () => getRobotsTaken(`${store}-${robot}`),
      60000
    );
    setIntervalId(interval);
    setTopics(topics_handler(store, robot));
    getRobotsTaken(`${store}-${robot}`);
    return interval;
  };

  const handleHeartBeat = () => {
    let interval = setInterval(() => setCanSendHeartbeat(true), 270000);
    return interval;
  };

  const publishHeartbeat = () => {
    topicPublish(client, {
      topic: `info/${store}/${robot}/heartbeat`,
      qos: 0,
      payload: JSON.stringify({
        isTaken: isRobotTaken,
        robotState: robotState,
      }),
    });
  };

  const handleMapInfo = (mapMsg) => {
    // If map comes in the payload, then it is a map update
    let mapPayload = {};
    try {
      mapPayload = JSON.parse(mapMsg.msg);
    } catch (error) {
      console.log('Error parsing map payload', mapMsg?.msg, error);
    }

    if (mapPayload?.map) {
      // Publish the recently arrived map and its hash to the database
      const map_bytes = mapPayload['map'];
      const map_metadata = mapPayload['map_metadata'];
      const map_hash = mapPayload['hash'];

      const fileForm = new FormData();
      fileForm.append('img', map_bytes);
      fileForm.append('robot_code', `${store}-${robot}`);
      fileForm.append('hash', map_hash);
      fileForm.append('resolution', map_metadata['resolution']);
      fileForm.append('height', map_metadata['height']);
      fileForm.append('width', map_metadata['width']);
      fileForm.append('origin', JSON.stringify(map_metadata['origin']));

      postNewMap({ fileForm });
    }
  };

  const handleFootageResponse = (msg) => {
    // If map comes in the payload, then it is a map update
    let payload = {};
    try {
      payload = JSON.parse(msg.msg);
    } catch (error) {
      console.log('Error parsing map payload', msg?.msg, error);
    }
    if (payload['message'] === 'success') {
      const snack = {
        open: true,
        message:
          'Successfully sent footage form to the backend, you will receive an email with the footage soon',
        severity: 'success',
      };
      setSnackAlert(snack);
    } else {
      const snack = {
        open: true,
        message: 'Error sending footage to the backend, try again later',
        severity: 'error',
      };
      setSnackAlert(snack);
    }
  };

  const handleRobotStatusResponse = (msg) => {
    try {
      const important_info = ['nav', 'collector1', 'collector2', 'collector3'];

      let status = JSON.parse(msg['msg']);
      let important_dict = {};

      important_info.forEach((info) => {
        important_dict[info] = [];
      });
      Object.keys(status).forEach((item) => {
        let aux = status[item];
        let aux_list = [];
        if (
          Object.prototype.toString.call(aux) === '[object Object]' ||
          typeof aux === 'boolean'
        ) {
          if (typeof aux === 'boolean') {
            important_dict[item] = [[item, aux]];
          } else {
            Object.keys(aux).forEach((val) => {
              if (important_info.includes(val)) {
                aux_list = [item, aux[val]];
                important_dict[val].push(aux_list);
              }
            });
          }
        }
      });

      let actual_states_components = {};
      Object.keys(important_dict).forEach((item) => {
        let actual_state = true;
        if (important_dict[item].length === 0) {
          actual_states_components[item] = null;
        } else {
          important_dict[item].forEach((info) => {
            if (info[1] === false) actual_state = false;
          });
          actual_states_components[item] = actual_state;
        }
      });
      const status_info = important_dict;

      let current_status = Object.keys(actual_states_components).every(
        (key) => actual_states_components[key]
      );

      if (current_status === true) {
        current_status = 'OK';
      } else if (current_status === false) {
        let is_warning = important_info.some(
          (info) => status_info[info].length === 0
        );
        if (is_warning) current_status = 'Warning';
        else current_status = 'Error';
      }

      let errors = [];
      let others = [];

      Object.keys(important_dict).forEach((info) => {
        if (
          important_dict[info].length === 1 &&
          important_dict[info][0][1] === false
        ) {
          errors.push({ name: info, data: important_dict[info] });
        } else {
          others.push({ name: info, data: important_dict[info] });
        }
      });

      setRobotMainStatus(current_status);
      setRobotDetailStatus([...errors, ...others]);
      setRawRobotStatus(status);
      setRobotStatusIssuesCount(errors.length);
    } catch (error) {
      console.log('Error parsing map payload', msg?.msg, error);
    }
  };

  const handleAutonomousMapping = (msg) => {
    let payload = {};
    try {
      payload = JSON.parse(msg.msg);
    } catch (error) {
      console.log('Error parsing map payload', msg?.msg, error);
    }
    if (payload['command_exec_state'] === 'success') {
      if (payload['message'] === 'Dictionary with lines retrieved') {
        setVirtualBorders(
          Object.entries(payload['info']).map(([key, value]) => {
            return {
              id: key,
              pxInit: { x: value[0][0], y: value[0][1] },
              pxEnd: { x: value[1][0], y: value[1][1] },
            };
          })
        );
      } else {
        const snack = {
          open: true,
          message: payload['message'],
          severity: 'success',
        };
        setSnackAlert(snack);
      }
    } else {
      const snack = {
        open: true,
        message: 'Error sending virtual borders to the robot, try again later',
        severity: 'error',
      };
      setSnackAlert(snack);
    }
    setIsLinesLoading(false);
  };

  const handleRefreshRobotAlert = (msg) => {
    getActiveAlerts({ robot_id: `${store}-${robot}` });
  };

  const handleSetOnDemandCameras = (cameraType) => (msg) => {
    let payload = {};
    try {
      payload = JSON.parse(msg.msg);
    } catch (error) {
      console.log('Error parsing map payload', msg?.msg, error);
    }
    setOnDemandCamerasOptions({ [cameraType]: payload });
  };

  const handleConnectionStatus = (msg) => {
    setConnectionStatusMsg(JSON.parse(msg['msg']));
  };

  const handleThumbMap = (msg) => {
    let message = JSON.parse(msg['msg']);
    setThumbMap(message['img']);
  };

  const handleMonitoringLogs = (msg) => {
    let payload = {};
    try {
      payload = JSON.parse(msg.msg);
    } catch (error) {
      console.log('Error parsing map payload', msg?.msg, error);
    }
    setIsSessionLogsLoading(false);
    setMonitoringLogs((prevLogs) => {
      return { ...prevLogs, [payload.log_type]: payload?.log };
    });
  };

  const msgHandler = (payload) => {
    const topicMapper = {
      [topics.map_topic?.topic]: handleMapInfo,
      [topics.nav_topic?.topic]: setNavMqttMessage,
      [topics.footage_topic?.topic]: handleFootageResponse,
      [topics.frontal_image.topic]: setFrontalImageMsg,
      [topics.floor_image?.topic]: setFloorImageMsg,
      [topics.info_command?.topic]: handleCommandsResponse,
      [topics.autonomous_mapping?.topic]: handleAutonomousMapping,
      [topics.robot_status_topic?.topic]: handleRobotStatusResponse,
      [topics.refresh_robot_status_topic?.topic]: handleRefreshRobotAlert,
      [topics.side_cameras?.topic]: handleSetOnDemandCameras('Collector'),
      [topics.security_cameras?.topic]: handleSetOnDemandCameras('Security'),
      [topics.connection_status_topic?.topic]: handleConnectionStatus,
      [topics.thumb_map_topic?.topic]: handleThumbMap,
      [topics.monitoring_logs?.topic]: handleMonitoringLogs,
    };
    if (topicMapper[payload?.topic]) topicMapper[payload.topic](payload);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const takeSelectedRobot = () => {
    getRobotsTaken();
    takeRobot(`${store}-${robot}`);
  };

  const leaveSelectedRobot = () => {
    getRobotsTaken();
    leaveRobot(`${store}-${robot}`);
  };

  const kickAndTake = () => {
    takeRobot(`${store}-${robot}`, undefined, true);
    setOpenKick(false);
  };

  const handleCloseKickDialog = () => {
    setOpenKick(false);
  };

  async function publishMessageCommand(element, extraInfo = null) {
    let cmdPayload = { command: element.command };
    if (extraInfo) {
      cmdPayload = { ...cmdPayload, ...extraInfo };
    }
    let response = await topicPublish(client, {
      topic: topics.info_command.publishTopic,
      qos: topics.info_command.publishQos,
      payload: JSON.stringify(cmdPayload),
    });
    if (response && response.status) {
      topicMessageFeedback(response.message, response.status);
    }
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{ borderRadius: '15px', background: '#f7f6fa', pb: 2 }}
      >
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Typography variant="h6" component="h1" padding={1}>
            Overseer / Robot View: {store}-{robot}
            {!isLoadingStoreInfo && storeInfo && <> ({storeInfo.name})</>}
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ marginLeft: 'auto' }}
          >
            <Tab label="Monitoring" {...a11yProps(0, {})} />
            <Tab
              disabled={isLoadingBrokerUrl}
              label="Session"
              {...a11yProps(1, {})}
            />
            <Tab
              disabled={isLoadingBrokerUrl}
              label="Timeline"
              {...a11yProps(2, {})}
            />
            <Tab
              disabled={isLoadingBrokerUrl}
              label={
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  Maintenance
                  <Badge
                    badgeContent={
                      hardwareActiveAlerts.length > 0
                        ? hardwareActiveAlerts.length
                        : null
                    }
                    color="error"
                    sx={{
                      position: 'absolute',
                      right: '-5px',
                      opacity: 0.8,
                      pointerEvents: 'none',
                    }}
                  />
                </Box>
              }
              {...a11yProps(3, {})}
            />
          </Tabs>
        </Box>
        {/* Monitoring Widgets */}
        <TabPanel value={tabIndex} index={0} sx={{ p: 3 }}>
          {isLoadingBrokerUrl ? (
            <>
              <LinearProgress sx={{ borderRadius: '5px' }} color="secondary" />
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item sm={12} md={4.5}>
                <CamWidget name={'Frontal'} image={frontalImage} />
              </Grid>
              <Grid item sm={12} md={4}>
                <CamWidget name={'Floor'} image={floorImage} />
              </Grid>
              <Grid item sm={12} md={3.5}>
                <NavigationWidget
                  title={t('overseer_app.widget.card_robot_status', 'Status')}
                  connectionStatusMsg={connectionStatusMsg}
                  mqttNavInfo={mqttNavInfo}
                  rawRobotStatus={rawRobotStatus}
                  thumbMap={thumbMap}
                  storeMapInfo={storeMapInfo}
                  store={store}
                  robot={robot}
                  robotMainStatus={robotMainStatus}
                  robotDetailStatus={robotDetailStatus}
                  rawData={rawRobotStatus}
                  issuesCount={robotStatusIssuesCount}
                  activeAlerts={activeAlerts}
                  robotState={robotState}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <MapWidget
                  id="robot-view"
                  title={t(`overseer_app.widget.map`, 'Map')}
                  robot={robot}
                  storeInfo={storeInfo}
                  mqttNavInfo={mqttNavInfo}
                  storeMapInfo={storeMapInfo}
                  minHeight={mapHeight}
                  getMapSignal={getMapSignal}
                  getMappingAlerts={getMappingAlerts}
                  mapSignal={mapSignal}
                  isLoadingMapSignalInfo={isLoadingMapSignalInfo}
                  mappingAlerts={mappingAlerts}
                  isMappingAlertsLoading={isMappingAlertsLoading}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <AlertsWidget store={store} robot={robot} />
              </Grid>
              <Grid item sm={12} md={4}>
                <ActionWidget
                  store={store}
                  robot={robot}
                  topics={topics}
                  client={client}
                  actionHistory={[{}]}
                  takeRobot={takeRobot}
                  leaveRobot={leaveRobot}
                  robotsTakenList={robotsTakenList}
                  robotCode={`${store}-${robot}`}
                  getRobotActions={getRobotActions}
                  getRobotsTaken={getRobotsTaken}
                  robotActionsList={robotActionsList}
                  getRobotBlocking={getRobotBlocking}
                  takeRobotResponse={takeRobotResponse}
                  leaveRobotResponse={leaveRobotResponse}
                  isSubscribedStatus={isSubscribedStatus}
                  isLoadingTakeRobot={isLoadingTakeRobot}
                  isLoadingLeaveRobot={isLoadingLeaveRobot}
                  topicMessageFeedback={topicMessageFeedback}
                  isLoadingRobotBlocking={isLoadingRobotBlocking}
                  mqttNavMessage={mqttNavMessage}
                  mqttNavInfo={mqttNavInfo}
                  frontalImage={frontalImage}
                  postUserActions={postUserActions}
                  postActionReason={postActionReason}
                  mqttMessage={mqttMessage}
                  floorImage={floorImage}
                  productsList={productsList}
                  isLoadingProductsList={isLoadingProductsList}
                  getProductsByName={getProductsByName}
                  storeMapInfo={storeMapInfo}
                  rawRobotStatus={rawRobotStatus}
                  publishMessageCommand={publishMessageCommand}
                  iHaveRobotTaken={iHaveRobotTaken}
                  setIHaveRobotTaken={setIHaveRobotTaken}
                  takeSelectedRobot={takeSelectedRobot}
                  leaveSelectedRobot={leaveSelectedRobot}
                  controlledBy={controlledBy}
                  setOpenKick={setOpenKick}
                  detailedUserInfo={detailedUserInfo}
                />
              </Grid>

              <Grid item sm={12} md={4}>
                <RobotParametersWidget
                  store={store}
                  robot={robot}
                  title={t(
                    'overseer_app.widget.robot_parameters',
                    'Parameters'
                  )}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <ScheduleWidget
                  title={t('overseer_app.widget.schedule', 'Schedule')}
                  store={store}
                  robot={robot}
                  rawRobotStatus={rawRobotStatus}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <CallerWidget
                  callMotives={callMotives}
                  getCallerHistory={getCallerHistory}
                  callerHistory={callerHistory}
                  postCallerAction={postCallerAction}
                  loadingCallerResponse={loadingNewCallerActionResponse}
                  newCallerActionResponse={newCallerActionResponse}
                  storeInfo={storeInfo}
                  storeLang={storeLang}
                  detailedUserInfo={detailedUserInfo}
                />
              </Grid>
            </Grid>
          )}
        </TabPanel>
        {/* Session Widgets */}
        <TabPanel value={tabIndex} index={1} sx={{ px: 3 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <AislesProgressWidget
                title={t(
                  'overseer_app.robot_view.aisle_progress',
                  'Aisle Progress'
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <PipelineProgressWidget store={store} robot={robot} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SessionPlanWidget
                title={t('overseer_app.widget.session_plan', 'Session Plan')}
                store={store}
                robotCode={`${store}-${robot}`}
                isLoadingRobotBlocking={isLoadingRobotBlocking}
                isLoadingTakeRobot={isLoadingTakeRobot}
                isLoadingLeaveRobot={isLoadingLeaveRobot}
                rawRobotStatus={rawRobotStatus}
                postUserActions={postUserActions}
                publishMessageCommand={publishMessageCommand}
                iHaveRobotTaken={iHaveRobotTaken}
                setIHaveRobotTaken={setIHaveRobotTaken}
                takeSelectedRobot={takeSelectedRobot}
                leaveSelectedRobot={leaveSelectedRobot}
                controlledBy={controlledBy}
                getRobotsTaken={getRobotsTaken}
                openKick={openKick}
                setOpenKick={setOpenKick}
                robotsTakenList={robotsTakenList}
                detailedUserInfo={detailedUserInfo}
                checkFeaturePermission={checkFeaturePermission}
                featurePermission={featurePermission}
                postActionReason={postActionReason}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <SessionLogsWidget
                client={client}
                topics={topics}
                monitoringLogs={monitoringLogs}
                isSessionLogsLoading={isSessionLogsLoading}
                setIsSessionLogsLoading={setIsSessionLogsLoading}
              />
            </Grid>
          </Grid>
        </TabPanel>
        {/* Timeline */}
        <TabPanel value={tabIndex} index={2} sx={{ px: 2 }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TimelineWidget
                storeInfo={storeInfo}
                getMapSignal={getMapSignal}
                getMappingAlerts={getMappingAlerts}
                store={store}
                robot={robot}
                mqttNavInfo={mqttNavInfo}
                storeMapInfo={storeMapInfo}
                minHeight={mapHeight}
                mapSignal={mapSignal}
                isLoadingMapSignalInfo={isLoadingMapSignalInfo}
                mappingAlerts={mappingAlerts}
                isMappingAlertsLoading={isMappingAlertsLoading}
              />
            </Grid>
          </Grid>
        </TabPanel>
        {/* Maintenance Widgets */}
        <TabPanel value={tabIndex} index={3} sx={{ px: 2 }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <RobotStateHistoryWidget
                store={store}
                robot={robot}
                setOpenState={setOpenState}
                featurePermission={featurePermission}
                checkFeaturePermission={checkFeaturePermission}
              />
            </Grid>
            <Grid item container xs={12} md={6} rowSpacing={2}>
              <Grid item xs={6}>
                <HardwareAlertsWidget store={store} robot={robot} />
              </Grid>
              <Grid item xs={6}>
                <HardwareComponentsWidget store={store} robot={robot} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ServicingHistoryWidget
                store={store}
                robot={robot}
                handleOpenDialog={handleOpenDialog}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <NewServicingDialog
          open={open}
          handleClose={handleCloseDialog}
          postNewServicing={postNewServicing}
          robotCode={`${store}-${robot}`}
          changeMotivesList={changeMotivesList}
          isLoadingChangeMotives={isLoadingChangeMotives}
          robotComponentsList={robotComponentsList}
          resetForm={resetForm}
          setResetForm={setResetForm}
          isLoadingNewServicing={isLoadingNewServicing}
        />
        <NewStateDialog
          robotCode={`${store}-${robot}`}
          robotState={robotState}
          open={openState}
          setOpenState={setOpenState}
          availableStatus={availableStatus}
          getAvailableRobotStatus={getAvailableRobotStatus}
          updateRobotState={updateRobotState}
          robotStateUpdateResponse={robotStateUpdateResponse}
          loadingNewRobotState={loadingNewRobotState}
          setResetForm={setResetForm}
          robotStateMotives={robotStateMotives}
        />
      </Grid>
      <ConfirmDialog
        open={openKick}
        handleClose={handleCloseKickDialog}
        handleConfirm={kickAndTake}
        elementObject={{
          title: t('overseer_app.widget.kick_control', 'Kick and take control'),
        }}
        isCommand={false}
      />
    </Grid>
  );
}
