import React, { Component } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { styled } from '@mui/material/styles';

// Redux components
import SideMenu from '../../redux/navigation/SideMenu';
import Navbar from '../../redux/navigation/Navbar';

// Styled components
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

/* Component Layout

  Layout of the navbar formed by the top navbar and left side Menu.

  Props:
    open: A booleano define whether the menu is open
*/
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleDrawer = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { children } = this.props;
    return (
      <Box style={{ display: 'flex' }}>
        <CssBaseline />
        {/* Top navigation bar */}
        <Navbar open={this.state.open} handleDrawer={this.handleDrawer} />

        {/* Left navigation bar */}
        <SideMenu
          open={this.state.open}
          handleDrawer={this.handleDrawer}
          DrawerHeader={DrawerHeader}
        />

        <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: "95vw" }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    );
  }
}

export default Layout;
