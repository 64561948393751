import { Box, Grid } from '@mui/material';
import { BarChart, Text, Title } from '@zippeditoolsjs/dashboards';

export default function CustomBarChart(props) {
  const {
    size,
    textOrientation = 'text-left',
    title,
    subtitle,
    chartData,
    dataFormatter,
    inputCategories,
    inputColors,
    inputIndex,
  } = props;

  return (
    <Grid container item xs={size}>
      <Grid item xs={12}>
        <Box className={textOrientation}>
          <Title className="mt-2">{title}</Title>
          <Text className={textOrientation}>{subtitle}</Text>
        </Box>
      </Grid>
      {chartData?.length ? (
        <Grid item xs={12}>
          <BarChart
            data={chartData}
            index={inputIndex}
            categories={inputCategories}
            colors={inputColors}
            valueFormatter={dataFormatter}
            yAxisWidth={48}
            onValueChange={(v) => console.log(v)}
          />
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
