export default function DataSourceService({ baseUrl, http, ...rest }) {
  const PATH_RETRIEVE_SCHEDULER = '/v1/retrieve_scheduler';
  const PATH_SESSIONS = '/v1/sessions';

  return {
    postRetrieveSessions,
    postNewSessions,
    updateNewSessions,
    cancelSessions,
  };

  function postRetrieveSessions(payload) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_RETRIEVE_SCHEDULER}`,
      body: payload
    });
  }

  function postNewSessions(sessions) {
    return http.postAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: sessions
    });
  }

  function updateNewSessions(sessions) {
    return http.patchAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: sessions
    });
  }

  function cancelSessions(sessions) {
    return http.deleteAuthenticated({
      url: `${baseUrl}${PATH_SESSIONS}`,
      body: sessions
    });
  }
}
