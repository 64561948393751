import DeleteIcon from "@mui/icons-material/Delete";
import { Box, MenuItem, Typography } from "@mui/material";
import React from "react";

import { DIALOG_TYPES, StyledMenu } from "./Utils";

function MenuMultiSessionTable(props) {
  const {
    anchorEl,
    open,
    handleClose,
    handleClickOpenNewSession,
    dateCellSelected,
    handleClickOpenDeleteSession,
    selectedSessions,
  } = props;

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id="sessions-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      {/* DELETE MULTIPLE */}
      {selectedSessions.length > 0 ? (
        <MenuItem
          onClick={() =>
            handleClickOpenDeleteSession(DIALOG_TYPES.MULTIPLE_CANCEL)
          }
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <DeleteIcon style={{ color: "#1B242B", fontSize: "1.8rem" }} />
          <Typography style={{ color: "#FF0000" }}>
            {`Cancel this group, ${dateCellSelected.date}`}
          </Typography>
        </MenuItem>
      ) : <Box sx={{ p: 1 }}><Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No Actions Available</Typography>
      </Box>}
    </StyledMenu>
  );
}

export default MenuMultiSessionTable;
