import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import Icon from '../../tools/Icon';

export default function ConfirmCancelSessionForm(props) {
  const {
    title = <Typography><b>This action can not be undone</b>. Are you sure you want to <span style={{ color: 'red' }}>cancel</span> this session?</Typography>,
    setOpenWarning,
    isDisabled = false,
    openWarning = false,
    selectedSessions = [],
    confirmRemoveSession,
    setConfirmRemoveSession,
    hasUniqueSessions = false,
  } = props;
  const { t } = useTranslation();

  // Handlers
  const handleSelectedRobotsCount = (selectedSessions) => {
    const uniqueRobots = [...new Set(selectedSessions.map((robot) => robot.robot_code))];
    return uniqueRobots.length;
  }

  return (
    <>
      {selectedSessions?.length > 1 ?
        <Box sx={(theme) => { return { border: `1px solid ${theme.palette['warning']?.main}55`, borderRadius: '5px', padding: '1em', backgroundColor: `${theme.palette['warning']?.main}11` } }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <Icon
              iconName={'warning'}
              color={'warning'}
            />
            <Typography variant="caption" sx={{ fontSize: 'small', ml: 1, color: '#777' }}>You are about to cancel <b>{selectedSessions.length}</b> sessions for <b>{handleSelectedRobotsCount(selectedSessions)}</b> selected robots. <b>This action can not be undone</b>, are you sure you want to continue?</Typography>
            <Tooltip title="Show robots">
              <IconButton onClick={() => { setOpenWarning(!openWarning) }} rotate={openWarning ? 180 : 0} sx={{ transition: 'transform 0.3s', transform: openWarning ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                <IconComponent
                  iconName={'chevron-down-outline'}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Collapse in={openWarning}>
            <List sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '10em', ml: 4 }}>
              {
                selectedSessions.map((robot, index) => (
                  <>
                    <ListItem key={`warning-robot-list-${index}`} sx={(theme) => ({ display: 'flex', alignItems: 'center', py: 1, mt: 1 })}>
                      <ListItemAvatar>
                        <Icon iconName={'information-circle'} color={'gray'} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${robot.robot_code} - ${robot.store_name} - Floor: ${robot.floor}`}
                        secondary={`Session type: ${robot.sessionType} - Start time: ${robot.sessionLocalStartTime}`}
                      />
                    </ListItem>
                    <Divider />
                  </>
                ))
              }
            </List>
          </Collapse>
        </Box>
        :
        <Typography variant="subtitle1" sx={{ maxWidth: '40em' }}>{title}</Typography>
      }
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <TextField
          id="cancel-confirmation-textarea"
          required
          error={confirmRemoveSession !== 'CANCEL' && confirmRemoveSession !== ''}
          label="To confirm this, type: CANCEL"
          placeholder="CANCEL"
          disabled={isDisabled}
          multiline
          value={confirmRemoveSession}
          onChange={(event) => { setConfirmRemoveSession(event.target.value); }}
          sx={{ width: '100%', minHeight: '5em' }}
        />
      </Box>
      {hasUniqueSessions && <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*The selected group has <b>unique sessions</b>, so it will be <span style={{ color: 'red' }}>cancelled</span> as a group <b>only for the selected day</b></Typography>}
    </>

  );
}
