import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
  Box,
  Link,
} from '@mui/material';
import { ContentCopy, Search, Warning } from '@mui/icons-material';

import MultiProgressBar from './MultiProgressBar';
import { CellDrawer } from './CellDrawer';
import { format } from 'date-fns';

export function MonitoringTableRow(props) {
  const {
    session,
    chain,
    store,
    storeName,
    plannedFor,
    objectData,
    selectedDate,
    test,
    showTestSessions,
    alertInfo,
    navigationReportSentTimestamp = '-',
    signBlob,
    signedblob,
    isLoadingSignedBlob,
  } = props;
  const [selectedObjectIndex, setSelectedObjectIndex] = useState(-1);
  const [selectedObject, setSelectedObject] = useState(undefined);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedReportBlob, setSelectedReportBlob] = useState();

  useEffect(() => {
    if (selectedObjectIndex !== -1) {
      setSelectedObject(objectData[selectedObjectIndex]);
    } else {
      setSelectedObject(undefined);
    }
  }, [selectedObjectIndex, objectData]);

  useEffect(() => {
    if (!isLoadingSignedBlob && signedblob && selectedReportBlob) {
      window.open(signedblob, '_blank');
      setSelectedReportBlob(null);
    }
  }, [isLoadingSignedBlob, signedblob]);

  const handleGoReport = (reportBlobPath) => {
    setSelectedReportBlob(reportBlobPath);
    signBlob({ blobPath: reportBlobPath, store });
  };

  const onCellClick = (index) => {
    if (index === selectedObjectIndex) {
      setOpenAlert(false);
      setSelectedObjectIndex(-1);
    } else {
      setSelectedObjectIndex(index);
      setOpenAlert(false);
    }
  };

  if ((showTestSessions && test) || !test) {
    return [
      <TableRow
        key={session}
        sx={{ backgroundColor: alertInfo ? '#FEF7EA' : null }}
      >
        <TableCell key={chain} align="center">
          <Typography>{chain}</Typography>
        </TableCell>
        <TableCell key={store} align="center">
          <Tooltip title={<Typography>{storeName}</Typography>}>
            <Typography>{store}</Typography>
          </Tooltip>
        </TableCell>

        <TableCell key={session} align="center">
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>{session}</Typography>
            <Box sx={{ display: 'flex' }}>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(session);
                }}
              >
                <ContentCopy />
              </IconButton>
              <IconButton
                href={`/pipeline_processing/${session}/${selectedDate}`}
              >
                <Search />
              </IconButton>
              {alertInfo ? (
                <Tooltip
                  title={`${alertInfo.incident.metric.displayName} at ${alertInfo?.incident?.metric?.labels.session_id} 
                    of store ${alertInfo?.incident?.metric?.labels.store_code} is above the threshold ${alertInfo.incident.threshold_value} 
                    with a value of ${alertInfo.incident?.observed_value}.`}
                >
                  <IconButton
                    onClick={() => {
                      setOpenAlert(!openAlert);
                      setSelectedObjectIndex(-1);
                      setSelectedObject(undefined);
                    }}
                  >
                    <Warning />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        </TableCell>

        <TableCell key={plannedFor} sx={{ textAlign: 'center' }}>
          <Tooltip title={<Typography>Planned Time</Typography>}>
            {plannedFor ? (
              <Typography>{format(new Date(plannedFor), 'HH:mm')}</Typography>
            ) : (
              <Typography>-</Typography>
            )}
          </Tooltip>
        </TableCell>
        {objectData.map((objectDict, index) => {
          return (
            <TableCell
              align="center"
              onClick={() => onCellClick(index)}
              key={`${session}-${objectDict['objectId']}`}
              height={20}
              width={150}
              sx={{
                backgroundColor:
                  alertInfo &&
                    parseInt(alertInfo.incident.metric.labels.object_id) ===
                    parseInt(objectDict['objectId'])
                    ? '#FFD95A'
                    : selectedObjectIndex === index
                      ? '#C7C7C7'
                      : null,

                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    alertInfo &&
                      parseInt(alertInfo.incident.metric.labels.object_id) ===
                      parseInt(objectDict['objectId'])
                      ? '#e0bf53'
                      : selectedObjectIndex === index
                        ? '#D9D9D9'
                        : '#D9D9D9',
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <MultiProgressBar
                  progresses={objectDict['progressValues']}
                  progressNames={objectDict['processNames']}
                  height={15}
                  maxValue={objectDict['maxValue']}
                  alertInfo={alertInfo}
                  handleGoReport={() =>
                    handleGoReport(objectDict['reportBlobPath'])
                  }
                  isLoadingSignedBlob={
                    isLoadingSignedBlob &&
                    selectedReportBlob === objectDict['reportBlobPath']
                  }
                  navigationReportSentTimestamp={
                    objectDict['navigationReportSentTimestamp']
                  }
                />
              </div>
            </TableCell>
          );
        })}
      </TableRow>,

      selectedObject || openAlert ? (
        <TableRow
          key={`drawer-${session}`}
          style={{
            background: alertInfo
              ? parseInt(alertInfo.incident.metric.labels?.object_id) ===
                parseInt(selectedObject?.objectId)
                ? '#FFD95A'
                : '#FEF7EA'
              : '#C7C7C7',
          }}
        >
          <TableCell colSpan={3 + objectData.length}>
            {selectedObject ? (
              <CellDrawer
                progressValues={selectedObject['progressValues']}
                processNames={selectedObject['processNames']}
                maxValue={selectedObject['maxValue']}
              />
            ) : (
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                  {alertInfo.incident.condition.displayName} Alert at{' '}
                  {alertInfo.incident.metric.labels.session_id}
                </Typography>
                <Typography sx={{ fontSize: 13 }}>
                  {`${alertInfo.incident.metric.displayName} at ${alertInfo.incident.metric.labels.session_id} 
                   of store ${alertInfo.incident.metric.labels.store_code} (ID: ${alertInfo.incident.metric.labels.store_id}) is above the threshold ${alertInfo.incident.threshold_value} 
                  with a value of ${alertInfo.incident.observed_value}`}
                </Typography>
                <Link href={alertInfo.incident.url} underline="always">
                  <Typography sx={{ fontSize: 13 }}>Go to incident</Typography>
                </Link>
              </Box>
            )}
          </TableCell>
        </TableRow>
      ) : undefined,
    ];
  } else {
    return null;
  }
}
