export const TIER_COLOR = Object.freeze({
  0: Symbol('pastelYellow'),
  1: Symbol('pastelEmerald'),
  2: Symbol('pastelSky'),
  3: Symbol('pastelViolet'),
  4: Symbol('teal'),
  5: Symbol('pink'),
});

export const getTierColor = (tier) => {
  let tierColor = TIER_COLOR[tier];
  tierColor = tierColor ? tierColor.description : 'slate';
  return tierColor;
};

/**
 * Transforms a hex color into a pastel color.
 * @param {string} hex - The hex color to transform.
 * @returns {string} - The pastel color in hex format.
 */
export function hexToPastel(hex) {
  // Ensure the hex is valid
  if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
    throw new Error('Invalid hex color');
  }

  // Convert the hex to RGB
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  // Mix the color with white
  r = Math.floor((r + 255) / 2);
  g = Math.floor((g + 255) / 2);
  b = Math.floor((b + 255) / 2);

  // Convert the pastel color back to hex
  const pastelHex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;

  return pastelHex;
}

/**
 * Combines the schedule with session state by updating each session in the sessionsList
 * with corresponding data from sessionsState.
 *
 * @param {Array} sessionsList - A list of robots, each containing a local scheduler with weeks, days, and sessions.
 * @param {Object} sessionsState - An object containing session state keyed by a combination of session ID, start date, and start time.
 */
export function combineScheduleWithSessionStates(sessionsList, sessionsState) {
  const newSessionList = JSON.parse(JSON.stringify(sessionsList));
  newSessionList.forEach(robots => {
    const targetInfo = ['local_scheduler', 'user_scheduler'];
    targetInfo.forEach(target => {
      robots[target].forEach(week =>
        week.forEach(day =>
          day?.sessions?.forEach(session => {
            const localID = `${session.session_id}-${day.date} ${session.local_start_time}`;
            if (localID in sessionsState) {
              const { cancelled, nav_start, nav_finish, nav_status } = sessionsState[localID];
              session.sessionName = sessionsState[localID].name;
              session.sessionState = { cancelled, nav_start, nav_finish, nav_status }
            }
          })
        )
      )
    })
  })
  return newSessionList;
}

export function handleGetUserUtcOffset() {
  const offsetInHours = (new Date()).getTimezoneOffset()
  const userLocalTime = -offsetInHours / 60;
  return userLocalTime;
}