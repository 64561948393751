import { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from './ConfirmDialog';
import { maskPhoneNumber } from '../tools/utils';

export default function CallerDialog(props) {
  const {
    open,
    setOpenState,
    callMotives,
    callerHistory,
    postCallerAction,
    loadingCallerResponse,
    store,
    contactId,
    phones,
    storeLang,
    detailedUserInfo,
  } = props;

  const { t } = useTranslation();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedCause, setSelectedCause] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const confirmTitle = t(
    'overseer_app.widget.caller_title_confirm',
    'Confirm call action'
  );

  const confirmMessage = t(
    'overseer_app.widget.caller_confirm_msg',
    `You are about to call the store for the following reason: "${t(
      `overseer_app.alerts.${selectedCause}`,
      { lng: storeLang }
    )}" . Do you want to continue?`,
    {
      selectedCause: t(`overseer_app.alerts.${selectedCause}`),
    }
  );

  const handleChange = (event) => {
    setSelectedCause(event.target.value);
  };

  const handleConfirm = () => {
    postCallerAction(
      store,
      phoneNumber,
      contactId,
      t(`overseer_app.alerts.description.${selectedCause}`, selectedCause, {
        lng: storeLang,
      }),
      selectedCause
    );
    setOpenConfirm(false);
    setOpenState(false);
    callerHistory.unshift({
      called_phone: maskPhoneNumber(phoneNumber),
      last_status: 'calling',
      started_at_simplified: 'Now',
      started_at: null,
      ended_at: null,
      username: detailedUserInfo.username,
      motive: selectedCause
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpenState(false)}>
      <DialogTitle>
        {t('overseer_app.widget.caller_dialog_title', 'Call the store')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          From here you can call the store staff directly to resolve a problem.
          It works with an automatic call so it is necessary to detail the
          following:
        </DialogContentText>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="cause-caller-label">
            {t('overseer_app.widget.cuase_dialog', 'Cause')}
          </InputLabel>
          <Select
            labelId="cause-caller-label"
            id="cause-caller"
            value={selectedCause}
            onChange={handleChange}
            label="Cause"
          >
            {callMotives.map((item) => (
              <MenuItem value={item.key}>
                {t(`overseer_app.alerts.${item.key}`, item.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="phones-caller-label">
            {t('overseer_app.widget.phones', 'Phones')}
          </InputLabel>
          <Select
            labelId="phones-caller-label"
            id="phone-caller"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            label="Cause"
          >
            {phones &&
              phones.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            disabled={loadingCallerResponse || !selectedCause || !phoneNumber}
            onClick={() => setOpenConfirm(true)}
          >
            {t('overseer_app.widget.call_button', 'Call')}
          </Button>
          {loadingCallerResponse && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
        </Box>
      </DialogActions>
      <ConfirmDialog
        open={openConfirm}
        title={confirmTitle}
        message={confirmMessage}
        cause={selectedCause}
        handleOpen={setOpenConfirm}
        handleConfirm={handleConfirm}
      />
    </Dialog>
  );
}
