import { Box, Skeleton, TextField, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

function not(a, b) {
  return a.filter((value) => !b.includes(value));
}

function intersection(a, b) {
  return a.filter((value) => b.includes(value));
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function DragTransferList(props) {
  const {
    options,
    inputSelected,
    setInputSelectedOptions,
    objectId = 'id',
    isDisabled = false,
    objectName = 'name',
    inputLabel = 'Selected',
    leftHeader = 'Not selected',
    rightHeader = 'Selected',
    id = 'transfer-list-id',
    leftTooltip = '',
    rightTooltip = '',
    maxLabelLength = 3,
    isEnumerated = false,
  } = props;
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [leftSearch, setLeftSearch] = useState('');
  const [rightSearch, setRightSearch] = useState('');
  const isInputChecked = useRef(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // Hooks
  useEffect(() => {
    if (options && !isInputChecked.current) {
      isInputChecked.current = inputSelected.length > 0;
      // removing the selected options from the options list to set the left list
      const newRight = [];
      const newLeft = [];
      const parsedInputSelected = inputSelected.map((item) => item[objectId]);
      const optionsAux = JSON.parse(JSON.stringify(options));
      // iteration respecting the order of the inputSelected
      parsedInputSelected.forEach((selected) => {
        const index = optionsAux.findIndex((option) => option[objectId] === selected);
        if (index !== -1) {
          newRight.push(optionsAux.splice(index, 1)[0]);
        }
      });
      newLeft.push(...optionsAux);
      setRight(newRight);
      setLeft(newLeft);
    }
  }, [options, inputSelected]);

  useEffect(() => {
    if (right.length || (right.length === 0 && inputSelected?.length !== 0)) {
      setInputSelectedOptions(right);
    }
  }, [right]);

  // Handlers
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items.filter((item) => !item.excluded)));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Mover entre listas
    if (source.droppableId === destination.droppableId) {
      const items = source.droppableId === leftHeader
        ? Array.from(left)
        : Array.from(right);
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);

      if (source.droppableId === leftHeader) {
        setLeft(items);
      } else {
        setRight(items);
      }
    } else {
      const sourceItems = Array.from(source.droppableId === leftHeader ? left : right);
      const destinationItems = Array.from(destination.droppableId === leftHeader ? left : right);

      const [removed] = sourceItems.splice(source.index, 1);
      destinationItems.splice(destination.index, 0, removed);

      if (source.droppableId === leftHeader) {
        setLeft(sourceItems);
        setRight(destinationItems);
      } else {
        setRight(sourceItems);
        setLeft(destinationItems);
      }
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const filterItems = (items, searchTerm) => {
    return items.filter((item) =>
      item[objectName].toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const customList = (title, items, tooltip = '', searchValue, setSearchValue, isEnumerated = false) => {
    const CARD_HEIGHT = 88;
    const ITEMS_HEIGHT = 46;
    const maxHeight = maxLabelLength * CARD_HEIGHT;
    const maxHeightList = (maxLabelLength) * ITEMS_HEIGHT;
    const filteredItems = filterItems(items, searchValue);

    return (
      <Card id={id} sx={{ maxHeight: `${maxHeight}px`, mx: '1px' }}>
        {
          tooltip &&
          <Tooltip title={tooltip} placement="top">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', 'width': '100%', height: 0 }}>
              <IconComponent iconName='information-circle' style={{ fontSize: "20px", color: "#DDD", marginRight: 3 }} />
            </Box>
          </Tooltip>
        }
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={
                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
              }
              disabled={items.length === 0 || isDisabled}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} ${inputLabel}`}
        />
        <TextField
          id={`search-${title}-${id}`}
          key={`search-${title}-${id}`}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={`Search ${title.toLowerCase()}`}
          fullWidth
          variant="outlined"
          size="small"
          sx={{ marginBottom: 1, px: '3px' }}
        />
        <Divider />
        <Droppable droppableId={title} key={title}>
          {(provided) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                width: 'auto',
                maxWidth: 200,
                height: 230,
                maxHeight: `${maxHeightList}px`,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
              dense
              component="div"
              role="list"
            >
              {filteredItems.map((item, index) => (
                <Draggable
                  key={`${item[objectId]}-${index}-${id}`}
                  draggableId={String(item[objectId])}
                  index={index}
                  isDragDisabled={item.excluded}
                >
                  {(provided) => (
                    <ListItemButton
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      role="listitem"
                      onClick={handleToggle(item)}
                      disabled={item.excluded | isDisabled}
                    >
                      <ListItemText primary={isEnumerated ? `${index + 1}°` : ''} sx={{ flex: 'none!important' }} />
                      <ListItemIcon>
                        <Checkbox
                          disabled={isDisabled}
                          checked={checked.includes(item)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': `transfer-list-all-item-${item[objectId]}-label`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`transfer-list-all-item-${item[objectId]}-label`}
                        primary={item[objectName]}
                        secondary={item.secondary} />

                    </ListItemButton>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </Card >
    )
  };

  return (
    isInputChecked ?
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={5}>
            {customList(leftHeader, left, leftTooltip, leftSearch, setLeftSearch)}
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" sx={{ alignItems: 'center' }}>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || isDisabled}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || isDisabled}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {customList(rightHeader, right, rightTooltip, rightSearch, setRightSearch, isEnumerated)}
          </Grid>
        </Grid>
      </DragDropContext>
      :
      <Skeleton variant="rectangular" width="100%" height={200} />
  );
}
