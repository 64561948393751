import {
  handleAvailable,
  handleAvailableAddObject,
  handleRequest,
  handleUnavailable,
  handleUnavailableAddObject,
} from '../reducerHandlers';
import {
  SNACK_SET,
  STORE_AVAILABLE,
  STORE_AVAILABLE_OBJECT,
  STORE_REQUEST,
  STORE_UNAVAILABLE,
  STORE_UNAVAILABLE_OBJECT,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  storeContacts: [],
  isLoadingStoreContacts: false,
  putResponse: null,
  isLoadingStoreContactsPut: false,
};

export const reducer = createReducer(defaultState, {
  [STORE_REQUEST]: handleRequest,
  [STORE_AVAILABLE]: handleAvailable,
  [STORE_UNAVAILABLE]: handleUnavailable,
  [STORE_AVAILABLE_OBJECT]: handleAvailableAddObject,
  [STORE_UNAVAILABLE_OBJECT]: handleUnavailableAddObject,
});

export function getStoreContacts() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeContacts'
    const loadingName = 'isLoadingStoreContacts'
    dispatch({ type: STORE_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getStoreContacts();
      dispatch({
        type: STORE_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the store contacts.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function putStoreContacts({ store_contact_id, store_id, phones, emails }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'putResponse'
    const loadingName = 'isLoadingStoreContactsPut'
    dispatch({ type: STORE_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.putStoreContacts(store_contact_id, store_id, phones, emails);
      dispatch({
        type: STORE_AVAILABLE,
        payload: { keyState: keyState, data: response, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: null },
      });
      const snack = {
        open: true,
        message: "There was an error updating the store contacts.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function resetPutResponse() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'putResponse'
    const loadingName = 'isLoadingStoreContactsPut'
    try {
      dispatch({
        type: STORE_AVAILABLE,
        payload: { keyState: keyState, data: null, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: null },
      });
      const snack = {
        open: true,
        message: "There was an error resetting the put response.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getStoresUtcOffset({ store, coordinates }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storesUtcOffset'
    const loadingName = 'isLoadingStoresUtcOffset'
    dispatch({ type: STORE_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getStoresUtcOffset(JSON.stringify(coordinates));
      const storeUtc = response?.store_utc_offset ?? undefined;
      dispatch({
        type: STORE_AVAILABLE_OBJECT,
        payload: { keyState: keyState, data: { [store]: storeUtc }, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: STORE_UNAVAILABLE_OBJECT,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: {} },
      });
      const snack = {
        open: true,
        message: "There was an error getting the stores utc offset.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}