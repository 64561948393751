import {
  BALDUR_AVAILABLE,
  BALDUR_REQUEST,
  BALDUR_UNAVAILABLE,
  SNACK_SET,
} from '../constants';
import { createReducer } from '../utils';

const defaultState = {
  isLoadingSessions: false,
  sessionsTable: [],
  newSessionResponse: null,
  loadingCancelSessionResponse: false,
  cancelMessage: null,
};

import {
  handleAvailable,
  handleRequest,
  handleUnavailable,
} from '../../reducerHandlers';

export const reducer = createReducer(defaultState, {
  [BALDUR_REQUEST]: handleRequest,
  [BALDUR_AVAILABLE]: handleAvailable,
  [BALDUR_UNAVAILABLE]: handleUnavailable,
});


export function getSessionsScheduler({ robotsData, from, to, user_utc_offset }) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const loadingName = 'isLoadingSessions';
    const keyState = 'sessionsTable';
    dispatch({ type: BALDUR_REQUEST, payload: { loadingName } });
    try {
      const sessionsResponse = await baldurSource.postRetrieveSessions({ robots_data: JSON.stringify(robotsData), local_start_date: from, local_end_date: to, user_utc_offset });
      const sessionsScheduler = sessionsResponse?.data ?? [];
      dispatch({
        type: BALDUR_AVAILABLE,
        payload: { keyState, loadingName, data: sessionsScheduler },
      });
    } catch (error) {
      console.error('error: ', error);
      let errorJson;
      try {
        const errorMsg = error.message;
        errorJson = JSON.parse(errorMsg);
      } catch (error) {
        errorJson = { data: 'Error fetching sessions' };
      }
      const snack = {
        open: true,
        message: errorJson.data,
        severity: 'warning',
      };
      dispatch({
        type: SNACK_SET,
        payload: { snack },
      });
      dispatch({
        type: BALDUR_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    };
  };
}

/**
 * Dispatches an action to post new sessions to the baldurSource service.
 *
 * @param {Object} sessions - The sessions data to be posted.
 * @returns {Function} A thunk function that handles the asynchronous posting of sessions.
 *
 * @example
 * const sessions = {
 *   coordinates: {"lat": 41.38, "lng": 2.17},
 *   local_start_date: '2023-10-01',
 *   local_start_time: '10:00',
 *   robot_uuid: 'robot-uuid',
 *   aisles: ['aisle1', 'aisle2'],
 *   comments: {'comments': yyy, 'reason': zzz},
 *   local_end_date: '2023-10-01',
 *   max_minutes: 60,
 *   overhead_mode: true,
 *   periodic_session_id: 'periodic-id',
 *   week_days: ['Monday', 'Wednesday'],
 *   zones: ['zone1', 'zone2']
 * };
 */
export function postNewSessions(sessions) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const loadingName = 'loadingNewSessionResponse';
    const keyState = 'newSessionResponse';
    dispatch({ type: BALDUR_REQUEST, payload: { loadingName } });
    try {
      const creationResponse = await baldurSource.postNewSessions(sessions);
      const creation = creationResponse ? creationResponse : {};
      dispatch({
        type: BALDUR_AVAILABLE,
        payload: { keyState, loadingName, data: creation },
      });
    } catch (error) {
      console.error('postNewSessions error: ', error);
      try {
        const errorMsg = error.message;
        const errorJson = JSON.parse(errorMsg);
        dispatch({
          type: BALDUR_AVAILABLE,
          payload: { keyState, loadingName, data: errorJson },
        });
      } catch (error) {
        console.error('postNewSessions Json Error: ', error);
        dispatch({
          type: BALDUR_UNAVAILABLE,
          payload: { keyState, loadingName },
        });
      }
    }
  };
}

/**
 * Updates new sessions by dispatching actions to the Redux store.
 *
 * @param {Array} sessions - The sessions to be updated.
 * @returns {Function} A thunk function that performs the update operation.
 *
 * @example
 * const sessions = [{
 *   coordinates: {"lat": 41.38, "lng": 2.17},
 *   local_start_date: '2023-10-01',
 *   local_start_time: '10:00',
 *   robot_uuid: 'robot-uuid',
 *   aisles: ['aisle1', 'aisle2'],
 *   comments: {'comments': yyy, 'reason': zzz},
 *   local_end_date: '2023-10-01',
 *   max_minutes: 60,
 *   overhead_mode: true,
 *   periodic_session_id: 'periodic-id',
 *   week_days: ['Monday', 'Wednesday'],
 *   zones: ['zone1', 'zone2']
 * }, ...];
 * 
 * @async
 * @function
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Object} _ - Unused parameter.
 * @param {Object} services - The services object containing baldurSource.
 * @param {Object} services.baldurSource - The source service for updating sessions.
 *
 * @throws Will log an error to the console if the update operation fails.
 */
export function updateNewSessions(sessions) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const loadingName = 'loadingNewSessionResponse';
    const keyState = 'newSessionResponse';
    dispatch({ type: BALDUR_REQUEST, payload: { loadingName } });
    try {
      const updateResponse = await baldurSource.updateNewSessions(sessions);
      const update = updateResponse ? updateResponse : {};
      dispatch({
        type: BALDUR_AVAILABLE,
        payload: { keyState, loadingName, data: update },
      });
    } catch (error) {
      console.error('error: ', error);
      try {
        const errorMsg = error.message;
        const errorJson = JSON.parse(errorMsg);
        dispatch({
          type: BALDUR_AVAILABLE,
          payload: { keyState, loadingName, data: errorJson },
        });
      } catch (error) {
        dispatch({
          type: BALDUR_UNAVAILABLE,
          payload: { keyState, loadingName },
        });
      }
    }
  };
}

/**
 * Deletes authenticated sessions and dispatches the appropriate actions based on the response.
 *
 * @param {Array} sessions - The sessions to be deleted.
 * @returns {Function} A thunk function that performs the deletion and dispatches actions.
 */
export function cancelSessions(sessions) {
  return async (dispatch, _, { services: { baldurSource } }) => {
    const loadingName = 'loadingCancelSessionResponse';
    const keyState = 'cancelMessage';
    dispatch({ type: BALDUR_REQUEST, payload: { loadingName } });
    try {
      const deleteResponse = await baldurSource.cancelSessions(sessions);
      const deletion = deleteResponse ? deleteResponse : {};
      dispatch({
        type: BALDUR_AVAILABLE,
        payload: { keyState, loadingName, data: deletion },
      });
    } catch (error) {
      console.error('error: ', error);
      try {
        const errorMsg = error.message;
        const errorJson = JSON.parse(errorMsg);
        dispatch({
          type: BALDUR_AVAILABLE,
          payload: { keyState, loadingName, data: errorJson },
        });
      } catch (error) {
        dispatch({
          type: BALDUR_UNAVAILABLE,
          payload: { keyState, loadingName },
        });
      }
    }
  };
}

export function removeCancelSessionResponse() {
  return async (dispatch) => {
    const loadingName = 'loadingCancelSessionResponse';
    dispatch({ type: BALDUR_UNAVAILABLE, payload: { keyState: 'cancelMessage', loadingName, defaultState: null } });
  };
}

export function removeNewSessionResponse() {
  return async (dispatch) => {
    const loadingName = 'loadingNewSessionResponse';
    dispatch({ type: BALDUR_UNAVAILABLE, payload: { keyState: 'newSessionResponse', loadingName, defaultState: null } });
  };
}
