import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Chip, Divider, Grid, Link, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { isEmptyOrUndefined, DatePicker, MultiSelector } from "@zippeditoolsjs/blocks";
import { DataBar } from "@zippeditoolsjs/dashboards";
import { DataGrid } from "@zippeditoolsjs/table";

import CustomIcon from '../tools/CustomIcon';
import { FormatToSentenceCase } from 'core/utils/parsers';
import PageLink from '../tools/PageLink';

const CARD_CONTENT_HEIGHT = '11em'

const columns = (t) => {
  const columns = [
    { field: 'store_code', headerName: t('overseer_app.metrics.Store_code', 'Store Code'), flex: 1 },
    { field: 'full_store_name', headerName: t('overseer_app.metrics.Name', 'Name'), flex: 1 },
    {
      field: 'robot_id',
      headerName: t('overseer_app.metrics.Robot_id', 'Robot ID'),
      flex: 1,
      renderCell: (params) => {
        return (
          <PageLink
            isRedirect
            onlyText
            text={params.row.robot_id}
            currentState={{
              tabIndex: 2
            }}
            view={`/robot/${params.row.store_code}/${params.row.robot_id.split("-")[1]}`}
            firstTooltipTitle={t('overseer_app.metrics.Robots_timeline', "Go to the robot's timeline")}
            secondTooltipTitle=''
          />
        );
      },
    },
    { field: 'session', headerName: t('overseer_app.metrics.Session', 'Session'), flex: 2 },
    {
      field: 'navigation_status',
      headerName: t('overseer_app.metrics.Nav_status', 'Nav Status'),
      flex: 1,
      valueFormatter: (params) => FormatToSentenceCase(params.value)
    },
    {
      field: 'navigated_percentage',
      headerName: t('overseer_app.metrics.Nav_conditions', 'Nav Conditions'),
      flex: 1,
      valueGetter: ({ row }) => row.navigation?.navigated_percentage,
      valueFormatter: (params) => displayDashWhenNull(params?.value) + '%'
    },
    {
      field: 'connected_percentage',
      headerName: t('overseer_app.metrics.Connectivity', 'Connectivity'),
      flex: 1,
      valueGetter: ({ row }) => row.connectivity?.connected_percentage,
      valueFormatter: (params) => displayDashWhenNull(params?.value) + '%'
    },
    {
      field: 'alerts_count',
      headerName: t('overseer_app.metrics.Issues', 'Issues'),
      flex: 1,
      valueGetter: ({ row }) => row.alerts_count,
      valueFormatter: (params) => displayDashWhenNull(params?.value)
    },
    {
      field: 'linear_issues_count',
      headerName: t('overseer_app.metrics.Linear_issues', 'Linear Issues'),
      flex: 1,
      valueGetter: ({ row }) => row.navigation?.linear_issues_count,
      valueFormatter: (params) => displayDashWhenNull(params?.value)
    },
    {
      field: 'tickets_count',
      headerName: t('overseer_app.metrics.Tickets', 'Tickets'),
      flex: 1,
      valueFormatter: (params) => displayDashWhenNull(params?.value)
    }
  ]
  return columns;
}

const getNavigationStatusColor = (row) => {
  if (row.navigation_status === 'finished') return 'success'
  else if (row.navigation_status === 'not_started') return 'default'
  else if (row.navigation_status === 'timed_out') return 'warning'
  else if (row.navigation.sessions_cancellation_metadata?.store_imposed) return 'info'
  else return 'error'
}

const displayDashWhenNull = (variable) => {
  if (variable === null || variable === undefined) return '-';
  return variable
}

export default function MonitoringSumary(props) {
  const {
    navigationStats,
    date,
    setDate,
    navigationStatus,
    navStatusOptionFormatter,
    navigationStatuses,
    isLoadingNavigationStatuses,
  } = props;
  const { t } = useTranslation();
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [filteredStats, setFilteredStats] = useState({});

  // Lifecycle Methods

  useEffect(() => {
    setFilteredStats(navigationStats)
  }, [navigationStats])

  // Methods

  return (
    <Grid container spacing={2}>
      {/* Selectors */}
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={2}>
          <DatePicker
            fullWidth
            value={date}
            setValue={setDate}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MultiSelector
            options={navigationStatuses}
            value={navigationStatus}
            setValue={navStatusOptionFormatter}
            label={t('overseer_app.metrics.Nav_status', 'Nav Status')}
            name='name'
            tagName='name'
            loading={isLoadingNavigationStatuses}
            limitTags={2}
            textFieldVariant={"outlined"}
          />
        </Grid>
      </Grid>
      {isEmptyOrUndefined(filteredStats, 'object') ?
        <Grid container item justifyContent='center'>
          <Typography>{t('overseer_app.metrics.No_data', 'No data.')}</Typography>
        </Grid>
        :
        <Grid container item direction='column' spacing={2}>
          {/* Cards */}
          <Grid container item spacing={2}>
            {/* Summary */}
            <Grid item xs={12} sm={6} md={4} xl={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems='center'>
                        <Typography variant='h6'>{t('overseer_app.metrics.Summary', 'Summary')}</Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow, 'object') ?
                      // General stats
                      <Grid container height='100%'>
                        <Grid container item direction='column' xs={6} justifyContent='space-between'>
                          <Typography>{t('overseer_app.metrics.Finished', 'Finished')}</Typography>
                          <Typography>{t('overseer_app.metrics.Timed_out', 'Timed Out')}</Typography>
                          <Typography>{t('overseer_app.metrics.Aborted', 'Aborted')}</Typography>
                          <Typography>{t('overseer_app.metrics.Cancelled', 'Cancelled')}</Typography>
                          <Typography>{t('overseer_app.metrics.Not_started', 'Not Started')}</Typography>
                        </Grid>
                        <Grid container item direction='column' xs={6} justifyContent='space-between' alignItems='center'>
                          {/* Finished */}
                          <Grid item>
                            <Chip label={filteredStats?.finished} color='success' size='small' />
                          </Grid>
                          {/* Timed Out */}
                          <Grid item>
                            <Chip label={filteredStats?.timed_out} color='warning' size='small' />
                          </Grid>
                          {/* Aborted */}
                          <Grid container item justifyContent='space-around'>
                            <Tooltip title={t('overseer_app.metrics.store_imposed', 'store imposed')}>
                              <Chip label={filteredStats?.aborted_store_imposed} color='info' size='small' />
                            </Tooltip>
                            <Tooltip title={t('overseer_app.metrics.zippedi_imposed', 'zippedi imposed')}>
                              <Chip label={filteredStats?.aborted_zippedi_imposed} color='error' size='small' />
                            </Tooltip>
                          </Grid>
                          {/* Cancelled */}
                          <Grid container item justifyContent='space-around'>
                            <Tooltip title={t('overseer_app.metrics.store_imposed', 'store imposed')}>
                              <Chip label={filteredStats?.cancelled_store_imposed} color='info' size='small' />
                            </Tooltip>
                            <Tooltip title={t('overseer_app.metrics.zippedi_imposed', 'zippedi imposed')}>
                              <Chip label={filteredStats?.cancelled_zippedi_imposed} color='error' size='small' />
                            </Tooltip>
                          </Grid>
                          {/* Not Started */}
                          <Grid item>
                            <Chip label={filteredStats?.not_started} color='default' size='small' />
                          </Grid>
                        </Grid>
                      </Grid>
                      :
                      // Individual stats
                      <Stack direction='row' height='100%'>
                        <Grid container direction='column' justifyContent='space-between'>
                          <Typography variant='body2'>{t('overseer_app.metrics.Status', 'Status')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Reason', 'Reason')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Nav_start', 'Nav Start')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Nav_finish', 'Nav Finish')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Nav_duration', 'Nav Duration')}</Typography>
                        </Grid>
                        <Grid container direction='column' justifyContent='space-between' alignItems='center'>
                          <Chip label={FormatToSentenceCase(selectedRow.navigation_status)} size="small" color={getNavigationStatusColor(selectedRow)} />
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.sessions_cancellation_metadata?.reason)}</Typography>
                          <Tooltip
                            title={`${t('overseer_app.metrics.Planned', 'Planned')}: ${displayDashWhenNull(selectedRow.planned_for)}`}
                          >
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.nav_start)}</Typography>
                          </Tooltip>
                          <Tooltip
                            title={`${t('overseer_app.metrics.Planned', 'Planned')}: ${displayDashWhenNull(selectedRow.navigation.planned_finish)}`}
                          >
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.nav_finish)}</Typography>
                          </Tooltip>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.nav_elapsed_time)}</Typography>
                        </Grid>
                      </Stack>
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {/* Nav conditions */}
            <Grid item xs={12} sm={6} md={4} xl={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems='center'>
                        <Typography variant='h6'>{t('overseer_app.metrics.Nav_conditions', 'Nav Conditions')}</Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow, 'object') ?
                      // General stats
                      <Stack direction='row' height='100%' justifyContent='center' alignItems='center'>
                        <DataBar addLabel type="progressCircle" value={filteredStats?.navigated_percentage} />
                      </Stack>
                      :
                      // Individual stats
                      <Stack direction='row' height='100%'>
                        <Grid container direction='column' justifyContent='space-between'>
                          <Typography variant='body2'>{t('overseer_app.metrics.Navigated', 'Navigated')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Omitted', 'Omitted')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Not_navigated', 'Not Navigated')}</Typography>
                          <Typography variant='body2'>{t('overseer_app.metrics.Planned', 'Planned')}</Typography>
                        </Grid>
                        <Grid container direction='column' justifyContent='space-between' alignItems='center'>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.navigated_linears_count)}</Typography>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.omitted_linears_count)}</Typography>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.not_navigated_count)}</Typography>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.planned_linears_count)}</Typography>
                        </Grid>
                        <Grid container direction='column' justifyContent='space-between' alignItems='center'>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation?.navigated_percentage)}%</Typography>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation?.omitted_linears_percentage)}%</Typography>
                          <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation?.not_navigated_percentage)}%</Typography>
                          <Typography variant='body2'>-</Typography>
                        </Grid>
                      </Stack>
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {/* Connectivity */}
            <Grid item xs={12} sm={6} md={4} xl={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems='center'>
                        <Typography variant='h6'>{t('overseer_app.metrics.Connectivity', 'Connectivity')}</Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow, 'object') ?
                      // General stats
                      <Stack direction='row' height='100%' justifyContent='center' alignItems='center'>
                        <DataBar addLabel type="progressCircle" value={filteredStats?.connected_percentage} />
                      </Stack>
                      :
                      // Individual stats
                      <Stack sx={{ overflow: 'auto' }}>
                        <Stack direction='row'>
                          <Grid container item direction='column' xs={4}>
                            <Typography variant='body2'>{t('overseer_app.metrics.Connected', 'Connected')}</Typography>
                            <Typography variant='body2'>{t('overseer_app.metrics.Disconnected', 'Disconnected')}</Typography>
                            <Typography variant='body2'>{t('overseer_app.metrics.Total', 'Total')}</Typography>
                          </Grid>
                          <Grid container item direction='column' xs={5} alignItems='center'>
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.connectivity.connected_time)}</Typography>
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.connectivity.disconnected_time)}</Typography>
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.nav_elapsed_time)}</Typography>
                          </Grid>
                          <Grid container item direction='column' xs={3} alignItems='center'>
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.connectivity.connected_percentage)}%</Typography>
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.connectivity.disconnected_percentage)}%</Typography>
                            <Typography variant='body2'>{displayDashWhenNull(selectedRow.navigation.nav_elapsed_percentage)}%</Typography>
                          </Grid>
                        </Stack>
                        <Stack sx={{ marginTop: 2 }}>
                          <Divider />
                          {isEmptyOrUndefined(selectedRow.connectivity.alerts, 'array') ?
                            <Typography variant='body2'>{t('overseer_app.metrics.No_alerts', 'No alerts.')}</Typography>
                            :
                            <Stack spacing={2}>
                              {selectedRow.connectivity?.alerts.map(alert => (
                                <Stack key={alert?.created_at} direction='row' spacing={2} alignItems='center'>
                                  <CustomIcon icon={alert.icon} />
                                  <Stack>
                                    <Typography>{alert?.title_name}</Typography>
                                    <Typography variant='caption'>{t('overseer_app.metrics.Created', 'Created')}: {alert?.created_at}</Typography>
                                    <Typography variant='caption'>{t('overseer_app.metrics.Suppressed', 'Suppressed')}: {alert?.suppressed_at}</Typography>
                                  </Stack>
                                </Stack>
                              ))}
                            </Stack>
                          }
                        </Stack>
                      </Stack>
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {/* Issues */}
            <Grid item xs={12} sm={6} md={4} xl={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems='center'>
                        <Typography variant='h6'>{t('overseer_app.metrics.Issues', 'Issues')}</Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow, 'object') ?
                      // General stats
                      <Stack direction='row' height='100%' justifyContent='center' alignItems='center'>
                        <Typography variant='h4'>{filteredStats?.alerts}</Typography>
                      </Stack>
                      :
                      // Individual stats
                      <>
                        {isEmptyOrUndefined(selectedRow.alerts, 'object') ?
                          <Typography>{t('overseer_app.metrics.No_alerts', 'No alerts.')}</Typography>
                          :
                          <Stack
                            divider={<Divider />}
                            sx={{ overflow: 'auto' }}
                          >
                            {selectedRow.alerts?.map(alert => (
                              <Stack key={alert.alert_id} direction='row' spacing={2} alignItems='center'>
                                <CustomIcon icon={alert.icon} />
                                <Stack>
                                  <Typography>{alert.title_name}</Typography>
                                  <Typography variant='caption'>{alert.count} {t('overseer_app.metrics.Alerts', 'alerts')}</Typography>
                                </Stack>
                              </Stack>
                            ))}
                          </Stack>
                        }
                      </>
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {/* Linear issues */}
            <Grid item xs={12} sm={6} md={4} xl={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems='center'>
                        <Typography variant='h6'>{t('overseer_app.metrics.Linear_issues', 'Linear Issues')}</Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow, 'object') ?
                      // General stats
                      <Stack direction='row' height='100%' justifyContent='center' alignItems='center'>
                        <Typography variant='h4'>{filteredStats?.linear_issues}</Typography>
                      </Stack>
                      :
                      // Individual stats
                      <>
                        {isEmptyOrUndefined(selectedRow.navigation, 'object') ?
                          <Typography>{t('overseer_app.metrics.No_data', 'No data .')}</Typography>
                          :
                          <Stack sx={{ overflow: 'auto' }}>
                            <Accordion disableGutters>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                              >
                                {t('overseer_app.metrics.Omitted', 'Omitted')} ({displayDashWhenNull(selectedRow.navigation.omitted_linears?.length)})
                              </AccordionSummary>
                              <AccordionDetails>
                                {isEmptyOrUndefined(selectedRow.navigation.omitted_linears, 'array') ?
                                  <Typography>{t('overseer_app.metrics.No_omitted_linears', 'No omitted linears.')}</Typography>
                                  :
                                  <Stack
                                    divider={<Divider />}
                                  >
                                    {selectedRow.navigation.omitted_linears.map(linear => (
                                      <>
                                        <Typography>{linear.aisle}{!isEmptyOrUndefined(linear.aisles_cancellation_metadata, 'object')}</Typography>
                                        <Typography variant='caption'>{isEmptyOrUndefined(linear.aisles_cancellation_metadata, 'object') ? '-' : linear.aisles_cancellation_metadata.reason}</Typography>
                                      </>
                                    ))}
                                  </Stack>
                                }
                              </AccordionDetails>
                            </Accordion>
                            <Accordion disableGutters>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                              >
                                {t('overseer_app.metrics.Not_navigated', 'Not Navigated')} ({displayDashWhenNull(selectedRow.navigation.not_navigated_linears?.length)})
                              </AccordionSummary>
                              <AccordionDetails>
                                {isEmptyOrUndefined(selectedRow.navigation.not_navigated_linears, 'array') ?
                                  <Typography>{t('overseer_app.metrics.No_not_navigated_linears', 'No not naviated linears.')}</Typography>
                                  :
                                  <Stack
                                    divider={<Divider />}
                                  >
                                    {selectedRow.navigation.not_navigated_linears.map(linear => (
                                      <Typography>{linear}</Typography>
                                    ))}
                                  </Stack>
                                }
                              </AccordionDetails>
                            </Accordion>
                          </Stack>
                        }
                      </>
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            {/* Tickets */}
            <Grid item xs={12} sm={6} md={4} xl={2}>
              <Card>
                <CardContent>
                  <Stack spacing={2} height={CARD_CONTENT_HEIGHT}>
                    {/* Title */}
                    <Stack>
                      <Stack alignItems='center'>
                        <Typography variant='h6'>{t('overseer_app.metrics.Tickets', 'Tickets')}</Typography>
                      </Stack>
                      <Divider />
                    </Stack>
                    {/* Content */}
                    {isEmptyOrUndefined(selectedRow, 'object') ?
                      // General stats
                      <Stack direction='row' height='100%' justifyContent='center' alignItems='center'>
                        <Typography variant='h4'>{filteredStats?.tickets}</Typography>
                      </Stack>
                      :
                      // Individual stats
                      <>
                        {isEmptyOrUndefined(selectedRow.tickets, 'object') ?
                          <Typography>{t('overseer_app.metrics.No_tickets', 'No tickets.')}</Typography>
                          :
                          <Stack
                            divider={<Divider />}
                            sx={{ overflow: 'auto' }}
                          >
                            {selectedRow.tickets.map(ticket => (
                              <Stack key={ticket.url} direction='row' spacing={2} alignItems='center'>
                                <CustomIcon icon={ticket.icon} />
                                <Stack>
                                  <Typography>{ticket.title_name}</Typography>
                                  <Link key={ticket} href={ticket.url} variant="caption" color="secondary" target="_blank" rel="noopener">{t('overseer_app.metrics.Link_to_clickup', 'Link to Clickup')}</Link>
                                </Stack>
                              </Stack>
                            ))}
                          </Stack>
                        }
                      </>
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {/* Table */}
          <Grid container item>
            <DataGrid
              rows={filteredStats?.individual_stats}
              columns={columns(t)}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              getRowId={(row) => row.robot_id + '.' + row.store_code + '.' + row.session}
              onRowSelectionModelChange={(newRowId) => {
                setSelectedRowId(newRowId);
                if (!isEmptyOrUndefined(newRowId, 'array')) {
                  const ids = newRowId[0].split('.');
                  const robot_id = ids[0], store_code = ids[1], session = ids[2];
                  let newSelectedRow = filteredStats?.individual_stats?.filter(row => row.robot_id === robot_id && row.store_code === store_code && row.session === session)
                  newSelectedRow = isEmptyOrUndefined(newSelectedRow, 'array') ? {} : newSelectedRow[0]
                  setSelectedRow(newSelectedRow)
                } else {
                  setSelectedRow({})
                }
              }}
              rowSelectionModel={selectedRowId}
            />
          </Grid>
        </Grid>
      }
    </Grid>
  )
}