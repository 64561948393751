import { useEffect } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import NotFound from '../notFound/NotFound';
import { useTranslation } from 'react-i18next';

export default function Link(props) {
  const { setLinkState } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    let state = urlParams.get('state');

    if (state && view) {
      const decodedState = Buffer.from(state, 'base64').toString('utf8');
      state = JSON.parse(decodedState);
      setLinkState(state);
      navigate(view)
    }
  }, [setLinkState, navigate]);

  // TODO: add a more apropiate photo, like a sad/dead robot with a 404
  return (
    <NotFound
      sx={{ pt: 3 }}
      title={t('overseer_app.http_messages.oops_something_wrong', 'Oops! Something went wrong.')}
      content={t('overseer_app.http_messages.check_url', 'The page you requested cannot be found. Please check the URL and try again.')}
    />
  );
};
