import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

//Icons
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import logo_zippedi from '../../../images/zippedi_only_letters_white.png';

// Sounds
import NotificationSound from "../../../sounds/notification-sound.mp3";

import Notification from './Notification';
// redux

import MyMenu from '../../redux/navigation/MyMenu';

// Styled components
const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 250;

const styles = {
  logo: {
    height: 50,
    width: 120,
  },
};

/* Navbar

  Top navigation menu contains:
  - Button to open the left side menu
  - Zippedi logo
  - Button on the right to open a small menu
*/
export default function Navbar(props) {
  const {
    open,
    handleDrawer,
    userInfo,
    detailedUserInfo,
    getNavigationOptions,
    getUserInfo,
    getWorkshiftInfo,
    workshiftInfo,
    workshiftResponse,
    isloadingPostWorkshift,
    getEvents,
    eventsList,
    updatingEventStatus,
    updateEventStatus,
    isLoadingUpdateEventStatus,
    checkFeaturePermission,
    featurePermissionResponse,
    postClickUpTask,
    newClickUpTaskResponse,
    isLoadingClickUpTask,
    getClickUpTeams,
    clickupTeamsList,
    setSnackAlert,
  } = props;

  const { t } = useTranslation();

  const [workshiftActive, setWorkshiftActive] = useState();
  const [actualShiftId, setActualShiftId] = useState();
  const [activeInShift, setActiveInShift] = useState();
  const [workshifEvents, setWorkshifEvents] = useState([]);
  const [userTier, setUserTier] = useState(null);

  const audioPlayer = useRef(null);

  const MONITOR_ASSIGNMENT_FEATURE = 'monitor_event_assignment';

  useEffect(() => {
    getClickUpTeams();
    getUserInfo(userInfo.email);
    checkFeaturePermission(MONITOR_ASSIGNMENT_FEATURE);
    getEvents();
    const timer = setInterval(() => {
      getEvents();
    }, 45000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (detailedUserInfo?.id) {
      handleNewNotification({ beforeEvents: workshifEvents, currentEvents: eventsList });
      getWorkshiftInfo(detailedUserInfo.id);
      setWorkshifEvents(eventsList);
    }
  }, [eventsList]);

  useEffect(() => {
    getNavigationOptions();
  }, [getNavigationOptions]);

  useEffect(() => {
    if (detailedUserInfo?.id) getWorkshiftInfo(detailedUserInfo.id);
    if (detailedUserInfo?.active_status)
      setActiveInShift(detailedUserInfo.active_status);
    if (detailedUserInfo?.tier_id !== undefined) setUserTier(detailedUserInfo.tier_id);
  }, [detailedUserInfo]);

  useEffect(() => {
    setWorkshiftActive(workshiftInfo.length > 0);
    if (workshiftInfo.length > 0) setActualShiftId(workshiftInfo[0].id);
  }, [workshiftInfo]);

  useEffect(() => {
    if (detailedUserInfo?.id) {
      getWorkshiftInfo(detailedUserInfo.id);
      getEvents();
    }
  }, [workshiftResponse]);

  useEffect(() => {
    if (activeInShift === false) {
      setWorkshifEvents([]);
    }
  }, [activeInShift]);

  useEffect(() => {
    getEvents();
  }, [updatingEventStatus]);

  const handleNewNotification = ({ beforeEvents, currentEvents }) => {
    const newEvents = currentEvents.filter((event) => {
      return !beforeEvents.some((beforeEvent) => beforeEvent.id === event.id);
    });

    if (newEvents.length > 0) {
      document.getElementById('audioPlayer')?.play();
      const messageList = newEvents.map((newEvent) => { return newEvent.title; });
      const newEventsCant = messageList.length;
      const message = `${newEventsCant} new event${newEventsCant > 1 ? 's' : ''}: ` + messageList.join(' and ');
      setSnackAlert({
        open: true,
        message,
        severity: 'info',
      });
    }
  }

  const handleFeaturePermission = () => {
    if (featurePermissionResponse[MONITOR_ASSIGNMENT_FEATURE]) return true;
    else return false;
  };

  const handleMonitorState = () => {
    let color, label;
    if (workshiftActive) {
      if (activeInShift) {
        label = t('overseer_app.monitoring.active', 'Active');
        color = 'info';
      } else {
        label = t('overseer_app.monitoring.away', 'Away');
        color = 'warning';
      }
    } else {
      label = t('overseer_app.monitoring.ineactive', 'Inactive');
      color = 'error';
    }
    return (
      <Tooltip
        title={`${t(
          'overseer_app.monitoring.tooltip_status',
          'Current shift status'
        )}: ${label}`}
      >
        <Chip
          label={
            isloadingPostWorkshift ? (
              <CircularProgress
                color="primary"
                size={24}
                sx={{ my: 2, mx: 1 }}
              />
            ) : (
              label
            )
          }
          color={color}
          disabled={isloadingPostWorkshift}
          style={{ borderRadius: '8px', fontWeight: 'bold' }}
        />
      </Tooltip>
    );
  };

  const navigate = useNavigate();

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        {/* Menu open button */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawer}
          edge="start"
          sx={{
            marginRight: 4,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <img
          style={styles.logo}
          src={logo_zippedi}
          alt="logo_zippedi"
          onClick={() => {
            navigate('');
          }}
        />

        {/* spacing */}
        <Grid sx={{ flexGrow: 1 }} />

        {handleFeaturePermission() ? (
          <>
            {handleMonitorState()}
            <Notification
              notificationList={workshifEvents}
              updateEventStatus={updateEventStatus}
              isLoadingUpdateEventStatus={isLoadingUpdateEventStatus}
              getEvents={getEvents}
              userTier={userTier}
              postClickUpTask={postClickUpTask}
              newClickUpTaskResponse={newClickUpTaskResponse}
              isLoadingClickUpTask={isLoadingClickUpTask}
              clickupTeamsList={clickupTeamsList}
            />
          </>
        ) : null}

        <MyMenu
          actualShiftId={actualShiftId}
          workshiftActive={workshiftActive}
          activeInShift={activeInShift}
          setActiveInShift={setActiveInShift}
          eventsList={workshifEvents}
          getEvents={getEvents}
          handleFeaturePermission={handleFeaturePermission}
        />
      </Toolbar>

      <audio id='audioPlayer' ref={audioPlayer} src={NotificationSound} />
    </AppBar>
  );
}
