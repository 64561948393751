import { createReducer } from './utils';

import {
  handleAvailable,
  handleRequest,
  handleUnavailable,
} from '../reducerHandlers';

import {
  PIPELINE_MONITORING_AVAILABLE,
  PIPELINE_MONITORING_REQUEST,
  PIPELINE_MONITORING_UNAVAILABLE,
  SNACK_SET,
} from './constants';

const GET_PROCESS_FLOW = 'GET_PROCESS_FLOW';
const GET_PROCESS_FLOW_SUCCESS = 'GET_PROCESS_FLOW_SUCCESS';
const GET_PROCESS_FLOW_FAILURE = 'GET_PROCESS_FLOW_FAILURE';

const GET_JOBS_COMPLETED = 'GET_JOBS_COMPLETED';
const GET_JOBS_COMPLETED_SUCCESS = 'GET_JOBS_COMPLETED_SUCCESS';
const GET_JOBS_COMPLETED_FAILURE = 'GET_JOBS_COMPLETED_FAILURE';

const GET_SIGNED_BLOB = 'GET_SIGNED_BLOB';
const GET_SIGNED_BLOB_SUCCESS = 'GET_SIGNED_BLOB_SUCCESS';
const GET_SIGNED_BLOB_FAILURE = 'GET_SIGNED_BLOB_FAILURE';

const GET_PROCESSES_DATA = 'GET_PROCESSES_DATA';
const GET_PROCESSES_DATA_SUCCESS = 'GET_PROCESSES_DATA_SUCCESS';
const GET_PROCESSES_DATA_FAILURE = 'GET_PROCESSES_DATA_FAILURE';

const GET_OBJECT_NAMES = 'GET_OBJECT_NAMES';
const GET_OBJECT_NAMES_SUCCESS = 'GET_OBJECT_NAMES_SUCCESS';
const GET_OBJECT_NAMES_FAILURE = 'GET_OBJECT_NAMES_FAILURE';

const GET_ALL_METADATA = 'GET_ALL_METADATA';
const GET_ALL_METADATA_SUCCESS = 'GET_ALL_METADATA_SUCCESS';
const GET_ALL_METADATA_FAILURE = 'GET_ALL_METADATA_FAILURE';

const GET_SESSION_PROGRESS = 'GET_SESSION_PROGRESS';
const GET_SESSION_PROGRESS_SUCCESS = 'GET_SESSION_PROGRESS_SUCCESS';
const GET_SESSION_PROGRESS_FAILURE = 'GET_SESSION_PROGRESS_FAILURE';

const GET_TASKS_PROGRESS = 'GET_TASKS_PROGRESS';
const GET_TASKS_PROGRESS_SUCCESS = 'GET_TASKS_PROGRESS_SUCCESS';
const GET_TASKS_PROGRESS_FAILURE = 'GET_TASKS_PROGRESS_FAILURE';

const GET_SESSION_DATA = 'GET_SESSION_DATA';
const GET_SESSION_DATA_SUCCESS = 'GET_SESSION_DATA_SUCCESS';
const GET_SESSION_DATA_FAILURE = 'GET_SESSION_DATA_FAILURE';

const GET_BATCHES_BY_AISLE = 'GET_BATCHES_BY_AISLE';
const GET_BATCHES_BY_AISLE_SUCCESS = 'GET_BATCHES_BY_AISLE_SUCCESS';
const GET_BATCHES_BY_AISLE_FAILURE = 'GET_BATCHES_BY_AISLE_FAILURE';

const defaultState = {
  loadingProcessFlow: false,
  processFlow: {},
  processFlowErrorMessage: null,

  loadingJobsCompleted: false,
  jobsCompleted: [],
  batchesReceived: [],
  totalAisles: {},
  navigationData: [],
  loadingNavigationData: false,
  jobsCompletedErrorMessage: null,

  loadingProcessesData: false,
  processesData: {},
  processesDataErrorMessage: null,

  objectNames: {},
  objectNamesErrorMessage: null,
  loadingObjectNames: false,

  loadingAllMetadata: false,
  allMetadataErrorMessage: null,

  storeData: {},

  sessionProgress: {},
  loadingSessionProgress: false,
  sessionProgressErrorMessage: null,

  tasksProgress: {},
  loadingTasksProgress: false,
  tasksProgressErrorMessage: null,

  batchesByAisle: {},
  loadingBatchesByAisle: false,
  batchesByAisleErrorMessage: null,

  signedblob: null,
  isLoadingSignedBlob: null,

  ppNodes: null,
  isPPNodesLoading: false,
};

export const reducer = createReducer(defaultState, {
  [GET_PROCESS_FLOW]: handleGetProcessFlow,
  [GET_PROCESS_FLOW_FAILURE]: handleProcessFlowFailure,
  [GET_PROCESS_FLOW_SUCCESS]: handleProcessFlowSuccess,

  [GET_SIGNED_BLOB]: handleGetSignedBlob,
  [GET_SIGNED_BLOB_SUCCESS]: handleSignedBlobSuccess,
  [GET_SIGNED_BLOB_FAILURE]: handleSignedBlobFailure,

  [GET_JOBS_COMPLETED]: handleGetJobsCompleted,
  [GET_JOBS_COMPLETED_SUCCESS]: handleJobsCompletedSuccess,
  [GET_JOBS_COMPLETED_FAILURE]: handleJobsCompletedFailure,

  [GET_PROCESSES_DATA]: handleProcessesData,
  [GET_PROCESSES_DATA_FAILURE]: handleProcessesDataFailure,
  [GET_PROCESSES_DATA_SUCCESS]: handleProcessesDataSuccess,

  [GET_OBJECT_NAMES]: handleObjectNames,
  [GET_OBJECT_NAMES_FAILURE]: handleObjectNamesFailure,
  [GET_OBJECT_NAMES_SUCCESS]: handleObjectNamesSuccess,

  [GET_ALL_METADATA]: handleGetAllMetadata,
  [GET_ALL_METADATA_SUCCESS]: handleGetAllMetadataSuccess,
  [GET_ALL_METADATA_FAILURE]: handleGetAllMetadataFailure,

  [GET_SESSION_PROGRESS]: handleGetSessionProgress,
  [GET_SESSION_PROGRESS_SUCCESS]: handleGetSessionProgressSuccess,
  [GET_SESSION_PROGRESS_FAILURE]: handleGetSessionProgressFailure,

  [GET_TASKS_PROGRESS]: handleGetTasksProgress,
  [GET_TASKS_PROGRESS_SUCCESS]: handleGetTasksProgressSuccess,
  [GET_TASKS_PROGRESS_FAILURE]: handleGetTasksProgressFailure,

  [GET_SESSION_DATA]: handleGetSessionData,
  [GET_SESSION_DATA_SUCCESS]: handleGetSessionDataSuccess,
  [GET_SESSION_DATA_FAILURE]: handleGetSessionDataFailure,

  [GET_BATCHES_BY_AISLE]: handleGetBatchesByAisle,
  [GET_BATCHES_BY_AISLE_SUCCESS]: handleGetBatchesByAisleSuccess,
  [GET_BATCHES_BY_AISLE_FAILURE]: handleGetBatchesByAisleFailure,

  [PIPELINE_MONITORING_REQUEST]: handleRequest,
  [PIPELINE_MONITORING_AVAILABLE]: handleAvailable,
  [PIPELINE_MONITORING_UNAVAILABLE]: handleUnavailable,
});

//////////////////// GET ALL METADATA //////////////////////////
function handleGetAllMetadataSuccess(
  state,
  { payload: { processesData, objectNames, storeData } }
) {
  return {
    ...state,
    loadingAllMetadata: false,
    processesData,
    objectNames,
    storeData,
  };
}

function handleGetAllMetadataFailure(state, { payload: { error } }) {
  console.log(error);
  return {
    ...state,
    loadingAllMetadata: false,
    allMetadataErrorMessage: error,
  };
}

function handleGetAllMetadata(state) {
  return {
    ...state,
    loadingAllMetadata: true,
    allMetadataErrorMessage: null,
  };
}

export function getAllMetadata({ chain_code, store }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_ALL_METADATA });
    try {
      const objectNames = await dataSource.getObjectNames();
      const storeData = await dataSource.getStoreData();
      const processesData = await dataSource.getProcessesData(
        chain_code,
        store
      );
      dispatch({
        type: GET_ALL_METADATA_SUCCESS,
        payload: { objectNames, storeData, processesData },
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_METADATA_FAILURE,
        payload: { error },
      });
    }
  };
}
//////////////////// GET ALL METADATA //////////////////////////

//////////////////// GET PROCESS FLOW //////////////////////////
function handleGetProcessFlow(state) {
  return {
    ...state,
    loadingProcessFlow: true,
    processFlowErrorMessage: null,
  };
}

function handleProcessFlowSuccess(state, { payload: { processFlow } }) {
  return {
    ...state,
    loadingProcessFlow: false,
    processFlow,
    processFlowErrorMessage: null,
  };
}

function handleProcessFlowFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingProcessFlow: false,
    processFlowErrorMessage: error,
  };
}

export function getProcessFlow() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_PROCESS_FLOW });
    try {
      const processFlow = await dataSource.getProcessFlow();
      dispatch({
        type: GET_PROCESS_FLOW_SUCCESS,
        payload: { processFlow },
      });
    } catch (error) {
      dispatch({
        type: GET_PROCESS_FLOW_FAILURE,
        payload: { error },
      });
    }
  };
}
//////////////////// GET PROCESS FLOW //////////////////////////

//////////////////// GET JOBS COMPLETED //////////////////////////
function handleGetJobsCompleted(state, { payload: { loading } }) {
  return {
    ...state,
    loadingJobsCompleted: loading,
    loadingNavigationData: true,
    jobsCompletedErrorMessage: null,
  };
}

function handleJobsCompletedSuccess(
  state,
  { payload: { jobsCompleted, totalAisles, batchesReceived, navigationData } }
) {
  return {
    ...state,
    loadingJobsCompleted: false,
    loadingNavigationData: false,
    jobsCompleted,
    totalAisles,
    batchesReceived,
    navigationData,
    jobsCompletedErrorMessage: null,
  };
}

function handleJobsCompletedFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingJobsCompleted: false,
    loadingNavigationData: false,
    jobsCompletedErrorMessage: error,
  };
}

export function getJobsCompleted({ date, store, robot, loading = true }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({
      type: GET_JOBS_COMPLETED,
      payload: { loading },
    });
    try {
      const jobsCompleted = await dataSource.getJobsCompleted({
        date,
        store,
        robot,
      });
      const totalAisles = await dataSource.getTotalAisles({
        date,
        store,
        robot,
      });
      const batchesReceived = await dataSource.getBatchesReceived({
        date,
        store,
        robot,
      });
      const navigationData = await dataSource.getNavigationData({
        date,
        store,
        robot,
      });

      dispatch({
        type: GET_JOBS_COMPLETED_SUCCESS,
        payload: {
          jobsCompleted,
          totalAisles,
          batchesReceived,
          navigationData,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_JOBS_COMPLETED_FAILURE,
        payload: { error },
      });
    }
  };
}
//////////////////// GET PROCESS FLOW //////////////////////////

//////////////////// GET PROCESSES DATA //////////////////////////
function handleProcessesData(state) {
  return {
    ...state,
    loadingProcessesData: true,
    processesDataErrorMessage: null,
  };
}

function handleProcessesDataSuccess(state, { payload: { processesData } }) {
  return {
    ...state,
    processesData,
    loadingProcessesData: false,
    processesDataErrorMessage: null,
  };
}

function handleProcessesDataFailure(state, { payload: { error } }) {
  console.log(error);
  return {
    ...state,
    loadingProcessesData: false,
    processesDataErrorMessage: error,
  };
}

//////////////////// GET PROCESSES DATA //////////////////////////

//////////////////// GET OBJECT NAMES //////////////////////////
function handleObjectNames(state) {
  return {
    ...state,
    loadingObjectNames: true,
    objectNamesErrorMessage: null,
  };
}

function handleObjectNamesSuccess(state, { payload: { objectNames } }) {
  return {
    ...state,
    objectNames,
    loadingObjectNames: false,
    objectNamesErrorMessage: null,
  };
}

function handleObjectNamesFailure(state, { payload: { error } }) {
  console.log(error);
  return {
    ...state,
    loadingObjectNames: false,
    objectNamesErrorMessage: error,
  };
}

export function getObjectNames() {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_OBJECT_NAMES });
    try {
      const objectNames = await dataSource.getObjectNames();
      dispatch({
        type: GET_OBJECT_NAMES_SUCCESS,
        payload: { objectNames },
      });
    } catch (error) {
      dispatch({
        type: GET_OBJECT_NAMES_FAILURE,
        payload: { error },
      });
    }
  };
}
//////////////////// GET OBJECT NAMES //////////////////////////

//////////////////// GET SESSION PROGRESS //////////////////////////
function handleGetSessionProgressSuccess(
  state,
  { payload: { sessionProgress } }
) {
  return {
    ...state,
    sessionProgress: sessionProgress,
    loadingSessionProgress: false,
    sessionProgressErrorMessage: null,
  };
}

function handleGetSessionProgressFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingSessionProgress: false,
    sessionProgressErrorMessage: error,
  };
}

function handleGetSessionProgress(state) {
  return {
    ...state,
    loadingSessionProgress: true,
    sessionProgressErrorMessage: null,
  };
}

export function getSessionProgress({ session, process_id }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SESSION_PROGRESS });
    try {
      const sessionProgress = await dataSource.getSessionProgress(
        session,
        process_id
      );

      dispatch({
        type: GET_SESSION_PROGRESS_SUCCESS,
        payload: { sessionProgress },
      });
      return sessionProgress;
    } catch (error) {
      dispatch({
        type: GET_SESSION_PROGRESS_FAILURE,
        payload: { error },
      });
    }
  };
}

export function getSessionProgressTable({ date, store, robot, chain_code }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const keyState = 'sessionProgressTable';
    const loadingName = 'isSessionProgressTableLoading';
    dispatch({
      type: PIPELINE_MONITORING_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getSessionProgressTable({
        date,
        store,
        robot,
        chain_code,
      });
      dispatch({
        type: PIPELINE_MONITORING_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PIPELINE_MONITORING_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the session progress table.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

//////////////////// GET SESSION PROGRESS //////////////////////////

//////////////////// GET TASKS PROGRESS //////////////////////////
function handleGetTasksProgressSuccess(state, { payload: { tasksProgress } }) {
  return {
    ...state,
    tasksProgress: tasksProgress,
    loadingTasksProgress: false,
    tasksProgressErrorMessage: null,
  };
}

function handleGetTasksProgressFailure(state, { payload: { error } }) {
  return {
    ...state,
    tasksProgress: null,
    loadingTasksProgress: false,
    tasksProgressErrorMessage: error,
  };
}

function handleGetTasksProgress(state) {
  return {
    ...state,
    loadingTasksProgress: true,
    tasksProgressErrorMessage: null,
  };
}

export function getTasksProgress({
  store,
  session_id,
  queue_name,
  project_id,
  service_name,
}) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_TASKS_PROGRESS });
    try {
      const tasksProgress = await dataSource.getTasksProgress({
        store,
        session_id,
        queue_name,
        project_id,
        service_name,
      });

      dispatch({
        type: GET_TASKS_PROGRESS_SUCCESS,
        payload: { tasksProgress },
      });
    } catch (error) {
      dispatch({
        type: GET_TASKS_PROGRESS_FAILURE,
        payload: { error },
      });
    }
  };
}
//////////////////// GET TASKS PROGRESS //////////////////////////

function handleGetSessionDataSuccess(state, { payload: { sessionData } }) {
  return {
    ...state,
    sessionData: sessionData,
    loadingSessionData: false,
    sessionDataErrorMessage: null,
  };
}

function handleGetSessionDataFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingSessionData: false,
    sessionDataErrorMessage: error,
  };
}

function handleGetSessionData(state) {
  return {
    ...state,
    loadingSessionData: true,
    sessionDataErrorMessage: null,
  };
}

export function getSessionData({ session }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_SESSION_DATA });
    try {
      const sessionData = await dataSource.getSessionData({
        session,
      });

      dispatch({
        type: GET_SESSION_DATA_SUCCESS,
        payload: { sessionData },
      });
    } catch (error) {
      dispatch({
        type: GET_SESSION_DATA_FAILURE,
        payload: { error },
      });
    }
  };
}

////////////////////////////////////////////////////////////////////////
function handleGetBatchesByAisleSuccess(
  state,
  { payload: { batchesByAisle } }
) {
  return {
    ...state,
    batchesByAisle: batchesByAisle,
    loadingBatchesByAisles: false,
    batchesByAisleErrorMessage: null,
  };
}

function handleGetBatchesByAisleFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingBatchesByAisles: false,
    batchesByAisleErrorMessage: error,
  };
}

function handleGetBatchesByAisle(state) {
  return {
    ...state,
    loadingBatchesByAisles: true,
    batchesByAisleErrorMessage: null,
  };
}

export function getBatchesByAisle({ date, store, robot }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({ type: GET_BATCHES_BY_AISLE });
    try {
      const batchesByAisle = await dataSource.getBatchesReceivedByAisle({
        date,
        store,
        robot,
      });

      dispatch({
        type: GET_BATCHES_BY_AISLE_SUCCESS,
        payload: { batchesByAisle },
      });
    } catch (error) {
      dispatch({
        type: GET_BATCHES_BY_AISLE_FAILURE,
        payload: { error },
      });
    }
  };
}

//////////////////// GET TASKS PROGRESS //////////////////////////
function handleGetSignedBlob(state) {
  return {
    ...state,
    loadingSignedBlob: true,
    SignedBlobErrorMessage: null,
  };
}

function handleSignedBlobSuccess(state, { payload: { signedBlob } }) {
  return {
    ...state,
    loadingSignedBlob: false,
    signedBlob,
    SignedBlobErrorMessage: null,
  };
}

function handleSignedBlobFailure(state, { payload: { error } }) {
  return {
    ...state,
    loadingSignedBlob: false,
    SignedBlobErrorMessage: error,
  };
}

export function signBlob({ store, blobPath }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    dispatch({
      type: GET_SIGNED_BLOB,
    });
    try {
      const signedBlob = await dataSource.signBlob({ store, blobPath });
      dispatch({
        type: GET_SIGNED_BLOB_SUCCESS,
        payload: {
          signedBlob: signedBlob.signed_blob,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SIGNED_BLOB_FAILURE,
        payload: { error },
      });
    }
  };
}
//////////////////// GET TASKS PROGRESS //////////////////////////


export function getPPNodes(payload = { session: '', hard_finished_sessions: [] }) {
  return async (dispatch, getState, { services: { dataSource } }) => {
    const keyState = 'ppNodes';
    const loadingName = 'isPPNodesLoading';
    dispatch({
      type: PIPELINE_MONITORING_REQUEST,
      payload: { loadingName: loadingName },
    });
    try {
      const response = await dataSource.getPPNodes(payload);
      dispatch({
        type: PIPELINE_MONITORING_AVAILABLE,
        payload: {
          keyState: keyState,
          data: response,
          loadingName: loadingName,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: PIPELINE_MONITORING_UNAVAILABLE,
        payload: {
          keyState: keyState,
          loadingName: loadingName,
          defaultState: [],
        },
      });
      const snack = {
        open: true,
        message: 'There was an error getting the pipeline progress nodes.',
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  }
}
