import { Badge, Box, ButtonBase, Grid, IconButton, Skeleton, Tooltip, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconComponent } from "@zippeditoolsjs/zippedi-icons";
import { isEmptyOrUndefined } from "core/utils/validators";
import dayjs from "dayjs";
import { checkViewOnlyPermision } from "../pipelineMonitoring/Utils";
import MenuMultiSessionTable from "./MenuMultiSessionTable";
import MenuSessionTable from "./MenuSessionTable";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SchedulerViewContext } from '../../context/SchedulerView';
import CancelSessionForm from '../../redux/containers/fleetManagement/CancelSessionForm';
import SessionForm from '../../redux/containers/fleetManagement/SessionForm';
import NotFound from '../notFound/NotFound';
import Icon from '../utils/Icon';
import SvgIcon from '../utils/SVGIcon';
import ScheduleTraceability from './ScheduleTraceability';
import { DIALOG_TYPES, handleIsCanceled, NO_EDIT_SESSION_HOURS_BEFORE } from './Utils';

const blueCircle = {
  display: "inline-block",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "#1E76E8",
  color: "white",
  textAlign: "center",
  lineHeight: "30px",
};

const columns = [
  { id: "store", label: "Store", minWidth: 170 },
  { id: "floor", label: "Floor", minWidth: 0 },
  { id: "store_name", label: "Name", minWidth: 170 },
];

export default function SchedulerTable(props) {
  const {
    currentDay,
    currentWeek,
    currentMonth,
    selectedDate,
    isLoadingSessionsPost,
    newSessionsResponse,
    selectedRobots,
    navOptions,
    viewId,
    dataRobots,
    updateSessionResponse,
    deleteSessionsResponse,
    isLoadingDeleteSessions,
    isLocalTime,
    isLoadingRobotSessions,
    handlePreviousWeek,
    handleNextWeek,
    handleNextMonth,
    handlePreviousMonth,
    sessionsState,
    isLoadingLayout,
    reload,
    canViewCancelledSessions,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  //table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //to access to the data of the table
  const [dateCellSelected, setDateCellSelected] = useState({
    date: "",
    day: "",
  });
  const [cellSelected, setCellSelected] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [actualRowClicked, setActualRowClicked] = useState(null);
  const [actualSessionClicked, setActualSessionClicked] = useState(null);
  //To open menus from table
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSession, setAnchorElSession] = useState(null);
  //dialogs
  const [openDeleteSession, setOpenDeleteSession] = useState(false);
  const [openSessionForm, setOpenSessionForm] = useState(false);
  const [openTrace, setOpenTrace] = useState(false);

  //data
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [schedulerTimeMode, setSchedulerTimeMode] = useState(isLocalTime ? 'user_scheduler' : 'local_scheduler');
  const [sessionsPositions, setSessionsPositions] = useState([]);

  const {
    dialogType,
    setDialogType,
  } = useContext(SchedulerViewContext);

  //To open menus from table
  const openSession = Boolean(anchorElSession);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (newSessionsResponse) {
      if (
        newSessionsResponse.message &&
        newSessionsResponse.message === "Success"
      ) {
      }
    }
  }, [newSessionsResponse]);

  useEffect(() => {
    if (updateSessionResponse) {
      if (
        updateSessionResponse.message &&
        updateSessionResponse.message === "Success"
      ) {
      }
    }
  }, [updateSessionResponse]);

  useEffect(() => {
    if (deleteSessionsResponse) {
      if (
        deleteSessionsResponse.message &&
        deleteSessionsResponse.message === "Success"
      ) {
        handleCloseDeleteSession();
      }
    }
  }, [deleteSessionsResponse]);

  useEffect(() => {
    setSchedulerTimeMode(isLocalTime ? 'user_scheduler' : 'local_scheduler');
  }, [isLocalTime]);

  useEffect(() => {
    if (dataRobots.length > 0 && schedulerTimeMode && currentWeek !== undefined) {
      const newSessionPosition = handleGetSessionPosition();
      setSessionsPositions(newSessionPosition);
    }
  }, [dataRobots, schedulerTimeMode, currentWeek]);

  const handleClickOpenNewSession = (type) => {
    setOpenSessionForm(true);
    setDialogType(type);
  };

  const handleCloseNewSession = ({ canReload }) => {
    if (canReload) {
      reload();
    }
  };

  const handleClickOpenDeleteSession = (type) => {
    setOpenDeleteSession(true);
    setDialogType(type);
  };

  const handleClickOpenTrace = (type) => {
    setOpenTrace(true);
    setDialogType(type);
  }

  const handleCloseDeleteSession = () => {
    setOpenDeleteSession(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickCell = (event, index, column, row = null) => {
    setCellSelected(index);
    const date = dayjs(column.date).format("YYYY-MM-DD");
    setDateCellSelected({ date: date, day: column.day_of_week, index: index });
  };

  const handleClickSessionsByDay = (event, dayNumber, dayWeek) => {
    if (!checkViewOnlyPermision(navOptions, viewId)) setAnchorEl(event.currentTarget);

    let sessionsInfo = [];
    dataRobots.forEach((element) => {
      const sessions = element[schedulerTimeMode][currentWeek][dayNumber].sessions;
      sessions.forEach((session) => {
        if (session?.session_id) {
          sessionsInfo.push({ ...session, isEditable: handleSessionIsEditable(dayWeek, session.user_start_time, session.local_start_date, session.user_start_date, session.status) });
        }
      });
    });

    setSelectedSessions(sessionsInfo);
  };

  const handleClickSession = (event, index, session, row = null, isEditable = true) => {
    if (!checkViewOnlyPermision(navOptions, viewId)) {
      setAnchorElSession(event.currentTarget);
      setSessionSelected({ ...session, isEditable });
      setSelectedSessions([{ ...session, isEditable }]);
      setActualRowClicked(row);
      setActualSessionClicked(session);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSession(null);
  };

  const badgeTooltipContent = (session) => {
    const { oh_mode } = session;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>{t('overseer_app.general.Details', 'Details')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2'>{`${t('overseer_app.scheduler.OH_Mode', 'OH Mode')}: ${oh_mode}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleSessionHoursDifference = (calendarDate, userStartTime, localStartDate, userStartDate) => {
    if (userStartTime) {
      const dayDiff = dayjs(userStartDate).diff(dayjs(localStartDate), 'day');
      const userDate = dayjs(calendarDate).add(dayDiff, 'day').format('YYYY-MM-DD');
      const selectedDateTime = dayjs(`${userDate} ${userStartTime}`, 'YYYY-MM-DD hh:mm:ss A');
      const currentTime = dayjs();
      const differenceInMinutes = selectedDateTime.diff(currentTime, 'minute');
      return differenceInMinutes / 60;
    }
  }

  const handleIsBeforeToday = (targetDay) => {
    const calendarDate = dayjs(targetDay);
    const isCalendarDateBeforeSelectedDate = calendarDate.isBefore(dayjs(), 'day');
    return isCalendarDateBeforeSelectedDate;
  }

  const handleSessionIsEditable = (dayWeek, userStartTime = null, localStartDate = null, userStartDate = null, sessionStatus = null) => {
    if (userStartTime) {
      return !isLocalTime && handleSessionHoursDifference(dayWeek.date, userStartTime, localStartDate, userStartDate) > NO_EDIT_SESSION_HOURS_BEFORE && !handleIsCanceled(sessionStatus);
    } else {
      const isCalendarDateBeforeSelectedDate = handleIsBeforeToday(dayWeek.date);
      return !isLocalTime && !isCalendarDateBeforeSelectedDate
    }
  }

  const isCriticalTimeRange = (calendarDate, userStartTime, localStartDate, userStartDate) => {
    const timeDiff = handleSessionHoursDifference(calendarDate, userStartTime, localStartDate, userStartDate);
    return 0 <= timeDiff && timeDiff <= NO_EDIT_SESSION_HOURS_BEFORE;
  }

  const handleIsOngoingSession = (session_id, date, local_start_time) => {
    const localID = `${session_id}-${date} ${local_start_time}`;
    return sessionsState?.[localID]?.nav_status === 'navigating';
  }

  const handleGetSessionPosition = () => {
    const dayWeeks = {}
    dataRobots?.forEach((robot) => {
      robot[schedulerTimeMode][currentWeek].forEach((day, index) => {
        const dayWeekDate = day.date ?? index;
        if (dayWeeks[dayWeekDate]) {
          dayWeeks[dayWeekDate].sessions = [...Array(Math.max(day?.sessions?.length ?? 0, dayWeeks[dayWeekDate]?.sessions?.length ?? 0)).keys()];
        } else {
          const dayWeekDate = day.date ?? index;
          dayWeeks[dayWeekDate] = {
            index,
            date: dayWeekDate,
            day_of_month: day.day_of_month,
            day_of_week: day.day_of_week,
            sessions: day.sessions,
          };
        }
      });
    });
    const sessionPositions = Object.values(dayWeeks).sort((a, b) => a.index - b.index);

    return sessionPositions;
  }

  const handleStartTime = (session) => {
    const targetTime = isLocalTime ? 'user_start_time' : 'local_start_time';
    return session[targetTime] ? dayjs(session[targetTime], 'HH:mm A').format('HH:mm A') : '-';
  }

  const handleSelectedCancelSessions = (selectedSessions) => {
    return selectedSessions.filter(session => handleSessionIsEditable(dateCellSelected, session.user_start_time, session.local_start_date, session.user_start_date, session.status));
  }

  return (
    <>{
      (!selectedRobots?.length || !dataRobots?.length) ? (
        <NotFound
          sx={{ pt: 3 }}
          title={'Make a selection to see content'}
          content={'Select Chain and Robot to see scheduled sessions'}
        />
      ) : isLoadingRobotSessions ?
        <Skeleton variant="rectangular" width="100%" height="11em" />
        :
        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: 600, maxWidth: document.getElementsByTagName('main')[0].clientWidth }}>
            <Table sx={{ minWidth: '95em' }}>
              {/* Table headers*/}
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    {
                      "Current week: " + (currentWeek + 1) // The week is 0 based
                    }
                  </TableCell>
                  {/* Days of the week */}
                  {dataRobots[0][schedulerTimeMode][currentWeek].map((day, index) => {
                    // If the day is the current day, it will be highlighted in blue
                    const isToday = !isEmptyOrUndefined(day, 'dict') && day.day_of_month === currentDay && currentMonth === selectedDate.$M + 1; // The month is 0 based
                    const sx = isToday ? { ...blueCircle } : {};
                    return (
                      <TableCell key={`header-cell-${day?.date ?? index}`} align={"center"} colSpan={2}>
                        <Box sx={sx}>
                          {Object.keys(day).length !== 0 ? day.day_of_month : " "}
                        </Box>
                      </TableCell>
                    )
                  })}
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Tooltip title={'Reload Table'}>
                          <IconButton onClick={reload} size='small'>
                            <IconComponent iconName='refresh' style={{ fontSize: '28px' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Box
                        display={'flex'}
                        alignItems={'center'}
                      >
                        {/* Icons buttons and Date today */}
                        <Tooltip title={currentWeek === 0 ? 'Previous month' : 'Previous week'}>
                          <IconButton
                            onClick={() => { currentWeek === 0 ? handlePreviousMonth() : handlePreviousWeek() }}
                            size="small"
                          >
                            <ArrowBackIosIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={(currentWeek === (dataRobots[0] ? dataRobots[0][schedulerTimeMode].length - 1 : 0)) || (selectedDate ? false : true) ? 'Next month' : 'Next week'}>
                          <IconButton
                            onClick={() => { (currentWeek === (dataRobots[0] ? dataRobots[0][schedulerTimeMode].length - 1 : 0)) || (selectedDate ? false : true) ? handleNextMonth() : handleNextWeek() }}
                            size="small"
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                  {/* Names of the days of the week */}
                  {dataRobots[0][schedulerTimeMode][currentWeek].map((weekDay, index) => (
                    <TableCell
                      key={`Week-cell-${weekDay?.day_of_week ?? index}`}
                      className="ordinal-session-div"
                      align={"center"}
                      colSpan={2}
                      style={{ color: "#FFFFFF", background: "#1a242d", top: 0, position: "relative" }}
                      onClick={(event) => handleClickCell(event, index, weekDay)}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {Object.keys(weekDay).length !== 0
                          ? weekDay.day_of_week
                          : " "}
                        {!handleIsBeforeToday(weekDay.date) &&
                          <Box className="delete-box">
                            <IconButton
                              onClick={(event) =>
                                handleClickSessionsByDay(
                                  event,
                                  index,
                                  weekDay,
                                )
                              }
                              size="small"
                            >
                              <Icon color="secondary" iconName='trash-outline' fontSize="18px" sx={{ p: '5px', mt: '5px' }} />
                            </IconButton>
                          </Box>
                        }
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {/* 2nd headers (store, floor, store name) */}
                  {columns.map((column, index) => (
                    <TableCell
                      key={`header-row-${column.id}`}
                      align={"center"}
                      style={{
                        top: 57,
                        background: "#1a242d",
                        color: "#FFF",
                        width: "1rem",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {/* Position of the sessions (1st, 2nd, 3rd, etc.) */}
                  {sessionsPositions.map((dayWeek, index) =>
                    Object.keys(dayWeek).length !== 0 ? (
                      <TableCell
                        key={`root-session-cell-${dayWeek?.day_of_month}-${dayWeek?.day_of_week}-${index}`}
                        align={"justify"}
                        m={"auto"}
                        style={{
                          top: 57,
                          textAlign: "center",
                          border: "solid 1px",
                          borderColor: "#1a242d",
                          paddingBlock: 0,
                        }}
                        colSpan={2}
                      >
                      </TableCell>
                    ) : (
                      <TableCell
                        key={index}
                        colSpan={2}
                        style={{
                          top: 57,
                          textAlign: "center",
                          border: "solid 1px",
                          borderColor: "#1a242d",
                          paddingBlock: 0,
                        }}
                      >
                        {" - "}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              {/* Table body */}
              <TableBody>
                {dataRobots
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={`Body-row-${row.robot_uuid}`}>
                        {/* Session info */}
                        <TableCell
                          align="center"
                          style={{
                            top: 57,
                            background: "#1a242d",
                            color: "#FFF",
                            width: "1rem",
                          }}
                        >
                          {row.store}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            top: 57,
                            background: "#1a242d",
                            color: "#FFF",
                            width: "1rem",
                          }}
                        >
                          {row.floor ?? '-'}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            top: 57,
                            background: "#1a242d",
                            color: "#FFF",
                            width: "1rem",
                          }}
                        >
                          {row.store_name}
                        </TableCell>
                        {/* Sessions */}
                        {row[schedulerTimeMode][currentWeek].map((dayWeek, index) =>
                          <Tooltip
                            key={`session-cell-${index}`}
                            title={dayWeek.session}>
                            <TableCell
                              align={"center"}
                              style={{
                                border: "solid 1px",
                                borderColor: "#1a242d",
                              }}
                              onClick={(event) =>
                                handleClickCell(event, index, dayWeek, row)
                              }
                              colSpan={2}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#D9D9D9",
                                },
                              }}
                            >
                              <Grid container columnSpacing={1} justifyContent='center'>
                                {dayWeek?.sessions?.length ?
                                  <>
                                    {dayWeek.sessions?.map((session, index_session) => {
                                      const isEditable = handleSessionIsEditable(dayWeek, session.user_start_time, session.local_start_date, session.user_start_date, session.status);
                                      return (
                                        <Grid item key={`session-Grid-${index_session}`}>
                                          {isEmptyOrUndefined(session, 'dict') || (handleIsCanceled(session.status) && !canViewCancelledSessions) ?
                                            isEditable ?
                                              <IconButton onClick={(event) => handleClickSession(event, index_session, session, row)}
                                                sx={{ backgroundColor: '#1E76E833' }}>
                                                <IconComponent
                                                  iconName='add'
                                                  color={isEditable ? 'secondary' : null}
                                                  style={{ fontSize: '18px', color: '#1E76E8' }}
                                                />
                                              </IconButton>
                                              :
                                              '-'
                                            :
                                            <Badge
                                              badgeContent={
                                                <Tooltip title={badgeTooltipContent(session)}>
                                                  <ButtonBase style={{ color: theme.palette.grey['500'], cursor: 'default' }}>
                                                    <IconComponent
                                                      iconName='information-circle'
                                                      style={{ fontSize: '18px' }}
                                                    />
                                                  </ButtonBase>
                                                </Tooltip>
                                              }
                                              invisible={isEmptyOrUndefined(session.oh_mode, 'falsy')}
                                              sx={{ alignItems: 'center', color: isEditable ? theme.palette.info.main : '#777' }}
                                            >
                                              <Box sx={{ mr: 1, ml: 2, borderRight: '1px solid', p: 1 }} >
                                                <Tooltip title={session.type}>
                                                  <Typography >
                                                    {
                                                      session.type === 'Exception' ?
                                                        <IconComponent iconName='git-branch' style={{ fontSize: '20px' }} />
                                                        : session.type === 'Periodic' ?
                                                          <IconComponent iconName='infinite' style={{ fontSize: '20px' }} />
                                                          : session.type === 'Unique' ?
                                                            <IconComponent iconName='git-commit' style={{ fontSize: '20px' }} />
                                                            : null
                                                    }
                                                  </Typography>
                                                </Tooltip>
                                                <Tooltip title={session.robot_ack ?
                                                  <Typography sx={{ display: 'flex', fontSize: 'small', alignContent: 'center', alignItems: 'center' }}>
                                                    <SvgIcon name="overseer-robot-validation" style={{ fontSize: "30px", position: 'relative', left: '-3px' }} />
                                                    Confirmed by robot
                                                  </Typography> :
                                                  'Waiting for robot confirmation'}>
                                                  <Typography >
                                                    {session.robot_ack ?
                                                      <IconComponent iconName='checkmark-circle-outline' style={{ fontSize: '20px' }} />
                                                      :
                                                      <IconComponent iconName='timer-outline' color="gray" style={{ fontSize: '20px' }} />
                                                    }
                                                  </Typography>
                                                </Tooltip>
                                              </Box>
                                              <Tooltip title={handleIsOngoingSession(session.session_id, dayWeek.date, session.local_start_time) ? 'On going' : isCriticalTimeRange(dayWeek.date, session.user_start_time, session.local_start_date, session.user_start_date) ? `You cannot edit a session that is less than ${NO_EDIT_SESSION_HOURS_BEFORE} hours away from start` : handleIsCanceled(session.status) ? 'Canceled' : ''}>
                                                <ButtonBase
                                                  onClick={(event) => handleClickSession(event, index_session, session, row, isEditable)}
                                                >
                                                  <Typography
                                                    className={handleIsOngoingSession(session.session_id, dayWeek.date, session.local_start_time) ? 'loading-effect' : ''}
                                                    sx={{ textDecoration: handleIsCanceled(session.status) ? 'line-through' : 'none' }}
                                                    style={{ '--loading-color-light': '#1E76E8' }}
                                                  >{handleStartTime(session)}
                                                  </Typography>
                                                </ButtonBase>
                                              </Tooltip>
                                            </Badge>
                                          }
                                        </Grid>
                                      )
                                    })}
                                    {
                                      dayWeek?.day_of_month && handleSessionIsEditable(dayWeek) ?
                                        <IconButton
                                          onClick={(event) => handleClickSession(event, 0, null, row)}
                                          sx={{ backgroundColor: '#1E76E833', height: 'fit-content', alignSelf: 'center', ml: 1 }}
                                        >
                                          <IconComponent
                                            iconName='add'
                                            style={{ fontSize: '10px', color: '#1E76E8' }}
                                          />
                                        </IconButton>
                                        : null
                                    }
                                  </>
                                  : dayWeek?.day_of_month && handleSessionIsEditable(dayWeek)
                                    ? <IconButton
                                      onClick={(event) => handleClickSession(event, 0, null, row)}
                                      sx={{ backgroundColor: '#1E76E833' }}
                                    >
                                      <IconComponent
                                        iconName='add'
                                        style={{ fontSize: '18px', color: '#1E76E8' }}
                                      />
                                    </IconButton>
                                    : '-'
                                }
                              </Grid>
                            </TableCell>
                          </Tooltip>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataRobots.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* Open if click all sessions */}
          <MenuMultiSessionTable
            anchorEl={anchorEl}
            open={open}
            dateCellSelected={dateCellSelected}
            selectedDate={selectedDate}
            selectedSessions={selectedSessions}
            handleClickOpenNewSession={handleClickOpenNewSession}
            handleClose={handleClose}
            handleClickOpenDeleteSession={handleClickOpenDeleteSession}
          />
          {/* Open if click only 1 session*/}
          <MenuSessionTable
            anchorEl={anchorElSession}
            open={openSession}
            dateCellSelected={dateCellSelected}
            selectedDate={selectedDate}
            sessionSelected={sessionSelected}
            handleClose={handleClose}
            handleClickOpenTrace={handleClickOpenTrace}
            handleClickOpenNewSession={handleClickOpenNewSession}
            handleClickOpenDeleteSession={handleClickOpenDeleteSession}
          />
          <CancelSessionForm
            openModal={openDeleteSession}
            onClose={handleCloseNewSession}
            inputSelectedRobots={selectedRobots}
            isLoadingLayout={isLoadingLayout}
            actualRowClicked={actualRowClicked}
            dateCellSelected={dateCellSelected}
            setOpenModal={setOpenDeleteSession}
            isDisabled={sessionSelected !== null ? !sessionSelected.isEditable : false}
            selectedSessions={handleSelectedCancelSessions(selectedSessions)}
          />
          <SessionForm
            openModal={openSessionForm}
            selectedRobots={selectedRobots}
            isLoadingLayout={isLoadingLayout}
            setOpenModal={setOpenSessionForm}
            dateCellSelected={dateCellSelected}
            actualRowClicked={actualRowClicked}
            isDisabled={!sessionSelected?.isEditable}
            sessionSelected={dialogType === DIALOG_TYPES.ADD ? {} : sessionSelected}
            onClose={handleCloseNewSession}
          />
          <ScheduleTraceability
            openModal={openTrace}
            setOpenModal={setOpenTrace}
            sessionSelected={sessionSelected}
          />
        </Paper >
    }
    </>
  );
}
