// Reducers for most slices. 
// If you need something custom, add it to the corresponding file in /slices

export function handleRequest(state, { payload: { loadingName, aditionalStates = {} } }) {
  return {
    ...state,
    [loadingName]: true,
    ...aditionalStates
  };
}

export function handleAvailable(state, { payload: { keyState, data, loadingName, aditionalStates = {} } }) {
  return {
    ...state,
    [keyState]: data,
    [loadingName]: false,
    ...aditionalStates,
  };
}

export function handleUnavailable(state, { payload: { keyState, loadingName, defaultState = [], aditionalStates = {} } }) {
  return {
    ...state,
    [keyState]: defaultState,
    [loadingName]: false,
    ...aditionalStates,
  };
}

export function handleAvailableAddObject(state, { payload: { keyState, data, loadingName } }) {
  return {
    ...state,
    [keyState]: { ...state[keyState], ...data },
    [loadingName]: false,
  };
}

export function handleUnavailableAddObject(state, { payload: { keyState, loadingName, defaultState = [] } }) {
  return {
    ...state,
    [keyState]: { ...state[keyState], ...defaultState },
    [loadingName]: false,
  };
}