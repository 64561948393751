import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { BarChart, Title } from '@zippeditoolsjs/dashboards';

export default function DowntimeChart(props) {
  const {
    size = 6,
    title = 'Active',
    downtimeData,
    inputIndex,
    inputCategories,
    textOrientation = 'text-left',
  } = props;

  return (
    <Grid container item xs={size} padding={1}>
      <Title>{title}</Title>
      {downtimeData?.length ? (
        <BarChart
          data={downtimeData}
          index={inputIndex}
          categories={inputCategories}
        />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </Grid>
  );
}
