import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TIERS } from '../tools/constants';

export default function EscalationDialog(props) {
  const {
    openDialog,
    handleCloseDialog,
    eventAction,
    userTier,
    handleConfirmResolve,
  } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {eventAction === 'change_tier_BLOCKED'
          ? userTier > TIERS.MONITOR_ID
            ? t('overseer_app.event.descalate', 'De-escalate')
            : t('overseer_app.event.escalate', 'Escalate')
          : t(
            'overseer_app.notification.resolved_confirm',
            'Mark the event as resolved'
          )}
      </DialogTitle>
      <DialogContent>
        {eventAction === 'change_tier_BLOCKED'
          ? t(
            'overseer_app.notification.change_tier',
            `You are about to ${userTier > TIERS.MONITOR_ID ? 'de-escalate' : 'escalate'
            } this event. Do you want to continue?`
          )
          : t(
            'overseer_app.notification.resolved_modal',
            'You are about to mark this event as resolved. Do you want to continue?'
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>No</Button>
        <Button onClick={handleConfirmResolve} autoFocus>
          {t('overseer_app.general.yes', 'Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
