import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotViewContext } from '../../context/RobotView';
import AlertBox from '../tools/AlertBox';
import MapCanvas from '../tools/MapCanvas';
import TabPanel from '../tools/TabPanel';
import { a11yProps, LAYER_OPTIONS, SINGLE_OPTIONS, CONNECTIVITY_OPTIONS, MAPPING_ALERT_OPTIONS } from '../Utils';
import CustomSelector from '../tools/CustomSelector';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '110em',
  maxWidth: '85%',
  height: '80%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function MapWidget(props) {
  const {
    id,
    title,
    robot,
    storeInfo,
    mqttNavInfo,
    storeMapInfo,
    minHeight,
    getMapSignal,
    getMappingAlerts,
    mapSignal,
    isLoadingMapSignalInfo,
    mappingAlerts,
    isMappingAlertsLoading,
  } = props;

  const { t } = useTranslation();

  const [isFollowingRobot, setFollowingRobot] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [uniqueLayerDisplay, setUniqueLayerDisplay] = useState(null);
  const [selectedValues, setSelectedValues] = useState([
    'robot',
    'lidar',
    'navigation_path',
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [canvasList, setCanvasList] = useState([]);
  const [selectedMappingAlerts, setSelectedMappingAlerts] = useState(null);
  const mapControlsRef = useRef(null);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCanvasList = (list) => setCanvasList(list);
  const handleDownloadMap = () => {
    if (canvasList?.length > 0) {
      const baseImage = document.getElementById('baseImage');

      const combinedCanvas = document.createElement('canvas');
      combinedCanvas.width = baseImage.clientWidth;
      combinedCanvas.height = baseImage.clientHeight;
      const ctx = combinedCanvas.getContext('2d');

      // Draw the base image on the new canvas
      ctx.drawImage(baseImage, 0, 0);

      // Get and draw the other canvases on the new canvas
      canvasList.forEach(id => {
        const canvas = document.getElementById(id);
        ctx.drawImage(canvas, 0, 0);
      });

      // Download the combined image
      const link = document.createElement('a');
      link.href = combinedCanvas.toDataURL('image/png');
      link.download = 'combined_image.png';
      link.click();
    } else {
      console.log("No canvas to download");
    }
  }

  const { isSubscribedStatus } = useContext(RobotViewContext);

  useEffect(() => {
    let layersList = [];
    let signal = null;
    let mappingAlert = null;
    selectedValues.forEach((item) => {
      if (LAYER_OPTIONS.some((obj) => obj.id === item)) layersList.push(item);
      else if (CONNECTIVITY_OPTIONS.some((obj) => obj.id === item)) signal = item;
      else if (MAPPING_ALERT_OPTIONS.some((obj) => obj.id === item)) mappingAlert = item;
    });
    mapControlsRef.current?.handleCleanCanvas({ canRemove: !(mappingAlert || signal) });
    setSelectedLayers(layersList);
    setSelectedSignal(signal);
    setSelectedMappingAlerts(mappingAlert);
    setUniqueLayerDisplay(signal || mappingAlert);
  }, [selectedValues]);

  useEffect(() => {
    if (selectedSignal && storeInfo?.code) {
      let mapInfo = {
        resolution: storeMapInfo.resolution,
        height: storeMapInfo.height,
        width: storeMapInfo.width,
        origin: storeMapInfo.origin,
      };
      if (selectedSignal === 'full') getMapSignal(storeInfo.code, mapInfo);
      else getMapSignal(storeInfo.code, mapInfo, selectedSignal);
    }
  }, [selectedSignal]);

  useEffect(() => {
    if (selectedMappingAlerts && storeInfo?.code) {
      const robotCode = `${storeInfo.code}-${robot}`;
      getMappingAlerts(robotCode);
    }
  }, [selectedMappingAlerts]);

  const handleFollowingRobotChange = (event) => {
    setFollowingRobot(event.target.checked);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue !== undefined) setTabIndex(newValue);
  };

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleTabChange}
            value={tabIndex}
            aria-label={'robot-status-tab-0'}
          >
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFollowingRobot}
                    onChange={handleFollowingRobotChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size="small"
                  />
                }
                label={t(
                  'overseer_app.widget.follow_robot_map',
                  'Follow robot'
                )}
              />
            </Box>
            <Box sx={{ alignSelf: 'center', mr: 1 }}>
              <CustomSelector
                singleOptions={SINGLE_OPTIONS}
                multipleOptions={LAYER_OPTIONS}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
              />
              <Tooltip title={t('overseer_app.widget.expand', 'Expand')}>
                <IconButton size="small" variant="outlined" sx={{ ml: 1 }} onClick={handleOpenModal}
                >
                  <IconComponent
                    iconName="expand"
                    style={{ fontSize: 24 }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('overseer_app.general.download', 'Download')}>
                <IconButton size="small" variant="outlined" sx={{ ml: 1 }} onClick={handleDownloadMap}
                >
                  <IconComponent
                    iconName="download"
                    style={{ fontSize: 24 }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Tabs>
        </Grid>
        <Grid xs={12}>
          <TabPanel value={tabIndex} index={0}>
            {(isLoadingMapSignalInfo || isMappingAlertsLoading) ? (
              <LinearProgress
                sx={{ width: '100%', mx: 'auto' }}
                color="secondary"
              />
            ) : null}
            <Grid
              item
              xs={12}
              sx={{
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch !important',
                minHeight: minHeight,
                maxHeight: 900,
                position: 'relative',
                borderBottom: '1px solid lightgray',
              }}
            >
              {!isSubscribedStatus ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ position: 'sticky', top: 0, zIndex: 1 }}
                />
              ) : storeMapInfo.img ? (
                <img
                  id='baseImage'
                  src={`data:image/jpeg;base64,${storeMapInfo.img}`}
                  style={{ position: 'absolute', top: 0, zIndex: 1 }}
                  alt="map"
                />
              ) : (
                <AlertBox
                  severity="warning"
                  sx={{
                    bgcolor: 'grey.300',
                    py: 7,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    height: 180,
                    width: '100%',
                  }}
                  content={t('overseer_app.widget.no_map', 'No map available')}
                />
              )}
              <MapCanvas
                id={id}
                info={storeMapInfo}
                width={storeMapInfo.width}
                height={storeMapInfo.height}
                navInfo={mqttNavInfo}
                isFollowingRobot={isFollowingRobot}
                layersDisplay={selectedLayers}
                signalDisplay={selectedSignal}
                uniqueLayerDisplay={uniqueLayerDisplay}
                getMapSignal={getMapSignal}
                mapSignal={mapSignal}
                mappingAlerts={mappingAlerts}
                mapControlsRef={mapControlsRef}
                onCanvasListChange={handleCanvasList}
              />
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-map-widget-title"
        aria-describedby="modal-map-widget-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Robot Map
            <Divider />
          </Typography>
          <Box id="modal-modal-description"
            sx={{
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch !important',
              minHeight: minHeight,
              height: '100em',
              maxHeight: '90%',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              mt: 2
            }}>

            {!isSubscribedStatus ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{ position: 'sticky', top: 0, zIndex: 1 }}
              />
            ) : storeMapInfo.img ? (
              <img
                src={`data:image/jpeg;base64,${storeMapInfo.img}`}
                alt="map"
                style={{ position: 'absolute', top: 0, zIndex: 1 }}
              />
            ) : (
              <AlertBox
                severity="warning"
                sx={{
                  bgcolor: 'grey.300',
                  py: 7,
                  alignSelf: 'center',
                  justifyContent: 'center',
                  height: 180,
                  width: '100%',
                }}
                content={t('overseer_app.widget.no_map', 'No map available')}
              />
            )}
            <Box style={{ position: 'absolute', top: 0, zIndex: 1, width: storeMapInfo.width, height: storeMapInfo.height }}
            >
              <MapCanvas
                id={`${id}-modal`}
                info={storeMapInfo}
                width={storeMapInfo.width}
                height={storeMapInfo.height}
                navInfo={mqttNavInfo}
                isFollowingRobot={isFollowingRobot}
                layersDisplay={selectedLayers}
                signalDisplay={selectedSignal}
                uniqueLayerDisplay={uniqueLayerDisplay}
                getMapSignal={getMapSignal}
                mapSignal={mapSignal}
                mappingAlerts={mappingAlerts}
                mapControlsRef={mapControlsRef}
              />
            </Box>
          </Box>
        </Box>
      </Modal>

    </Card>
  );
}
