import { Autocomplete, Grid, LinearProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotAllowedView from '../tools/NotAllowed';
import { checkPermision } from '../Utils';
import ParamsTable from './ParamsTable';
import UpdateDialog from './UpdateDialog';
import VersionTable from './VersionTable';

const robotSelectStyle = {
  paddingTop: '1rem',
};

export default function Parameters(props) {
  const {
    //List with data
    chainsList,
    parameters,
    robotVersions,
    robotsListBaldur,
    paramsUpdateResponse,
    parametersValuesList,
    navOptions,
    //Booleans
    isLoadingChains,
    isLoadingParametersValues,
    isLoadingRobotVersions,
    isLoadingRobotListBaldur,
    isLoadingParametersUpdate,
    //Methods
    getChains,
    getRobotsBaldur,
    getRobots,
    getAllVersionsRobot,
    getParameters,
    getRobotParametersValues,
    getRobotParametersByVersion,
    postParameterUpdates,
  } = props;

  const { t } = useTranslation();
  const viewId = 'Parameters';
  const [selectedChains, setSelectedChains] = useState(null);
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [robotVerionsList, setRobotVersionsList] = useState([]);
  const [parameterUpdate, setParameterUpdate] = useState('');
  const [open, setOpen] = useState(false);
  const [paramToEdit, setParamToEdit] = useState('');
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [lastVersion, setLastVersion] = useState(null);

  useEffect(() => {
    if (chainsList && chainsList.length === 0) {
      getChains();
    }
  }, [chainsList, getChains]);

  useEffect(() => {
    if (selectedChains && selectedChains.length > 0) {
      getRobots(selectedChains);
      getRobotsBaldur({ chains: selectedChains });
      getParameters();
    }
  }, [selectedChains, getRobotsBaldur]);

  useEffect(() => {
    if (selectedRobots) {
      getRobotParametersValues(selectedRobots);
    }
  }, [selectedRobots, getRobotParametersValues]);

  useEffect(() => {
    if (robotVersions.length > 0) {
      setLastVersion(robotVersions[0].version);
      setSelectedVersion(robotVersions[0].version);
    }
    setRobotVersionsList(robotVersions);
  }, [robotVersions]);

  useEffect(() => {
    if (selectedRobots && selectedRobots.length === 1) {
      getAllVersionsRobot({ robot_uuid: selectedRobots[0] });
    } else if (selectedRobots && robotVerionsList.length > 0) {
      setRobotVersionsList([]);
    }
  }, [selectedRobots, getAllVersionsRobot]);

  useEffect(() => {
    if (paramsUpdateResponse) {
      getRobotParametersValues(selectedRobots);
      handleDialog();
      if (
        paramsUpdateResponse.message &&
        paramsUpdateResponse.message === 'Success'
      ) {
        getVersionsAfterUpdate();
      }
    }
  }, [paramsUpdateResponse]);

  const handleDialog = () => {
    setOpen(!open);
  };

  const getVersionsAfterUpdate = () => {
    if (selectedRobots && selectedRobots.length === 1) {
      getAllVersionsRobot({ robot_uuid: selectedRobots[0] });
    }
  };

  const handleClickModal = (row) => () => {
    let parameterUpdate = row.parameter;
    setParameterUpdate(parameterUpdate);
    //add logic to filter datarobots by parameter then setRobotsData
    handleDialog();
    // find robot parameters values
    let paramValues = parametersValuesList.find(
      (item) => item.parameter === parameterUpdate
    );
    setParamToEdit(paramValues);
  };

  const handleTableRowClick = (row) => {
    getRobotParametersByVersion(row.robot_uuid, row.version);
  };

  const handleVersion = (version) => {
    if (version) setSelectedVersion(version.version);
  };

  return (
    <>
      <Grid container spacing={2}>
        {navOptions.length === 0 ? (
          <LinearProgress
            sx={{ width: '100%', mx: 'auto', top: '-5px' }}
            color="secondary"
          />
        ) : (
          <>
            {checkPermision(navOptions, viewId) ? (
              <>
                <Grid item xs={12} md={selectedRobots.length > 1 ? 3 : 2}>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      fullWidth
                      multiple
                      disableCloseOnSelect
                      options={
                        chainsList
                          ? chainsList.map((chain) => chain.name)
                          : chainsList
                      }
                      onChange={(e, value) => setSelectedChains(value)}
                      renderInput={(params) => (
                        <TextField {...params} label="Chains" />
                      )}
                    />
                    {isLoadingChains && (
                      <LinearProgress
                        sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                        color="secondary"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      fullWidth
                      multiple
                      disableCloseOnSelect
                      sx={robotSelectStyle}
                      options={robotsListBaldur}
                      getOptionLabel={(option) => option.robot_code.toString()}
                      onChange={(e, value) => {
                        setSelectedRobots(value.map((item) => item.robot_uuid));
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Robots" />
                      )}
                    />
                    {isLoadingRobotListBaldur && (
                      <LinearProgress
                        sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                        color="secondary"
                      />
                    )}
                  </Grid>
                </Grid>
                {selectedRobots ? (
                  selectedRobots.length === 1 ? (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      {isLoadingRobotVersions && (
                        <LinearProgress
                          sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                          color="secondary"
                        />
                      )}
                      <VersionTable
                        data={robotVerionsList ? robotVerionsList : null}
                        handleTableRowClick={handleTableRowClick}
                        handleVersion={handleVersion}
                      />
                    </Grid>
                  ) : null
                ) : null}
                <Grid
                  item
                  xs={12}
                  md={selectedRobots.length > 1 ? 9 : 6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  {isLoadingParametersValues && (
                    <LinearProgress
                      sx={{ width: '100%', mx: 'auto', top: '-5px' }}
                      color="secondary"
                    />
                  )}
                  {parametersValuesList && parametersValuesList.length ? (
                    <ParamsTable
                      data={parametersValuesList}
                      parameterDefinition={parameters}
                      handleClickModal={handleClickModal}
                      navOptions={navOptions}
                      viewId={viewId}
                      selectedVersion={selectedVersion}
                      lastVersion={lastVersion}
                      selectedRobots={selectedRobots}
                    />
                  ) : null}
                </Grid>
              </>
            ) : (
              <NotAllowedView />
            )}
          </>
        )}
      </Grid>
      <UpdateDialog
        parameterUpdate={parameterUpdate}
        paramToEdit={paramToEdit}
        updatePost={postParameterUpdates}
        loading={isLoadingParametersUpdate}
        open={open}
        handleDialog={handleDialog}
      />
    </>
  );
}
