import { Divider, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Icon from '../tools/Icon';

export default function ConfirmDialog(props) {
  const { open, message, title, handleClose, handleOpen, handleConfirm } =
    props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">
        <Typography
          variant="h6"
          sx={{ display: 'flex', alignItems: 'baseline' }}
          gutterBottom
        >
          <Icon iconName="warning" color={'warning'} />
          {title}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <Typography variant="body" gutterBottom>
            {message}
          </Typography>
        </DialogContentText>
        <Divider sx={{ my: 1 }} />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title="The action will be canceled">
          <Button onClick={() => handleOpen(false)} variant="outlined">
            No
          </Button>
        </Tooltip>
        <Button
          onClick={handleConfirm}
          autoFocus
          variant="contained"
          color="secondary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
