import {
  handleAvailable,
  handleRequest,
  handleUnavailable,
} from '../reducerHandlers';
import {
  ROBOT_MONITORING_AVAILABLE,
  ROBOT_MONITORING_REQUEST,
  ROBOT_MONITORING_UNAVAILABLE,
  SNACK_SET,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  mappingAlerts: null,
  robotActions: [],
  robotsTaken: {},
  robotStatusHistory: null,
  loadingTakeRobot: false,
  loadingLeaveRobot: false,
  isLoadingMapSignal: false,
  loadingRobotActions: false,
  loadingRobotBlocking: false,
  isLoadingMappingAlerts: false,
  isLoadingRobotStatusHistory: false,
  robotState: null,
  isLoadingRobotState: false,
  availableStatus: [],
  isLoadingAvailableStatus: false,
  isLoadingCallerHistory: false,
  isLoadingPostCallerActionResponse: false,
  callMotives: [],
};

export const reducer = createReducer(defaultState, {
  [ROBOT_MONITORING_REQUEST]: handleRequest,
  [ROBOT_MONITORING_AVAILABLE]: handleAvailable,
  [ROBOT_MONITORING_UNAVAILABLE]: handleUnavailable,
});

export function getRobotActions() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotActions';
    const keyState = 'robotActions';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const robotActionsResponse = await dataSource.getRobotActions();
      const robotActions = robotActionsResponse ? robotActionsResponse : [];
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: robotActions },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function topicMessageFeedback(response, status) {
  return (dispatch, _, { services: { dataSource } }) => {
    let snack = {
      open: true,
      message: response,
      severity: status,
    };
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function postUserActions(action, robot_code, extra_info = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingNewUserActions';
    const keyState = 'newUserActionsResponse';
    let info = extra_info ? JSON.stringify(extra_info) : extra_info;
    dispatch({ type: ROBOT_MONITORING_AVAILABLE, payload: { loadingName } });
    try {
      const creationResponse = await dataSource.postUserAction(
        action,
        robot_code,
        info
      );
      const creation = creationResponse ? creationResponse : {};
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { data: creation, keyState, loadingName },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
    }
  };
}

export function getRobotsTaken(robot = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotsTaken';
    const keyState = 'robotsTaken';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const robotsTakenResponse = await dataSource.getRobotsTaken(robot);
      const robotsTaken = robotsTakenResponse ? robotsTakenResponse : [];
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: robotsTaken },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function takeRobot(robot, custom_timeout = 15, kick = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingTakeRobot';
    const keyState = 'takeRobotResponse';
    let snack;
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const takeRobotResponse = await dataSource.postTakeRobot(
        robot,
        custom_timeout,
        kick
      );
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { data: takeRobotResponse, keyState, loadingName },
      });
      snack = {
        open: true,
        message: takeRobotResponse.message,
      };
      if (takeRobotResponse.error) {
        snack['severity'] = 'error';
      } else {
        snack['severity'] = 'success';
      }
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'Error',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function leaveRobot(robot) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingLeaveRobot';
    const keyState = 'leaveRobotResponse';
    let snack;
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const leaveRobotResponse = await dataSource.postLeaveRobot(robot);
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { data: leaveRobotResponse, keyState, loadingName },
      });
      snack = {
        open: true,
        message: leaveRobotResponse.message,
      };
      if (leaveRobotResponse.error) {
        snack['severity'] = 'error';
      } else {
        snack['severity'] = 'success';
      }
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'Error',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function getRobotBlocking(robot) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingRobotBlocking';
    const keyState = 'robotBlocking';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const robotBlockingResponse = await dataSource.getRobotsTaken(robot);
      const robotBlocking = robotBlockingResponse ? robotBlockingResponse : [];
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: robotBlocking },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getProductsByName(productName, store, limit) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingProductsByName';
    const keyState = 'productsList';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const productsByNameResponse = await dataSource.getProducts(
        productName,
        store,
        limit
      );
      const productsList = productsByNameResponse ? productsByNameResponse : [];
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: productsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotStatusHistory({ robotCode, hoursBefore }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotStatusHistory';
    const keyState = 'robotStatusHistory';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getRobotStatusHistory({
        robotCode,
        hoursBefore,
      });
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getMapSignal(storeCode, mapData, signalType = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingMapSignal';
    const keyState = 'mapSignal';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getMapSignal(
        storeCode,
        JSON.stringify(mapData),
        signalType
      );
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getMappingAlerts(robotCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingMappingAlerts';
    const keyState = 'mappingAlerts';
    dispatch({
      type: ROBOT_MONITORING_REQUEST, payload: { loadingName, aditionalStates: { [keyState]: null } }
    });
    try {
      const response = await dataSource.getMappingAlerts(robotCode);
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getAvailableRobotStatus() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingAvailableStatus';
    const keyState = 'availableStatus';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getAllStatus();
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function postActionReason(modalForm) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingPostActionReasonResponse';
    const keyState = 'postActionReasonResponse';
    dispatch({ type: ROBOT_MONITORING_AVAILABLE, payload: { loadingName } });
    try {
      const response = await dataSource.postActionReason(modalForm);
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { data: response, keyState, loadingName },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
    }
  };
}

export function getRobotState(robotCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotState';
    const keyState = 'robotState';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getRobotState(robotCode);
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function updateRobotState(robot_code, state_id, motive) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'loadingNewRobotState';
    const keyState = 'newRobotStateResponse';
    let snack;
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const updateResponse = await dataSource.updateRobotState(
        robot_code,
        state_id,
        motive
      );
      const update = updateResponse ? updateResponse : {};
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { data: update, keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'The robot state was updated',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'Error updating robot state',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function getRobotStateHistory(robotCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotStateHistory';
    const keyState = 'robotStateHistory';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getRobotStateHistory(robotCode);
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getActionReasons({ command }) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingActionReasons';
    const keyState = 'actionReasons';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getActionReasons({ command });
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: {
          keyState,
          loadingName,
          data: response?.reason_options ?? [],
        },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getRobotStateMotives() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobotStateMotives';
    const keyState = 'robotStateMotives';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getAllRobotMotives();
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function getCallerHistory(store) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingCallerHistory';
    const keyState = 'callerHistory';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getCallerActionHistory(store);
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}

export function postCallerAction(
  store_code,
  phone_number,
  store_contact_id,
  alert_description, // Same text as the motive, but in the store language for the text-to-voice call
  motive
) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingPostCallerActionResponse';
    const keyState = 'postCallertActionResponse';
    let snack;
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.postCallerAction(
        store_code,
        phone_number,
        store_contact_id,
        alert_description,
        motive
      );
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { data: response, keyState, loadingName },
      });
      snack = {
        open: true,
        message: response?.msg,
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { error, keyState, loadingName, defaultState: {} },
      });
      snack = {
        open: true,
        message: 'There was a problem making the call',
        severity: 'error',
      };
    }
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}

export function getCallMotives() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingCallMotives';
    const keyState = 'callMotives';
    dispatch({ type: ROBOT_MONITORING_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getCallMotives();
      dispatch({
        type: ROBOT_MONITORING_AVAILABLE,
        payload: { keyState, loadingName, data: response },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: ROBOT_MONITORING_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
    }
  };
}
