import { createReducer } from './utils';
import {
  BACKEND_REQUEST,
  BACKEND_AVAILABLE,
  BACKEND_UNAVAILABLE,
  SNACK_SET,
  BACKEND_OBJECT_AVAILABLE,
  BACKEND_OBJECT_UNAVAILABLE,
  LEGACY_BACKEND_REQUEST,
  LEGACY_BACKEND_AVAILABLE,
  LEGACY_BACKEND_UNAVAILABLE,
} from './constants';
import { handleAvailable, handleRequest, handleUnavailable } from '../reducerHandlers';
import { FormatToSentenceCase } from '../utils/parsers';

const defaultState = {
  loading_chains: false,
  loading_robots: false,
  isLoadingBrokerUrl: false,
  chainsList: [],
  robotsList: [],
  brokerUrl: null,
  featurePermission: {},
  navigationStatuses: [],
  isLoadingNavigationStatuses: false,
};

export const reducer = createReducer(defaultState, {
  [BACKEND_REQUEST]: handleRequest,
  [BACKEND_AVAILABLE]: handleAvailable,
  [BACKEND_UNAVAILABLE]: handleUnavailable,
  [LEGACY_BACKEND_REQUEST]: handleCustomRequest,
  [LEGACY_BACKEND_AVAILABLE]: handleCustomAvailable,
  [LEGACY_BACKEND_UNAVAILABLE]: handleCustomUnavailable,
  [BACKEND_OBJECT_AVAILABLE]: handleCustomObjectAvailable,
  [BACKEND_OBJECT_UNAVAILABLE]: handleCustomObjectUnavailable,
});

function handleCustomRequest(state, { payload: { loadingName } }) {
  return {
    ...state,
    [loadingName]: true,
  };
}

function handleCustomAvailable(
  state,
  { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }
) {
  const x = {
    ...state,
    [loadingName]: false,
    [keyState]: requestInfo,
    ...aditionalStates,
  };
  return x;
}

function handleCustomUnavailable(
  state,
  { payload: { keyState, loadingName } }
) {
  return {
    ...state,
    [keyState]: [],
    [loadingName]: false,
  };
}

function handleCustomObjectAvailable(
  state,
  { payload: { keyState, loadingName, requestInfo, additionalStates = {} } }
) {
  const previousState = state[keyState] || {};
  const x = {
    ...state,
    [loadingName]: false,
    [keyState]: { ...previousState, ...requestInfo },
    ...additionalStates,
  };
  return x;
}

function handleCustomObjectUnavailable(
  state,
  { payload: { keyState, loadingName, objectKey } }
) {
  const previousState = state[keyState] || {};
  delete previousState[objectKey];
  return {
    ...state,
    [keyState]: previousState,
    [loadingName]: false,
  };
}

export function getChains() {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingChains';
    const keyState = 'chainsList';
    dispatch({ type: LEGACY_BACKEND_REQUEST, payload: { loadingName } });
    try {
      const chainsResponse = await dataSource.getChains();
      const chainsList = chainsResponse ? chainsResponse : [];
      dispatch({
        type: LEGACY_BACKEND_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: chainsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LEGACY_BACKEND_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function getRobots(chains_list) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingRobots';
    const keyState = 'robotsList';
    dispatch({ type: LEGACY_BACKEND_REQUEST, payload: { loadingName } });
    try {
      const robotsResponse = await dataSource.getRobots(
        JSON.stringify(chains_list)
      );
      const robotsList = robotsResponse ? robotsResponse : [];
      dispatch({
        type: LEGACY_BACKEND_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: robotsList },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LEGACY_BACKEND_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function getBroker(storeCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingBrokerUrl';
    const keyState = 'brokerUrl';
    dispatch({ type: LEGACY_BACKEND_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getBroker(storeCode.toUpperCase());
      if (response?.message === 'not found') {
        const snack = {
          open: true,
          message: 'This store does not have configured a MQTT connection.',
          severity: 'error',
        };
        dispatch({ type: SNACK_SET, payload: { snack } });
      } else {
        dispatch({
          type: LEGACY_BACKEND_AVAILABLE,
          payload: {
            keyState,
            loadingName,
            requestInfo: response.broker?.broker_url ?? null,
          },
        });
      }
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LEGACY_BACKEND_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function getStore(storeCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingStoreInfo';
    const keyState = 'storeInfo';
    dispatch({ type: LEGACY_BACKEND_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getStore(storeCode.toUpperCase());
      dispatch({
        type: LEGACY_BACKEND_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: response.store },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LEGACY_BACKEND_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}

export function checkFeaturePermission(feature) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isLoadingCheckPermission';
    const keyState = 'featurePermission';
    const objectKey = feature;
    dispatch({ type: LEGACY_BACKEND_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getFeaturePermission(feature);
      const featurePermission = { [feature]: response[0] }
      dispatch({
        type: BACKEND_OBJECT_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: featurePermission },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: BACKEND_OBJECT_UNAVAILABLE,
        payload: { error, keyState, loadingName, objectKey },
      });
    }
  };
}

export function getNavigationStatus(view = null) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'navigationStatuses'
    const loadingName = 'isLoadingNavigationStatuses'
    dispatch({ type: BACKEND_REQUEST, payload: { loadingName: loadingName } });
    try {
      const response = await dataSource.getNavigationStatus(view);
      // Parse the status, so that the first letter is uppercase
      const parsedResponse = response.map(status => (
        {
          ...status,
          name: FormatToSentenceCase(status["status"])
        }
      ))
      dispatch({
        type: BACKEND_AVAILABLE,
        payload: { keyState: keyState, data: parsedResponse, loadingName: loadingName },
      });
    } catch (error) {
      console.log('error: ', error);
      dispatch({
        type: BACKEND_UNAVAILABLE,
        payload: { keyState: keyState, loadingName: loadingName, defaultState: [] },
      });
      const snack = {
        open: true,
        message: "There was an error getting the navigation statuses.",
        severity: 'error',
      };
      dispatch({ type: SNACK_SET, payload: { snack } });
    }
  };
}

export function getCountryByStore(storeCode) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const keyState = 'storeCountry';
    const loadingName = 'isLoadingStoreCountry';
    dispatch({ type: LEGACY_BACKEND_REQUEST, payload: { loadingName } });
    try {
      const response = await dataSource.getStoreCountry(
        storeCode.toUpperCase()
      );
      dispatch({
        type: LEGACY_BACKEND_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: response[0] },
      });
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: LEGACY_BACKEND_UNAVAILABLE,
        payload: { error, keyState, loadingName },
      });
    }
  };
}