import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import dayjs from 'dayjs';
import { Fragment, React, useEffect, useState } from 'react';

import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';
import AlertBox from '../tools/AlertBox';
import TabPanel from '../tools/TabPanel';
import { a11yProps } from '../Utils';
import { combineScheduleWithSessionStates } from '../utils/commons';
import SvgIcon from '../utils/SVGIcon';

export default function ScheduleWidget(props) {
  const {
    title,
    store,
    robot,
    isLoadingSessions,
    rawRobotStatus,
    sessionsList,
    getSessionsScheduler,
    getSessionsState,
    sessionsState,
    robotsListBaldur,
    getRobotsBaldur,
  } = props;

  const nDays = 2;
  const N_BEFORE_WINDOW = 1;

  const [actualRobotSession, setActualRobotSession] = useState('');
  const [actualSessionState, setActualSessionState] = useState(null);
  const [robotSessions, setRobotSessionsList] = useState([]);
  const [isLoadingRobotSessions, setIsLoadingRobotSessions] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (rawRobotStatus && robotSessions?.length) {
      setActualRobotSession(rawRobotStatus.session_id);
      Object.values(sessionsState ?? {})?.forEach(session => {
        checkActualSession({ firstSessionName: session.name })
      });
    }
  }, [rawRobotStatus, robotSessions]);

  useEffect(() => {
    if (store && robot) {
      const robotCode = `${store}-${robot}`;
      getRobotsBaldur({ robotCode })
    }
  }, [store, robot]);

  useEffect(() => {
    if (robotsListBaldur?.length) {
      const yesterday = dayjs(new Date()).subtract(N_BEFORE_WINDOW, 'day');
      const from = dayjs(yesterday).format('YYYY-MM-DD')
      const to = dayjs(new Date()).add(nDays, 'day').format('YYYY-MM-DD');
      const offsetInHours = (new Date()).getTimezoneOffset()
      const userLocalTime = -offsetInHours / 60;
      getSessionsScheduler({ robotsData: robotsListBaldur, from, to, user_utc_offset: userLocalTime });
      getSessionsState({ robotsData: robotsListBaldur, from, to });
    }
  }, [robotsListBaldur]);

  useEffect(() => {
    if (sessionsList?.length && Object.keys(sessionsState)?.length) {
      setRobotSessionsList(beforeState => {
        setIsLoadingRobotSessions(true)
        return combineScheduleWithSessionStates(sessionsList, sessionsState)?.[0]?.local_scheduler ?? []
      });
      setIsLoadingRobotSessions(false)
    }
  }, [sessionsList, sessionsState]);

  const checkActualSession = ({ firstSessionName }) => {
    if (firstSessionName === actualRobotSession) {
      setActualSessionState(rawRobotStatus.state);
    }
  };

  const checkSessionState = (session) => {
    let state, icon;

    const currentSessionIcon = session.sessionName === actualRobotSession ?
      <Tooltip title={t('overseer_app.widgets.robot_current_session', "Robot's current session")}>
        <Typography>
          <SvgIcon name="overseer-robot-validation" style={{ fontSize: "30px", position: 'relative', left: '-3px' }} />
        </Typography>
      </Tooltip>
      : null;
    switch (session.sessionName) {
      case actualRobotSession:
        switch (actualSessionState) {
          case 'ST_CAPTURING':
          case 'ST_SENDING_CROPS':
          case 'ST_COMPUTING_STOCKOUT':
          case 'ST_SENDING_STOCKOUT':
          case 'ST_SENDING_REMAININ_CROPS':
          case 'ST_COMPUTING_DATA':
            state = t('overseer_app.widgets.in_progress', 'In progress');
            icon = <RefreshOutlinedIcon sx={{ color: '#0E7F19' }} />;
            break;
          case 'ST_WAITING_NEXT_SESSION':
            state = t('overseer_app.widgets.planned', 'Planned');
            icon = <IconComponent iconName='calendar' style={{ color: 'grey' }} />;
            break;
          case 'ST_CANCEL':
            state = t('overseer_app.widgets.canceled', 'Canceled');
            icon = <CancelOutlinedIcon sx={{ color: '#2a2a2a' }} />;
            break;
          case 'ST_FAILURE':
            state = t('overseer_app.widgets.failure', 'Failure');
            icon = <CancelIcon sx={{ color: '#fc2828' }} />;
            break;
          default:
            state = t('overseer_app.widgets.unknown', 'Unknown');
            icon = <QuestionMarkIcon sx={{ color: '#6b7280' }} />;
            break;
        }
        break;
      default:
        if (session.sessionState?.nav_status === 'not_started') {
          state = t('overseer_app.widgets.not_started', 'Not Started');
          icon = <IconComponent iconName='calendar' style={{ color: 'grey' }} />;
        } else if (session.sessionState?.nav_status === 'finished') {
          state = t('overseer_app.widgets.finished', 'Finished');
          icon = <IconComponent iconName='checkmark-circle-outline' style={{ fontSize: '20px', color: '#0070B7' }} />
        } else if (session.sessionState?.nav_status === 'navigating') {
          state = t('overseer_app.widgets.in_progress', 'In progress');
          icon = <RefreshOutlinedIcon sx={{ color: '#0E7F19' }} />;
        } else if (['aborted', 'cancelled'].includes(session.sessionState?.nav_status)) {
          state = t('overseer_app.widgets.planned', 'Canceled');
          icon = <CancelOutlinedIcon sx={{ color: '#2a2a2a' }} />;
        } else {
          state = t('overseer_app.widgets.planned', 'Planned');
          icon = <IconComponent iconName='calendar' style={{ color: 'grey' }} />;
        }
    }
    return (
      <>
        <ListItemIcon style={{ minWidth: '2rem' }}>
          {icon}
        </ListItemIcon>
        <Tooltip
          title={
            <>
              {`Session type: ${session.type}`} <br />
              {`Overhead mode: ${session.overhead_mode}`} <br />
              *Timestamp is in the store's local time
            </>
          }
        >
          <ListItemText
            primary={session.sessionName}
            secondary={<Typography>{session.local_start_time} - {state}</Typography>}
          />
        </Tooltip>
        {currentSessionIcon}
      </>
    );
  };

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Grid container>
        <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={0}
            aria-label={'robot-schedule-tab-0'}
          >
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
          </Tabs>
        </Grid>
        <Grid xs={12}>
          <TabPanel value={0} index={0}>
            {(isLoadingSessions || isLoadingRobotSessions) ? (
              <Skeleton variant="rectangular" width="100%" height="11em" />
            ) : robotSessions.length ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ overflowY: 'auto', maxHeight: '12em' }}
                >
                  <List>
                    {robotSessions[0].map((robotDaySession, index) => (
                      robotDaySession.date &&
                      <Fragment key={`robot-session-${index}`}>
                        <Typography
                          variant="h8"
                          color="primary"
                          sx={{ pl: 1 }}
                        >
                          {robotDaySession.date}
                        </Typography>
                        {robotDaySession?.sessions?.map((session) => (
                          <ListItem style={{ paddingTop: 0 }}>
                            {checkSessionState(session)}
                          </ListItem>
                        ))}
                        {robotDaySession.date && <Divider />}
                      </Fragment>
                    ))}
                  </List>
                </Grid>
              </Grid>
            ) : (
              <AlertBox
                severity="info"
                sx={{ pt: 1 }}
                content={t(
                  'overseer_app.widget.no_sessions',
                  'No sessions available'
                )}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid >
    </Card >
  );
}
