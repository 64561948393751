/**
 * Sort an array of strings
 * @param   {array}   array   The array to sort
 * @returns {array}           The sorted array
 */
export function sortArray(array) {
  return array.sort((a, b) => {
    return a.localeCompare(b);
  });
}

/**
 * Capitalizes the first letter of a given string.
 *
 * @param   {string}    string   The input string to be capitalized.
 * @returns {string}             A new string with the first letter capitalized.
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
 * Converts a string to lowercase, except for the first letter of each word.
 *
 * @param {string} string - The input string.
 * @returns {string} The string with the first letter of each word capitalized and the rest in lowercase.
 */
export function lowerCaseAllWordsExceptFirstLetters(string) {
  let words = ''
  if (string) {
    words = string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
  } else {
    words = string
  }
  return words
}

/**
 * Parse a string in lowercase and with underscores to a string with spaces and first letters in uppercase (sentence case)
 * @param 	{String} string  	String to be parsed
 * @returns {String} 					Parsed string
 * @example
 * FormatToSentenceCase('hello_world') // Hello world
 */
export function FormatToSentenceCase(string) {
  let newString = string.replace(/_/g, ' ')
  return lowerCaseAllWordsExceptFirstLetters(newString);
}