import {
  SESSIONS_AVAILABLE,
  SESSIONS_REQUEST,
  SESSIONS_UNAVAILABLE,
  SNACK_SET,
} from './constants';
import { createReducer } from './utils';

const defaultState = {
  isDeletingSessions: false,
  deleteSessionsResponse: {},
};

export const reducer = createReducer(defaultState, {
  [SESSIONS_REQUEST]: handleCustomRequest,
  [SESSIONS_AVAILABLE]: handleCustomAvailable,
  [SESSIONS_UNAVAILABLE]: handleCustomUnavailable,
});


function handleCustomRequest(state, { payload: { loadingName } }) {
  return {
    ...state,
    [loadingName]: true,
  };
}

function handleCustomAvailable(state, { payload: { keyState, loadingName, requestInfo, aditionalStates = {} } }) {
  const x = {
    ...state,
    [loadingName]: false,
    [keyState]: requestInfo,
    ...aditionalStates,
  };
  return x
}

function handleCustomUnavailable(state, { payload: { keyState, loadingName } }) {
  return {
    ...state,
    [keyState]: [],
    [loadingName]: false,
  };
}

export function deleteSessions(sessions, date) {
  return async (dispatch, _, { services: { dataSource } }) => {
    const loadingName = 'isDeletingSessions';
    const keyState = 'deleteSessionsResponse';
    let snack;
    dispatch({ type: SESSIONS_REQUEST, payload: { loadingName } });
    try {
      const sessionsResponse = await dataSource.deleteSessions(JSON.stringify(sessions), date);
      const sessionsScheduler = sessionsResponse ? sessionsResponse : {};
      dispatch({
        type: SESSIONS_AVAILABLE,
        payload: { keyState, loadingName, requestInfo: sessionsScheduler },
      });
      snack = {
        open: true,
        message: 'Session(s) was deleted',
        severity: 'success',
      };
    } catch (error) {
      console.error('error: ', error);
      dispatch({
        type: SESSIONS_UNAVAILABLE,
        payload: { keyState, loadingName },
      });
      snack = {
        open: true,
        message: 'There was a problem with the deletion of the session',
        severity: 'error',
      };
    };
    dispatch({ type: SNACK_SET, payload: { snack } });
  };
}