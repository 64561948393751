import { connect } from 'react-redux';
import StoreContacts from '../../../components/storeContacts/StoreContacts';
import { getStoreContacts, putStoreContacts, resetPutResponse } from 'core/slices/store';
import { isLoadingStoreContacts, isLoadingStoreContactsPut, storeContacts, putResponse } from 'core/selectors';
import { setSnackAlert } from 'core/slices/snackAlert';

export default connect(
  (state) => ({
    storeContacts: storeContacts(state),
    isLoadingStoreContacts: isLoadingStoreContacts(state),
    putResponse: putResponse(state),
    isLoadingStoreContactsPut: isLoadingStoreContactsPut(state),
  }),
  {
    setSnackAlert,
    getStoreContacts,
    putStoreContacts,
    resetPutResponse,
  }
)(StoreContacts);
