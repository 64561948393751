import { Fragment, useEffect, useState } from 'react';

import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  Link,
} from '@mui/material';
import { IconComponent, isIconAvailable } from '@zippeditoolsjs/zippedi-icons';
import { useTranslation } from 'react-i18next';
import TabPanel from '../tools/TabPanel';

import { findIconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlertBox from '../tools/AlertBox';
import { a11yProps, colorIcon } from '../Utils';

function HardwareAlertsWidget(props) {
  const {
    getActiveHardwareAlerts,
    hardwareActiveAlerts,
    loadingHardwareActiveAlerts,
    getHistoricHardwareAlerts,
    historicHardwareAlerts,
    loadingHistoricHardwareAlerts,
    store,
    robot,
  } = props;
  library.add(fas);

  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (robot) {
      let robotCode = `${store}-${robot}`;
      getActiveHardwareAlerts(robotCode);
      getHistoricHardwareAlerts(robotCode);
    }
  }, [store, robot]);

  const handleColorAlert = (alert) => {
    return colorIcon(alert.monitor_assignation ? 'error' : 'warn');
  };

  return loadingHistoricHardwareAlerts || loadingHardwareActiveAlerts ? (
    <Box
      variant="rectangular"
      height={'100%'}
      minHeight={250}
      sx={{
        m: 2,
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <CircularProgress />
      <Typography>Loading Alerts...</Typography>
    </Box>
  ) : (
    <Card
      sx={{
        m: 2,
        height: '100%',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={t('overseer_app.widget.alerts', 'Alerts')}
            {...a11yProps(0, { textTransform: 'initial' })}
          />
          <Tab
            label={t('overseer_app.widget.card_alerts_history', 'History')}
            {...a11yProps(1, { textTransform: 'initial' })}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', maxHeight: 410, px: 2 }}>
            {hardwareActiveAlerts.length > 0 ? (
              hardwareActiveAlerts.map((alert, idx) => (
                <Fragment key={`active-alerts-list-${idx}`}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 0.5,
                      }}
                    >
                      {isIconAvailable({ iconName: alert.icon }) ? (
                        <IconComponent
                          iconName={alert.icon}
                          style={{
                            fontSize: '27px',
                            color: handleColorAlert(alert),
                          }}
                        />
                      ) : findIconDefinition({
                          iconName: alert.icon.slice(3),
                        }) ? (
                        <FontAwesomeIcon
                          icon={alert.icon}
                          color={handleColorAlert(alert)}
                          size="xl"
                        />
                      ) : (
                        <IconComponent
                          iconName={'warning'}
                          style={{
                            fontSize: '27px',
                            color: handleColorAlert(alert),
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                        {alert.description}
                      </Typography>
                      {
                        alert.hardware ?
                          <Typography sx={{ fontSize: '12px' }}>
                            {t(
                              'overseer_app.widget.Component',
                              'Component'
                            )}: {alert.hardware}
                          </Typography> :
                          null
                      }
                      <Typography sx={{ fontSize: '12px' }}>
                        Creation: {alert.created_at}
                      </Typography>
                      {alert.url ? (
                        <Link
                          href={alert.url}
                          underline="always"
                          target="_blank"
                        >
                          <Typography sx={{ fontSize: 12 }}>
                            {t(
                              'overseer_app.widget.link_to_ticket',
                              'Link to ticket'
                            )}
                          </Typography>
                        </Link>
                      ) : null}
                    </Box>
                  </Box>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <AlertBox
                severity="success"
                content={t(
                  'overseer_app.widget.no_active_alerts',
                  'No active alerts'
                )}
                sx={{ my: 1 }}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Grid container>
          <Grid item xs={12} sx={{ overflowY: 'auto', px: 2, maxHeight: 410 }}>
            {historicHardwareAlerts.length > 0 ? (
              historicHardwareAlerts.map((alert, idx) => (
                <Fragment key={`historic-alerts-list-${idx}`}>
                  <Box
                    key={idx}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      p: 1,
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 0.5,
                      }}
                    >
                      {isIconAvailable({ iconName: alert.icon }) ? (
                        <IconComponent
                          iconName={alert.icon}
                          style={{
                            fontSize: '27px',
                            color: handleColorAlert(alert),
                          }}
                        />
                      ) : findIconDefinition({
                          iconName: alert.icon.slice(3),
                        }) ? (
                        <FontAwesomeIcon
                          icon={alert.icon}
                          color={handleColorAlert(alert)}
                          size="xl"
                        />
                      ) : (
                        <IconComponent
                          iconName={'warning'}
                          style={{
                            fontSize: '27px',
                            color: handleColorAlert(alert),
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                        {alert.description}
                      </Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        {t('overseer_app.general.creation', 'Creation')}:{' '}
                        {alert.created_at}
                      </Typography>
                      {alert.supressed_at ? (
                        <Typography sx={{ fontSize: '12px' }}>
                          {t('overseer_app.general.suppression', 'Suppression')}
                          : {alert.supressed_at}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <AlertBox
                severity="success"
                content={t(
                  'overseer_app.widget.no_alerts_24',
                  'No alerts in the last 24 hours...'
                )}
                sx={{ my: 1 }}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>
    </Card>
  );
}

export default HardwareAlertsWidget;
