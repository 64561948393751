import {
  Box,
  FormControl,
  Grid, InputLabel, LinearProgress, MenuItem,
  Select,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RobotLayouts from '../../../redux/containers/layouts/RobotLayouts';
import TabPanel from '../../tools/TabPanel';
import { a11yProps } from '../../Utils';
import DragTransferList from '../../utils/DragTransferList';

export default function ScanningForm(props) {
  const {
    isDisabled,
    scanningForm,
    store,
    floor,
    inputOH = 'OFF',
    inputPlanner = 'graph',
    inputSelectedAisles = [],
    inputSelectedZones = [],
    isLoadingLayout,
  } = props;
  const TAB_INDEX = {
    ZONES: 0,
    AISLES: 1,
  };
  const [ohMode, setOhMode] = useState(inputOH);
  const [planner, setPlanner] = useState(inputPlanner);
  const [selectedLayer, setSelectedLayer] = useState('zones');
  const [selectedAisles, setSelectedAisles] = useState([]);
  const [aisleOptions, setAisleOptions] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [zonesOptions, setZonesOptions] = useState([]);
  const [tabIndex, setTabIndex] = useState(TAB_INDEX.ZONES);

  const initForm = useRef(scanningForm.current);

  const { t } = useTranslation();
  const PLANNER_OPTIONS = [
    { value: 'graph', label: 'Graph (preferred)' },
    { value: 'coverage', label: 'Coverage' },
  ]
  const OH_OPTIONS = [
    { value: 'ON', label: 'ON' },
    { value: 'OFF', label: 'OFF' },
    { value: 'PRIORITY', label: 'PRIORITY' },
  ];
  const SCANNING_AREAS = [
    { name: 'zones', label: t('overseer_app.general.zones', 'Zones'), index: 0 },
    { name: 'aisles', label: t('overseer_app.general.omitted_aisles', 'Omitted Aisles'), index: 1 },
  ]



  // Hooks
  useEffect(() => {
    initForm.current = handleInitializeForm();
    scanningForm.current = handleInitializeForm();
  }, []);

  useEffect(() => {
    if (isLoadingLayout) {
      const selectedLayer = inputSelectedZones?.length ? 'zones' : 'aisles';
      initForm.current = handleInitializeForm({
        ohMode: inputOH,
        planner: inputPlanner,
        selectedLayer,
        selectedZones: inputSelectedZones.map((zoneId) => zonesOptions.find((zone) => zone.id === zoneId)?.id),
        selectedAisles: inputSelectedAisles.map((aisleId) => aisleOptions.find((aisle) => aisle.id === aisleId)?.id),
      });
    }
  }, [isLoadingLayout]);

  useEffect(() => {
    scanningForm.current = handleInitializeForm();
  }, [ohMode, selectedLayer, selectedAisles, selectedZones, planner]);

  useEffect(() => {
    if (aisleOptions?.length && inputSelectedAisles?.length && !isLoadingLayout) {
      handleSelectedAisles(inputSelectedAisles.map((aisleId) => aisleOptions.find((aisle) => aisle?.id === aisleId)).filter(row => row?.id));
    } else if (inputSelectedAisles?.length) {
      setSelectedLayer('aisles');
      setTabIndex(TAB_INDEX.AISLES);
    }
  }, [aisleOptions, isLoadingLayout]);

  useEffect(() => {
    if (zonesOptions?.length && inputSelectedZones?.length && !isLoadingLayout) {
      handleSelectedZones(inputSelectedZones.map((zoneId) => zonesOptions.find((zone) => zone?.id === zoneId)).filter(row => row?.id));
    } else if (inputSelectedZones?.length) {
      setSelectedLayer('zones');
      setTabIndex(TAB_INDEX.ZONES);
    }
  }, [zonesOptions, isLoadingLayout]);


  // Handlers
  const handleTabChange = (event, newValue) => {
    setSelectedLayer(newValue === TAB_INDEX.AISLES ? 'aisles' : 'zones');
    setTabIndex(newValue);
  };

  const handleInitializeForm = (inputForm = {}) => {
    const newForm = {
      ohMode,
      planner,
      selectedLayer,
      selectedZones: selectedZones.map((zone) => zone.id),
      selectedAisles: selectedAisles.map((aisle) => aisle.id),
      ...inputForm,
    }
    const isTouched = handleIsFormTouched(newForm);
    newForm.isTouched = isTouched;
    return newForm
  }

  const handleIsFormTouched = (newForm) => {
    const firstForm = { ...initForm.current }
    const secondForm = { ...newForm }
    delete firstForm['isTouched'];
    delete secondForm['isTouched'];

    return JSON.stringify(firstForm) !== JSON.stringify(secondForm);
  }

  const handleOptions = ({ targetList, setOptions, patternMode = 'default' }) => {
    let targetSorted = targetList.sort((a, b) => {
      if (a.excluded && !b.excluded) return 1;
      if (!a.excluded && b.excluded) return -1;
      if (a.isInThePlan && !b.isInThePlan) return -1;
      if (!a.isInThePlan && b.isInThePlan) return 1;
      return 0;
    });
    targetSorted = targetSorted.map((target) => {
      return {
        ...target,
        secondary: target.excluded ? 'Aisle excluded by layout' : target.client_name ? `Client name: ${target.client_name}` : '',
        patternMode
      }
    });
    setOptions(targetSorted);
  }

  const handleChangeSelectScanMode = (event) => {
    setOhMode(event.target.value);
  };

  const handleSelectedAisles = (aisleList) => {
    setSelectedAisles(aisleList);
  }

  const handleSelectedZones = (zoneList) => {
    setSelectedZones(zoneList);
  }

  return (
    <Grid container sx={{ height: '100%' }} columnSpacing={8}>
      <Grid item xs={'auto'} sx={{ maxHeight: 'calc(600px - 0.8em)', overflow: 'auto' }}>
        <RobotLayouts
          store={store}
          floor={floor}
          onChangeAisles={(aisles) => handleOptions({ targetList: aisles, setOptions: setAisleOptions, patternMode: 'block_pattern' })}
          onChangeZones={(zones) => handleOptions({ targetList: zones, setOptions: setZonesOptions })}
          layersDisplay={[selectedLayer]}
          highlightedBoxes={selectedLayer === 'aisles' ? selectedAisles : selectedZones}
        />
      </Grid>
      <Grid item xs sx={{ mt: 1 }}>
        <Grid container rowSpacing={4}>
          {/* OH mode */}
          <Grid item xs={4}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="oh_mode-select-label">{t('overseer_app.scheduler.OH_Mode', 'OH Mode')}</InputLabel>
              <Select
                disabled={isDisabled}
                labelId="oh_mode-select-label"
                id="oh_mode-select-label"
                value={ohMode}
                label="OH Mode"
                onChange={handleChangeSelectScanMode}
              >
                {
                  OH_OPTIONS.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {/* PLANNER */}
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%', ml: 1 }}>
              <InputLabel id="planner-select-label">{t('overseer_app.scheduler.Planner', 'Planner')}</InputLabel>
              <Select
                disabled={isDisabled}
                labelId="planner-select-label"
                id="planner-select-label"
                value={planner}
                label="Planner"
                onChange={(event) => setPlanner(event.target.value)}
              >
                {
                  PLANNER_OPTIONS.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {/* LAYERS */}
          <Grid item xs={12}>
            <Typography variant="subtitle2">Scanning Area</Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="scanning-tabs"
              >
                {SCANNING_AREAS.map((area) => (
                  <Tab
                    label={area.label}
                    {...a11yProps(area.index, { textTransform: 'initial' })}
                  />
                ))}
              </Tabs>
            </Box>
            {/* ZONES */}
            <TabPanel value={tabIndex} index={0}>
              <Box >
                {
                  isLoadingLayout ?
                    <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                      <LinearProgress sx={{ width: '100%' }} />
                    </Box>
                    :
                    !zonesOptions?.[0]?.name ?
                      <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                        <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No zones available</Typography>
                      </Box>
                      :
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <DragTransferList
                          id='scanning-zones'
                          options={zonesOptions}
                          isDisabled={zonesOptions.length === 0 || isDisabled}
                          inputSelected={selectedZones}
                          setInputSelectedOptions={handleSelectedZones}
                          objectName={'name'}
                          objectId={'id'}
                          leftHeader='Zones'
                          rightHeader='Selected Zones'
                          isEnumerated={true}
                        />
                        <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*The robot will scan zones in the same order as in Selected Zones list</Typography>
                      </Box>
                }
                <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>**If no zones are selected, the robot will attempt to navigate all aisles</Typography>
              </Box>
            </TabPanel>
            {/* AISLES */}
            <TabPanel value={tabIndex} index={1}>
              <Box>
                {
                  isLoadingLayout ?
                    <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                      <LinearProgress sx={{ width: '100%' }} />
                    </Box>
                    :
                    !aisleOptions?.[0]?.name ?
                      <Box sx={{ border: 'solid 1px #CCC', borderRadius: '3px', p: 1 }}>
                        <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>No aisles available</Typography>
                      </Box>
                      :
                      <>
                        <DragTransferList
                          id='scanning-aisles'
                          options={aisleOptions}
                          isDisabled={aisleOptions.length === 0 || isDisabled}
                          inputSelected={selectedAisles}
                          setInputSelectedOptions={handleSelectedAisles}
                          objectName={'name'}
                          objectId={'id'}
                          leftHeader='Aisles'
                          rightHeader='Omitted Aisles'
                          maxLabelLength={3}
                        />
                        <Typography variant="caption" sx={{ fontSize: 'small', color: '#888' }}>*Only activated aisles in layout available for selection</Typography>
                      </>
                }
              </Box>
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
