import {
  getChainsList,
  getDeleteSessionsResponse,
  getNewSessionsResponse,
  getRobotListBaldur,
  getRobotsList,
  getScheduleSessions,
  getSessionUpdateResponse,
  isDeletingSessions,
  isLoadingChains,
  isLoadingNewSessions,
  isLoadingRobotListBaldur,
  isLoadingRobots,
  isLoadingRobotSessions,
  isLoadingSessionUpdate,
  navigationOptions,
  isLoadingSessionsState,
  getLocalSessionsState,
  isLoadingRobotLayout,
  isLoadingBucketMapLayout,
} from 'core/selectors';
import { getSessionsState } from 'core/slices/session';
import { getChains, getRobots } from 'core/slices/backend';
import { getSessionsScheduler } from 'core/slices/baldur/scheduler';
import { getRobotsBaldur, postSessionUpdates } from 'core/slices/fleetControl';
import {
  deleteSessions
} from 'core/slices/scheduler';
import { connect } from 'react-redux';
import Scheduler from '../../../components/fleetManagement/Scheduler';

export default connect(state => ({
  chainsList: getChainsList(state),
  robotsList: getRobotsList(state),
  robotsListBaldur: getRobotListBaldur(state),
  sessionsList: getScheduleSessions(state),
  isLoadingSessions: isLoadingRobotSessions(state),
  newSessionsResponse: getNewSessionsResponse(state),
  isLoadingChains: isLoadingChains(state),
  isLoadingRobots: isLoadingRobots(state),
  isLoadingRobotListBaldur: isLoadingRobotListBaldur(state),
  isLoadingNewSessions: isLoadingNewSessions(state),
  navOptions: navigationOptions(state),
  isLoadingSessionUpdate: isLoadingSessionUpdate(state),
  updateSessionResponse: getSessionUpdateResponse(state),
  isLoadingDeleteSessions: isDeletingSessions(state),
  deleteSessionsResponse: getDeleteSessionsResponse(state),
  isLoadingSessionsState: isLoadingSessionsState(state),
  sessionsState: getLocalSessionsState(state),
  isLoadingRobotLayout: isLoadingRobotLayout(state),
  isLoadingBucketMapLayout: isLoadingBucketMapLayout(state),
}),
  {
    getChains,
    getRobots,
    getRobotsBaldur,
    getSessionsScheduler,
    postSessionUpdates,
    deleteSessions,
    getSessionsState,
  },)(Scheduler);