import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import Icon from '../tools/Icon';

export default function ScheduleTraceability(props) {
  const {
    openModal,
    setOpenModal,
    sessionSelected,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Dialog open={openModal} maxWidth="lg" width='auto' onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">{t('overseer_app.fleetManagement.last_change', 'Last Change')}</Typography>
            <Tooltip title="Close">
              <IconButton onClick={() => setOpenModal(false)} sx={{ p: 0 }}>
                <IconComponent iconName={'close-circle-outline'} />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: `min(100%, calc(${document.getElementById('layout-map-box')?.clientHeight ?? 400}px + 7em))`, overflowY: 'auto', maxHeight: '45em', overflow: 'hidden', pb: 0 }}>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              {sessionSelected?.author &&
                <List sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '20em' }}>
                  {
                    [{
                      primary: `Last modification by ${sessionSelected?.author}`,
                      secondary: `On ${dayjs(sessionSelected?.updated_at).add(sessionSelected.utc_offset, 'hours').format('DD/MM/YYYY HH:mm')}. Reason: ${JSON.parse(sessionSelected?.comments || '{}').reason ?? '-'}. ${JSON.parse(sessionSelected?.comments || '{}').comment ?? '-'}`,
                    }].map((infoItem, index) => (
                      <>
                        <ListItem key={`info-item-list-${index}`} sx={(theme) => ({ display: 'flex', alignItems: 'center', py: 1, mt: 1, ...infoItem.listItemStyles })}>
                          <ListItemAvatar>
                            <Icon iconName={'information-circle'} color={'gray'} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={infoItem.primary}
                            secondary={infoItem.secondary}
                          />
                        </ListItem>
                        <Divider />
                      </>
                    ))
                  }
                </List>
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog >
    </>

  );
}
