import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { isEmptyOrUndefined } from "@zippeditoolsjs/blocks";
import { DataGrid } from '@zippeditoolsjs/table';
import { IconComponent } from '@zippeditoolsjs/zippedi-icons';

const tableColumns = (t, handleEditOpen) => {
  const columns = [
    { field: 'store_id', headerName: t('overseer_app.store_contacts.Store_code', 'Store Code') },
    { field: 'phones', headerName: t('overseer_app.store_contacts.Phones', 'Phones'), flex: 1 },
    { field: 'emails', headerName: t('overseer_app.store_contacts.Emails', 'Emails'), flex: 1 },
    {
      field: 'edit', headerName: t('overseer_app.store_contacts.edit', 'Edit'),
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => handleEditOpen(params.row)}
          >
            <IconComponent
              iconName={'pencil'}
              style={{ fontSize: '20px' }}
            />
          </IconButton>
        );
      }
    },
  ]
  return columns;
}

export default function StoreContacts(props) {
  const {
    setSnackAlert,
    getStoreContacts,
    storeContacts,
    isLoaingStoreContacts,
    putStoreContacts,
    putResponse,
    isLoadingStoreContactsPut,
    resetPutResponse,
  } = props;
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [contact, setContact] = useState({ phones: '', emails: '' });

  // Lifecycle Methods

  useEffect(() => {
    getStoreContacts()
  }, [])

  // Successful edit flow
  useEffect(() => {
    if (putResponse === 200) {
      getStoreContacts();
      resetPutResponse();
      handleEditClose();
      setSnackAlert({
        open: true,
        message: t(
          'overseer_app.store_contacts.put_successful',
          'Contact modified successfully'
        ),
        severity: 'success',
      });
    }
  }, [putResponse])

  // Methods

  // Edit button handlers
  const handleEditOpen = (row) => {
    setOpenEdit(true);
    setContact(row);
  }

  const handleEditClose = () => {
    setOpenEdit(false);
  }

  const onEdit = () => {
    let phones = null;
    let emails = null;

    let params = {
      store_contact_id: contact?.store_contact_id,
      store_id: contact?.store_id
    }

    if (contact?.phones) {
      // Remove white spaces and split the string by comma
      phones = contact.phones.toString().replace(/\s+/g, '').split(",");

      // Remove empty strings
      phones = phones.filter(phone => phone)

      // Give a warning if no phones were added
      if (!isEmptyOrUndefined(phones, 'array')) {
        phones = JSON.stringify(phones)
      } else {
        setSnackAlert({
          open: true,
          message: t(
            'overseer_app.store_contacts.Missing_phone',
            'Missing a phone number'
          ),
          severity: 'warning',
        });
        return;
      }
    }

    if (contact?.emails) {
      // Remove white spaces and split the string by comma
      emails = contact.emails.toString().replace(/\s+/g, '').split(",");

      // Remove empty strings
      emails = emails.filter(email => email)

      // If emails's an empty list, return as null
      if (!isEmptyOrUndefined(emails, 'array')) {
        emails = JSON.stringify(emails)
      } else {
        emails = null
      }
    }

    if (phones !== null) {
      params["phones"] = phones
    }

    if (emails !== null) {
      params["emails"] = emails
    }

    putStoreContacts({ ...params })
  }

  return (
    <Grid container direction='column' spacing={2}>
      {/* Edit user dialog */}
      <Dialog
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
        maxWidth='md'
        fullWidth
      >
        <DialogContent>
          <DialogTitle id="edit-dialog-title">
            {t('overseer_app.store_contacts.Edit_store_contacts', 'Edit Store Contacts')}: {contact?.store_id}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="edit-dialog-description">
              {t('overseer_app.store_contacts.Edit_instructions', "Separate the contacts with a comma (,) and make sure there's no space between them.")}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid container item>
                <TextField
                  required
                  fullWidth
                  label={t('overseer_app.store_contacts.Phones', 'Phones')}
                  id="phones"
                  helperText={t('overseer_app.store_contacts.Phones_example', 'Example: +11111111111,+22222222222')}
                  variant="filled"
                  value={contact?.phones}
                  onChange={(e) => setContact(prevState => ({ ...prevState, phones: e.target.value }))}
                />
              </Grid>
              <Grid container item>
                <TextField
                  fullWidth
                  label={t('overseer_app.store_contacts.Emails', 'Emails')}
                  id="emails"
                  variant="filled"
                  value={contact?.emails}
                  onChange={(e) => setContact(prevState => ({ ...prevState, emails: e.target.value }))}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>{t('overseer_app.store_contacts.cancel', 'Cancel')}</Button>
            <LoadingButton
              loading={isLoadingStoreContactsPut}
              onClick={onEdit}
              loadingPosition="start"
              startIcon={
                <IconComponent
                  iconName={'save'}
                  style={{ fontSize: '18px' }}
                />
              }
            >
              {t('overseer_app.store_contacts.Save', 'Save')}
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Title */}
      <Grid item>
        <Typography variant='h4'>{t('overseer_app.store_contacts.Store_contacts', 'Store Contacts')}</Typography>
      </Grid>
      {/* Table */}
      <Grid item>
        <DataGrid
          autoHeight
          loading={isLoaingStoreContacts}
          rows={storeContacts}
          columns={tableColumns(t, handleEditOpen)}
          getRowId={(row) => row.store_id}
        />
      </Grid>
    </Grid>
  )
}
