import { Skeleton, Box, Grid } from '@mui/material';
import React from 'react';


export default function TableSkeleton(props) {

  return (
    <Box>
      <Box>
        <Box sx={{ display: 'flex', direction: 'row', gap: 2, backgroundColor: 'blue' }} >
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="text" width="100%" height='5em' />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" width="100%" height='5em' />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" width="100%" height='5em' />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="text" width="100%" height='5em' />
          </Grid>
        </Box>
      </Box>
      {[1, 2, 3].map((item) => (
        <Box>
          <Box sx={{ display: 'flex', direction: 'row', gap: 2 }} >
            <Grid item xs={2}>
              <Skeleton variant="text" width="100%" height='5em' />
            </Grid>
            <Grid item xs={1}>
              <Skeleton variant="text" width="100%" height='5em' />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100%" height='5em' />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100%" height='5em' />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100%" height='5em' />
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
