import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NavigationIcon from '@mui/icons-material/Navigation';
import ErrorIcon from '@mui/icons-material/Error';

import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExploreIcon from '@mui/icons-material/Explore';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import InfoIcon from '@mui/icons-material/Info';

import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RobotStatusModal from './Components/RobotStatusModal';
import TabPanel from '../tools/TabPanel';
import LastRefresh from '../tools/LastRefresh';
import { a11yProps } from '../Utils';
import { capitalizeFirstLetter } from '../tools/utils';

const data = [
  { name: 'ROS', key: 'ros_status' },
  {
    name: 'Door',
    key: 'door_state',
  },
  { name: 'Battery', key: 'battery_status' },
  { name: 'Navigation', key: 'nav_state' },
  { name: 'Confidence', key: 'loc_conf' },
  { name: 'Relocalization', key: 'reloc_status' },

  { name: 'Current Aisle', key: 'current_aisle' },
  { name: 'Last Aisle', key: 'last_aisle' },
  { name: 'Internal', key: 'state' },
];

export default function NavigationWidget(props) {
  const {
    title,
    connectionStatusMsg,
    mqttNavInfo,
    rawRobotStatus,
    robot,
    store,
    robotMainStatus,
    robotDetailStatus,
    robotStatusHistory,
    getRobotStatusHistory,
    isLoadingRobotStatusHistory,
    activeAlerts,
    robotState,
  } = props;

  const { t } = useTranslation();

  const [connected, setConnected] = useState(null);
  const [rosStatus, setRosStatus] = useState('');
  const [doorState, setDoorState] = useState(null);
  const [autoPause, setAutoPause] = useState(null);
  const [locConfidence, setLocConfidence] = useState(null);
  const [navState, setNavState] = useState(null);
  const [relocStatus, setRelocStatus] = useState(null);
  const [currentAisle, setCurrentAisle] = useState(null);
  const [lastAisle, setLastAisle] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [batteryStatus, setBatteryStatus] = useState(null);
  const [batteryVoltage, setBatteryVoltage] = useState(null);
  const [lastStatusTime, setLastStatusTime] = useState(null);
  const [rawRobotState, setRawRobotState] = useState(null);

  const getNavInfo = (item) => {
    let icon;
    let title;
    let value;
    let description;
    if (item.key === 'ros_status') {
      title = 'ROS';
      switch (rosStatus) {
        case 'ready':
          icon = <CheckCircleIcon fontSize="large" sx={{ color: '#0E7F19' }} />;
          description = `${t(
            'overseer_app.widget.software_ready',
            'Software ready and session planned'
          )}`;
          break;
        case 'off':
          icon = <CancelIcon color="error" fontSize="large" />;
          description = `${t(
            'overseer_app.widget.software_off',
            'Software off'
          )}`;
          break;
        case 'turning_on':
          icon = <AutorenewIcon fontSize="large" sx={{ color: '#0E7F19' }} />;
          description = `${t(
            'overseer_app.widget.turning_on',
            'Turning on software'
          )}`;
          break;
        case 'turning_off':
          icon = <AutorenewIcon color="error" fontSize="large" />;
          description = `${t(
            'overseer_app.widget.turning_off',
            'Turning off software'
          )}`;
          break;
        default:
          icon = <QuestionMarkIcon fontSize="large" />;
          description = `${t('overseer_app.widget.unknown', 'Unknown')}`;
          break;
      }
    } else if (item.key === 'door_state') {
      title = 'Door';
      switch (doorState) {
        case 0:
          icon = <CheckCircleIcon fontSize="large" sx={{ color: '#0E7F19' }} />;
          break;
        case 1:
          icon = <ArrowUpwardIcon fontSize="large" sx={{ color: '#0E7F19' }} />;
          break;
        case 2:
          icon = (
            <ArrowDownwardIcon fontSize="large" sx={{ color: '#0E7F19' }} />
          );
          break;
        case 3:
          icon = <CancelIcon color="error" fontSize="large" />;
          break;
        default:
          icon = <CancelIcon color="warning" fontSize="large" />;
          break;
      }
    } else if (item.key === 'nav_state') {
      title = t(`overseer_app.widget.${item.key}`, item.name);
      let auto_pause = !valuesMap['auto_pause']
        ? ` (${t('overseer_app.widget.auto_pause')})`
        : '';
      value =
        item.key && valuesMap[item.key]
          ? valuesMap[item.key].toString() + auto_pause
          : null;
      icon = <NavigationIcon fontSize="large" color="info" />;
    } else if (item.key === 'battery_status') {
      title = t(`overseer_app.widget.${item.key}`, item.name);
      value = batteryVoltage && `${batteryVoltage} V`;
      if (batteryStatus === 1) {
        icon = (
          <BatteryChargingFullIcon fontSize="large" sx={{ color: '#0E7F19' }} />
        );
      } else if (batteryStatus === 2) {
        icon = <BatteryAlertIcon fontSize="large" color="error" />;
      } else {
        let charge_status = rawRobotStatus?.charge_state;

        if (charge_status <= 0.2) {
          icon = <Battery20Icon fontSize="large" color="error" />;
        } else if (charge_status > 0.8) {
          icon = (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <BatteryFullIcon fontSize="large" sx={{ color: '#0E7F19' }} />
              {charge_status ? (
                <Typography
                  sx={{ fontSize: 12, fontWeight: 600, color: 'grey' }}
                >{`(${charge_status * 100}%)`}</Typography>
              ) : null}
            </Box>
          );
        } else {
          icon = (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Battery50Icon fontSize="large" color="warning" />
              {charge_status ? (
                <Typography
                  sx={{ fontSize: 12, fontWeight: 700, color: 'grey' }}
                >{`(${charge_status * 100}%)`}</Typography>
              ) : null}
            </Box>
          );
        }
      }
    } else if (item.key === 'loc_conf') {
      title = t(`overseer_app.widget.${item.key}`, item.name);
      value =
        item.key && valuesMap[item.key] ? valuesMap[item.key].toString() : null;
      icon = <MyLocationIcon fontSize="large" color="info" />;
    } else {
      title = t(`overseer_app.widget.${item.name}`, item.name);
      value =
        item.key && valuesMap[item.key] ? valuesMap[item.key].toString() : null;
      if (item.key === 'reloc_status') {
        icon = <ExploreIcon fontSize="large" color="info" />;
      } else if (item.key === 'last_aisle') {
        icon = <ModeOfTravelIcon fontSize="large" color="info" />;
      } else if (item.key === 'current_aisle') {
        icon = <LocationOnIcon fontSize="large" color="info" />;
      } else if (item.key === 'state') {
        icon = <InfoIcon fontSize="large" color="info" />;
      }
    }
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mr: 2,
          my: 1,
          ml: 2.5,
        }}
      >
        <Box>
          {description ? <Tooltip title={description}>{icon}</Tooltip> : icon}
        </Box>
        <Box>
          <Typography sx={{ fontSize: 15, fontWeight: 700, color: 'black' }}>
            {title}
          </Typography>
          {value && (
            <Typography variant={"caption"} sx={{ fontSize: 12, fontWeight: 700, color: 'grey' }}>
              {value}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  const handleColorRobotState = (robotState) => {
    let style;
    switch (robotState) {
      case 'active':
        style = {
          backgroundColor: '#0E7F19',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;

      case 'non-operational':
        style = {
          backgroundColor: '#fe2d38',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;
      case 'under maintenance':
        style = {
          backgroundColor: '#FFA500',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;
      default:
        style = {
          backgroundColor: '#808080',
          color: 'white',
          fontSize: '0.7rem',
        };
        break;
    }
    return style;
  };

  useEffect(() => {
    if (connectionStatusMsg.mqtt_connection) {
      setConnected(connectionStatusMsg.mqtt_connection);
    }
    if (connectionStatusMsg.program_status) {
      setRosStatus(connectionStatusMsg.program_status);
    }
  }, [connectionStatusMsg]);

  useEffect(() => {
    if ('door_state' in mqttNavInfo) setDoorState(mqttNavInfo.door_state);
    if (mqttNavInfo.loc_conf)
      setLocConfidence(`${mqttNavInfo.loc_conf * 100}%`);
    if (mqttNavInfo?.nav_criteria) setNavState(mqttNavInfo.nav_criteria);
  }, [mqttNavInfo]);

  useEffect(() => {
    if (rawRobotStatus) {
      if (rawRobotStatus.robot_paused_auto_alert)
        setAutoPause(rawRobotStatus.robot_paused_auto_alert);
      if (rawRobotStatus.visual_localization_system)
        setRelocStatus(rawRobotStatus.visual_localization_system.status);
      if (rawRobotStatus.current_aisle) {
        if (rawRobotStatus.current_aisle === '0') setCurrentAisle('-');
        else setCurrentAisle(rawRobotStatus.current_aisle);
      }
      // If aisles_to_process has one or more aisles, then the last one is there.
      if (rawRobotStatus.aisles_to_process?.length > 0)
        setLastAisle(rawRobotStatus.aisles_to_process.slice(-1)[0]);
      // Else if aisles_processed has one or more aisles, the last one is there.
      else if (rawRobotStatus.aisles_processed?.length > 0)
        setLastAisle(rawRobotStatus.aisles_processed.slice(-1)[0]);
      else setLastAisle('-');
      setBatteryStatus(rawRobotStatus?.battery_status);
      setBatteryVoltage(rawRobotStatus?.battery);
      setRawRobotState(rawRobotStatus?.state)
      setLastStatusTime(new Date());
    }
  }, [rawRobotStatus]);

  // Since the activeAlerts object is not big, this will do, but
  // caution is advised since larger objects could affect performance.
  useEffect(() => {
    let connectionAlert = activeAlerts.find(
      (alert) => alert?.alert_id === 'no-connection'
    );
    if (connectionAlert) setConnected(false);
    else setConnected(true);
  }, [JSON.stringify(activeAlerts)]);

  let valuesMap = {
    ros_status: rosStatus,
    door_state: doorState,
    auto_pause: autoPause,
    loc_conf: locConfidence,
    nav_state: navState,
    reloc_status: relocStatus,
    current_aisle: currentAisle,
    last_aisle: lastAisle,
    state: rawRobotState
  };

  // card with widget info
  return (
    <Card
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <RobotStatusModal
        open={openModal}
        rawData={rawRobotStatus}
        setOpen={setOpenModal}
        data={robotDetailStatus}
        robotCode={`${store}-${robot}`}
        robotStatusHistory={robotStatusHistory}
        getRobotStatusHistory={getRobotStatusHistory}
        isLoadingRobotStatusHistory={isLoadingRobotStatusHistory}
      />
      <Grid container>
        <Grid xs={12} sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={0}
            aria-label={'robot-status-tab-0'}
          >
            <Tab
              label={title}
              {...a11yProps(0, { textTransform: 'initial' })}
            />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
              <Typography variant="overline">
                <Chip
                  label={
                    robotState
                      ? capitalizeFirstLetter(robotState)
                      : t('No info')
                  }
                  disabled={false}
                  size="small"
                  variant="filled"
                  style={handleColorRobotState(robotState)}
                />
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1 }}>
              <Typography variant="overline">
                <Chip
                  label={connected ? t('Connected') : t('Disconnected')}
                  disabled={false}
                  size="small"
                  variant="filled"
                  style={
                    connected
                      ? {
                          backgroundColor: '#0E7F19',
                          color: 'white',
                          fontSize: '0.7rem',
                        }
                      : {
                          backgroundColor: '#fe2d38',
                          color: 'white',
                          fontSize: '0.7rem',
                        }
                  }
                />
              </Typography>
            </Box>
          </Tabs>
        </Grid>
        <Grid xs={12}>
          <TabPanel value={0} index={0}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container item xs={12} justifyContent={'space-between'}>
                  {data.map((item) => (
                    <Grid item key={item.name} xs={6}>
                      {getNavInfo(item)}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Divider sx={{ width: '100%', borderBottomWidth: 2 }} />
              <Box
                sx={{
                  display: 'flex',
                  // flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                  mt: 1,
                }}
              >
                {robotMainStatus === 'OK' ? (
                  <CheckCircleIcon
                    sx={{ fontSize: '36px', color: '#0E7F19' }}
                  />
                ) : robotMainStatus === 'Error' ? (
                  <CancelIcon color="error" sx={{ fontSize: '36px' }} />
                ) : robotMainStatus === 'Warning' ? (
                  <ErrorIcon color="warning" sx={{ fontSize: '36px' }} />
                ) : (
                  <CircularProgress size={30} />
                )}

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setOpenModal(true)}
                >
                  {t('overseer_app.widget.see_more', 'See More')}
                </Button>
              </Box>
              <LastRefresh
                lastUpdateTime={lastStatusTime}
                timeInterval={10000}
              />
            </Box>
          </TabPanel>
        </Grid>
      </Grid>
    </Card>
  );
}
