import { FormControl, ListSubheader, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function CustomSelector(props) {
  const { singleOptions, multipleOptions, selectedValues, setSelectedValues } =
    props;

  const { t } = useTranslation();

  const [uniqueOptions, setUniqueOptions] = useState([]);

  useEffect(() => {
    const options = singleOptions?.reduce((options, currentOption) => [...options, ...(Object.values(currentOption.items).map(row => row.id))], []);
    setUniqueOptions(options);
  }, [singleOptions]);

  const handleSelectionChange = (event) => {
    const { value } = event.target;
    setSelectedValues(value);
  };

  const isConnectivityOptionDisabled = (id) => {
    return (
      !selectedValues.includes(id) &&
      uniqueOptions.some((item) => selectedValues.includes(item))
    );
  };

  return (
    <FormControl sx={{ maxWidth: '10em' }}>
      <Select
        labelId="select-label"
        value={selectedValues}
        onChange={handleSelectionChange}
        displayEmpty
        multiple
        size='small'
        renderValue={(selected) => {
          return <em>Layers</em>;
        }}
      >
        {multipleOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}

        {singleOptions?.map((option, index) => (
          <CustomMenuItem
            key={`customMenuItem-${index}`}
            title={t(option.title)}
            items={option.items}
            isConnectivityOptionDisabled={isConnectivityOptionDisabled}
            handleSelectionChange={handleSelectionChange}
            selectedValues={selectedValues}
            t={t}
          />
        ))}

      </Select>
    </FormControl>
  );
}

const CustomMenuItem = forwardRef((props, ref) => {
  const {
    title,
    items,
    selectedValues,
    isConnectivityOptionDisabled,
    handleSelectionChange,
    t,
  } = props;

  const handleSelection = (id) => {
    if (selectedValues.includes(id)) {
      return selectedValues.filter((value) => value !== id);
    }
    return [...selectedValues, id];
  }

  return (
    <div ref={ref}>
      <ListSubheader>{title}</ListSubheader>
      {items.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
          selected={selectedValues.includes(item.id)}
          disabled={isConnectivityOptionDisabled(item.id)}
          onClick={() => { handleSelectionChange({ target: { value: handleSelection(item.id) } }) }}
        >
          {t(item.name)}
        </MenuItem>
      ))}
    </div>
  )
});
