import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Title } from '@zippeditoolsjs/dashboards';
import EventDialog from './EventDialog';

import { IconComponent } from '@zippeditoolsjs/zippedi-icons';
import { Chip } from '@mui/material';
import { getTierColor } from '../utils/commons';

const headCells = [
  {
    id: 'robot_id',
    numeric: false,
    disablePadding: true,
    label: 'Robot ID',
  },
  {
    id: 'incident_type',
    numeric: false,
    disablePadding: true,
    label: 'Incident Type',
  },
  {
    id: 'elapsed_time',
    numeric: false,
    disablePadding: true,
    label: 'Elapsed Time',
  },
  {
    id: 'assigned_monitor',
    numeric: false,
    disablePadding: true,
    label: 'Assigned Monitor',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: true,
    label: 'Creation Time',
  },
  {
    id: 'tier_id',
    numeric: false,
    disablePadding: true,
    label: 'Assigned Team',
  },
];

export default function EventsTable(props) {
  const { eventsInfo } = props;
  const { t } = useTranslation();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRowClick = (event) => {
    setSelectedEvent(event);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
  };

  const goToRobot = (event, robot_id) => {
    event.stopPropagation();
    const url = `${window.location.origin}/robot/${robot_id
      .split('-')
      .join('/')}`;
    window.open(url, '_blank');
  };

  const goToTimeline = (event, robot_id) => {
    event.stopPropagation();
    const tabIndex = 2;
    const url = `${window.location.origin}/robot/${robot_id
      .split('-')
      .join('/')}?tab=${tabIndex}`;
    window.open(url, '_blank');
  };

  return (
    <>
      {eventsInfo.length > 0 ? (
        <Box sx={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow key="init">
                {headCells.map((headcell) => (
                  <TableCell
                    key={headcell.id}
                    align={headcell.numeric ? 'right' : 'left'}
                    padding={headcell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headcell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headcell.id}
                      direction={orderBy === headcell.id ? order : 'asc'}
                      onClick={() => handleSort(headcell.id)}
                    >
                      {headcell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell key={'go_to'}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventsInfo
                .sort((a, b) => {
                  const isAsc = order === 'asc';
                  if (orderBy === 'incident_type') {
                    return isAsc
                      ? a.alerts[0].alert_id.localeCompare(b.alerts[0].alert_id)
                      : b.alerts[0].alert_id.localeCompare(
                        a.alerts[0].alert_id
                      );
                  } else if (orderBy === 'elapsed_time') {
                    let timeA, timeB;
                    timeA = parseFloat(a[orderBy].split(' ')[0]);
                    timeB = parseFloat(b[orderBy].split(' ')[0]);
                    return isAsc ? timeA - timeB : timeB - timeA;
                  } else if (orderBy === 'tier_id') {
                    let timeA = a[orderBy];
                    let timeB = b[orderBy];
                    return isAsc ? timeA - timeB : timeB - timeA;
                  } else if (orderBy === 'assigned_monitor') {
                    let monitorA = a['assigned_monitor_username'];
                    let monitorB = b['assigned_monitor_username'];
                    if (!monitorA) {
                      return -1;
                    }
                    if (!monitorB) { 
                      return 1;
                    }
                    return isAsc
                    ? monitorA.localeCompare(monitorB)
                    : monitorB.localeCompare(monitorA);
                  }
                  return isAsc
                    ? a[orderBy].localeCompare(b[orderBy])
                    : b[orderBy].localeCompare(a[orderBy]);
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((event) => (
                  <TableRow
                    key={event.id}
                    onClick={() => handleRowClick(event)}
                  >
                    <TableCell>{event.robot_id}</TableCell>
                    <TableCell>{event.alerts[0]?.alert_id}</TableCell>
                    <TableCell>{event.elapsed_time}</TableCell>
                    <TableCell>{event.assigned_monitor_username}</TableCell>
                    <TableCell>{event.created_at}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={t(
                          `overseer_app.general.${event.tier_name}`,
                          event.tier_name
                        )}
                      >
                        <Chip
                          sx={{
                            '& .MuiChip-label': {
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            },
                            maxWidth: 'calc(100% - 15px)',
                          }}
                          label={t(
                            `overseer_app.general.${event.tier_name}`,
                            event.tier_name
                          )}
                          Size="small"
                          color={getTierColor(event.tier_id)}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={t(
                          'overseer_app.events.go_to_robot',
                          'Go to robot'
                        )}
                      >
                        <IconButton
                          aria-label="go-to-robot"
                          onClick={(e) => goToRobot(e, event.robot_id)}
                        >
                          <IconComponent iconName={'arrow-forward-sharp'} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={t(
                          'overseer_app.events.go_to_timeline',
                          'Go to timeline'
                        )}
                      >
                        <IconButton
                          aria-label="go-to-timeline"
                          onClick={(e) => goToTimeline(e, event.robot_id)}
                        >
                          <IconComponent iconName={'time'} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={eventsInfo.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <EventDialog
            selectedEvent={selectedEvent}
            openModal={openModal}
            handleCloseModal={handleCloseModal}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Title>No data</Title>
        </Box>
      )}
    </>
  );
}
