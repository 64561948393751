import { connect } from 'react-redux';

import {
  getCancelMessage,
  getReasonOptions,
  isLoadingActionReasons,
  loadingCancelSessionResponse,
} from 'core/selectors';

import { cancelSessions, removeCancelSessionResponse } from 'core/slices/baldur/scheduler';
import { getActionReasons } from 'core/slices/robotMonitoring';
import { setSnackAlert } from 'core/slices/snackAlert';

import CancelSessionForm from '../../../components/fleetManagement/forms/CancelSessionForm';

export default connect(
  (state) => ({
    actionReasons: getReasonOptions(state),
    isLoadingActionReasons: isLoadingActionReasons(state),
    loadingCancelSessionResponse: loadingCancelSessionResponse(state),
    cancelMessage: getCancelMessage(state),
  }),
  {
    getActionReasons,
    setSnackAlert,
    cancelSessions,
    removeCancelSessionResponse,
  }
)(CancelSessionForm);
