import React from 'react';

import { ReactSVG } from 'react-svg';
import RobotValidationIcon from '../../assets/icons/over_validation.svg';
import RobotIcon from '../../assets/icons/robot.svg';

export const IconMap = Object.freeze({
  'overseer-robot': {
    icon: RobotIcon,
    defaultStyle: {
      position: 'relative',
      left: '-3px'
    }
  },
  'overseer-robot-validation': {
    icon: RobotValidationIcon,
    defaultStyle: {
      position: 'relative',
      left: '-3px'
    }
  },
});

const SvgIcon = ({ name, style, ...rest }) => {
  const svgPath = IconMap[name].icon;
  const defaultStyle = IconMap[name].defaultStyle;

  if (!svgPath) {
    console.error(`SVG with name "${name}" not found.`);
    return null;
  }

  return (
    <div style={{ width: style.fontSize ?? 24, height: style.fontSize ?? 24, ...defaultStyle, ...style }} {...rest}>
      <ReactSVG src={svgPath}
        beforeInjection={(svg) => {
          svg.querySelectorAll('path').forEach((path) => {
            path.style.stroke = style.color ?? 'currentColor';
            path.style.fill = style.fill;
          });
          svg.querySelectorAll('line').forEach((path) => {
            path.style.stroke = style.color ?? 'currentColor';
          });
          svg.querySelectorAll('polyline').forEach((path) => {
            path.style.stroke = style.color ?? 'currentColor';
          });
          svg.querySelectorAll('rect').forEach((path) => {
            path.style.stroke = style.color ?? 'currentColor';
          });
        }} />
    </div>
  );
};

export default SvgIcon;
