import { connect } from 'react-redux';

import {
  getLocalSessionsState,
  getRobotListBaldur,
  getRobotSessionsList,
  getScheduleSessions,
  isLoadingRobotSessions,
} from 'core/selectors';
import { getSessionsScheduler } from 'core/slices/baldur/scheduler';
import { getRobotsBaldur } from 'core/slices/fleetControl';
import { getSessionsState } from 'core/slices/session';

import ScheduleWidget from '../../../components/widgets/ScheduleWidget';

export default connect(
  (state) => ({
    robotsListBaldur: getRobotListBaldur(state),
    robotSessionsList: getRobotSessionsList(state),
    sessionsList: getScheduleSessions(state),
    isLoadingSessions: isLoadingRobotSessions(state),
    sessionsState: getLocalSessionsState(state),
  }),
  {
    getRobotsBaldur,
    getSessionsScheduler,
    getSessionsState,
  }
)(ScheduleWidget);
